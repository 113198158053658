import React, { useState } from "react";
import {Link} from "react-router-dom";
import imageSrc from '../../FullLogo.png';


const Navbar = (props) => {
    const [navbarOpen] = useState(false);
    return (
        <nav className={
                (props.transparent
                    ? "top-0 absolute z-50 w-full"
                    : "relative bg-white shadow-lg") +
                " flex flex-wrap items-center justify-between px-2 py-3 "
            }
        >
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div
                    className={
                        "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
                        (navbarOpen ? " block rounded shadow-lg" : " hidden")
                    }
                    id="example-navbar-warning"
                >

                    <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                          to={'/landing'}>
                        <img src={imageSrc} alt=""/> {/* Adjust height as needed */}
                    </Link>

                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

                        <li className="flex items-center">
                            <div className="buttons hidden lg:flex items-center">
                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                    to={'/LandingChems'}>Chems</Link>

                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                      to={'/LandingSprays'}>Sprays</Link>

                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                      to={'/LandingFleet'}>Fleet</Link>

                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                      to={'/LandingTeam'}>Team</Link>

                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                      to={'/landingCalendar'}>Calendar</Link>

                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                      to={'/landing'}>Dashboards</Link>
                            </div>
                        </li>

                    </ul>


                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

                        <li className="flex items-center">
                            <div className="buttons hidden lg:flex items-center">
                                <Link className='signin text-white text-lg font-medium px-4 py-2 hover:text-darkgreen transition duration-200'
                                    to={'/login'}>Login</Link>
                                <Link className='signup text-white text-lg font-medium border border-white px-6 py-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200'
                                    to={'/register'}>Sign Up</Link>
                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        </nav>
    );
}

export default Navbar;
