import React from "react";
import Footer from "../LandingFooter";
import Navbar from "../LandingNavBar";
import dash from "../../fleetDash.png";
import dash2 from "../../fleetDash2.png";
// eslint-disable-next-line
import tag from "../../FleetTag[1].png";

import { Typewriter } from "react-simple-typewriter";


const LandingFleet = () => {
    return (
        <>
            <Navbar transparent/>

            <main className="bg-bgcolor w-full pt-16">

                <section id='hero'>

                    <div
                        className="top-0 mx-auto mt-[-96px] flex h-screen w-full max-w-screen-xl flex-col items-center justify-center text-center text-white">
                        <p className="p-2 font-bold uppercase text-litegreen">
                            Streamlined Equipment Management
                        </p>
                        <h1 className="text-5xl font-bold sm:text-6xl md:text-7xl">
                            Optimize Equipment Performance.
                        </h1>

                        <div className="mt-4 flex text-xl font-bold sm:text-3xl md:text-4xl">
                            <p className="mr-2 md:mr-3">Efficiently oversee</p>
                            <Typewriter
                                words={["Vehicle Maintenance Schedules", "Fuel Consumption", "Driver Assignments", "Route Planning", "And More"]}
                                loop={0}
                                cursor={1}
                                typeSpeed={120}
                            />
                        </div>

                        <p className=" mt-4 w-[90vw] text-xl font-bold text-gray-400 md:text-2xl">
                            Seamlessly track and manage your golf course's fleet of equipment, log service history, and
                            monitor
                            usage trends with ease.
                        </p>
                    </div>

                </section>

                <section id='features' className="pb-16">

                    <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
                        <div className='flex flex-col space-y-12 md:w-1/2'>
                            <h2 className='max-w-md text-4xl font-bold text-center text-white md:text-left'>
                                What Sets Our Equipment Tracker Apart?
                            </h2>
                            <p className='max-w-sm text-center text-white md:text-left'>
                                Our software provides a comprehensive solution for managing your golf course's equipment
                                fleet.
                                From logging service history to tracking usage on each machine, it's the ultimate tool
                                for
                                optimizing equipment performance.
                            </p>
                        </div>

                        <div className='flex flex-col space-y-8 md:w-1/2'>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            01
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Comprehensive Equipment Tracking
                                    </h3>
                                    <p className='text-white'>
                                        Keep detailed records of your equipment fleet, including service history and
                                        usage data, all
                                        accessible from one centralized platform.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            02
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Service Log Integration
                                    </h3>
                                    <p className='text-white'>
                                        Log and track equipment service and maintenance tasks, ensuring timely upkeep
                                        and prolonging
                                        the lifespan of your machines.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            03
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Usage Tracking and Graphing
                                    </h3>
                                    <p className='text-white'>
                                        Monitor and graph usage data for each machine, enabling informed decision-making
                                        and
                                        optimizing equipment allocation.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash}
                                 alt="/"/>
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Enhanced Equipment Management
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Optimize Equipment Performance
                                </h1>
                                <p className="mt-2 text-white">
                                    Elevate your golf course's equipment management with our powerful tracking and
                                    analytics
                                    features. From service logging to usage tracking and graphing, our software ensures
                                    your
                                    machines are always operating at their best.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Service Hours Graphing
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Track Machine Service History
                                </h1>
                                <p className="mt-2 text-white">
                                    Gain insights into equipment maintenance with our service hours graphing feature.
                                    Track and graph
                                    service hours for each machine, allowing for proactive maintenance scheduling and
                                    optimizing
                                    equipment uptime.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                            
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash2} alt="/"/>

                        </div>
                    </div>
                </section>

                <Footer/>

            </main>


        </>
    );
}

export default LandingFleet;