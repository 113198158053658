import React, {useEffect, useState} from 'react';
import {useAuth} from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import {db} from '../../firebase/firebase';
import {
    faCaretDown,
    faCaretUp,
    faDownload,
    faEdit,
    faFileExcel,
    faMagnifyingGlass,
    faPlus,
    faSquareCaretLeft,
    faSquareCaretRight,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {utils as xlsxUtils, write as writeXlsx} from 'xlsx';
import {saveAs} from 'file-saver';
import {ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar, Pie} from 'react-chartjs-2';
import {XCircleIcon} from "@heroicons/react/24/outline";

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const sprayOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Occurrences',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const sprayOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Chem Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Cost per Acre',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};


const Sprays = () => {
    const { currentUser } = useAuth();
    const [alertMessage, setAlertMessage] = useState(null);
    const [sprays, setSprays] = useState([]);
    const [spraySide, setSpraySide] = useState(false);
    const [filteredSprays, setFilteredSprays] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        area: 0,
        areaUnit: '',
        date: '',
        purpose: '',
        rate: 0,
        tankNum: 0,
        tankVol: 0,
        target: '',
        ingredients: [],
        updated: false,
    });
    const [editingSprayId, setEditingSprayId] = useState(null);
    const [chems, setChems] = useState([]); // New state for chemicals

    const showAlert = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage(null);
        }, 2000); // Display for 2 seconds
    };

    const onUpdate = async () => {
        try {
            const sprayCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
            const snapshot = await sprayCollectionRef.get();
            const sprayData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSprays(sprayData);
            setFilteredSprays(sprayData);
            localStorage.setItem(`spraysData_${currentUser.uid}`, JSON.stringify(sprayData));
        } catch (error) {
            console.error('Error updating fleet data:', error);
        }
    };

    useEffect(() => {
        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const storedSpraysData = localStorage.getItem(`spraysData_${currentUser.uid}`);
                    if (storedSpraysData) {
                        const spraysData = JSON.parse(storedSpraysData);
                        setSprays(spraysData);
                        setFilteredSprays(spraysData);
                    } else {
                        const spraysCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
                        const snapshot = await spraysCollectionRef.get();
                        if (!snapshot.empty) {
                            const spraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setSprays(spraysData);
                            setFilteredSprays(spraysData);
                            localStorage.setItem(`spraysData_${currentUser.uid}`, JSON.stringify(spraysData));
                        } else {
                            console.log('No sprays found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                    } else {
                        const chemsRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                        const snapshot = await chemsRef.get();
                        const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setChems(chemsData);
                        localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemsData));
                    }
                } catch (error) {
                    console.error('Error fetching chemicals:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setSpraySide(userData.spraySide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setSpraySide(userData.spraySide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchSprays();
        fetchChems(); // Fetch chemicals when component mounts
    }, [currentUser]);


    const deleteSprayFromFirestore = async (sprayId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('sprays').doc(sprayId).delete();
            console.log('Spray item deleted successfully');
        } catch (error) {
            console.error('Error deleting Spray item:', error);
        }
    };

    const fillFormWithSprayData = (spray) => {
        setFormData({
            name: spray.name,
            area: spray.area,
            areaUnit: spray.areaUnit,
            date: spray.date,
            purpose: spray.purpose,
            rate: spray.rate,
            tankNum: spray.tankNum,
            tankVol: spray.tankVol,
            target: spray.target,
            ingredients: spray.ingredients
        });
        setEditingSprayId(spray.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            area: 0,
            areaUnit: '',
            date: '',
            purpose: '',
            rate: 0,
            tankNum: 0,
            tankVol: 0,
            target: '',
            ingredients: [],
            updated: false,
        });
        setEditingSprayId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');

        // If date field is empty, set it to the current date
        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            date: formData.date || currentDate
        };

        // Map formDataWithDate.ingredients to include necessary fields
        const ingredientsData = await Promise.all(formDataWithDate.ingredients.map(async ingredient => {
            // Since ingredients already include additional fields from handleIngredientChange,
            // we can directly use them here without additional processing
            return {
                name: ingredient.name,
                weight: ingredient.weight,
                rate: ingredient.rate,
                unit: ingredient.unit,
                price: ingredient.price,
                nutrients: ingredient.nutrients,
                containerVol: ingredient.containerVol,
                chemID: ingredient.chemID,
            };
        }));

        const parsedFormData = {
            ...formDataWithDate,
            area: parseFloat(formDataWithDate.area),
            rate: parseFloat(formDataWithDate.rate),
            tankNum: parseFloat(formDataWithDate.tankNum),
            tankVol: parseFloat(formDataWithDate.tankVol),
            // Include ingredients data
            ingredients: ingredientsData
        };

        try {
            if (editingSprayId) {
                await collectionRef.doc(editingSprayId).update(parsedFormData);
                console.log('Spray updated successfully!');
                showAlert('Spray updated successfully!');
                await onUpdate();
            } else {
                await collectionRef.add(parsedFormData);
                console.log('Spray added successfully!');
                showAlert('Spray added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedSpraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSprays(updatedSpraysData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating Spray: ', error);
            showAlert('Error adding/updating Spray: ', error);

        }
    };





    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'date') {
            // If the changed field is 'date', directly set the value
            setFormData((prevData) => ({
                ...prevData,
                date: value,
            }));
        } else {
            // For other fields, update as usual
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };



    const handleIngredientChange = async (index, fieldName, e) => {
        const selectedValue = e.target.value;
        if (!selectedValue) {
            console.error('Invalid selected value:', selectedValue);
            return;
        }

        try {
            // Fetch the data of the selected chem from Firestore
            const selectedChemData = await fetchChemData(selectedValue);

            // Debugging: Log the selected chemical data
            console.log('Selected chemical data:', selectedChemData);

            // Check if selectedChemData is null
            if (!selectedChemData) {
                console.error('Selected chemical data is null for:', selectedValue);
                return; // Exit the function if data is not found
            }

            // Update the ingredient object with data from the selected chem
            const updatedIngredients = formData.ingredients.map((ingredient, i) => {
                if (i === index) {
                    return {
                        ...ingredient,
                        // Assign data from the selected chem to corresponding fields
                        name: selectedValue, // Update the name
                        weight: selectedChemData.weight,
                        rate: selectedChemData.rate,
                        unit: selectedChemData.unit,
                        price: selectedChemData.price, // Map unitprice to price
                        nutrients: selectedChemData.nutrients,
                        containerVol: selectedChemData.containerVol,
                        chemID: selectedChemData.chemID,
                        // Add more fields similarly for other properties
                    };
                }
                return ingredient;
            });

            // Update the state with the updated ingredients
            setFormData(prevState => ({
                ...prevState,
                ingredients: updatedIngredients
            }));
        } catch (error) {
            console.error('Error handling ingredient change:', error);
            // Handle any errors that occur during the data fetching or processing
        }
    };





    const fetchChemData = async (chemicalName) => {
        try {
            const chemDocs = await db.collection('Users').doc(currentUser.uid).collection('chems').where('name', '==', chemicalName).get();
            if (!chemDocs.empty) {
                const chemDoc = chemDocs.docs[0];
                const chemData = chemDoc.data();
                return {
                    chemID: chemDoc.id, // Capture the document ID
                    weight: chemData.weight,
                    rate: chemData.rate,
                    unit: chemData.unit,
                    price: chemData.unitprice,
                    nutrients: chemData.nutrients,
                    containerVol: chemData.volume,
                };
            } else {
                console.error('Chemical data not found for:', chemicalName);
                return null;
            }
        } catch (error) {
            console.error('Error fetching chemical data:', error);
            return null;
        }
    };

    const handleAddIngredient = () => {
        setFormData({ ...formData, ingredients: [...formData.ingredients, { name: null }] });
    };

    const handleRemoveIngredients = (index) => {
        const updatedIngredients = [...formData.ingredients];
        updatedIngredients.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            ingredients: updatedIngredients
        }));
    };

    const addOrUpdateButtonText = editingSprayId ? 'Update Spray' : 'Add Spray';

    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');


    const calculateTotalCoverage = (spray) => {
        return (spray.area);
    };

    const calculateTotalPrice = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;

        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
        }, 0).toFixed(2);

        return (totalChemPrice);
    };




    // Function to filter sprays based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = sprays.filter(spray =>
            spray.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredSprays(filtered);
    };

    const filterSpraysByPurpose = (purpose) => {
        setSelectedPurpose(purpose);
        const filtered = sprays.filter(spray => purpose === '' || spray.purpose === purpose);
        setFilteredSprays(filtered);
    };





    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Purpose', 'Date', 'Tanks', 'Tank Volume', 'Ingredients', 'Target', 'Area', 'Area Unit'];
        const worksheetData = [columnHeaders, ...sprays.map(spray => [spray.name, spray.purpose, spray.date, spray.tankNum, spray.tankVol, spray.ingredients.length, spray.target, spray.area, spray.areaUnit])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Chemicals');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'SprayHistory.xlsx');
        showAlert('Sprays Successfully Downloaded!');

    };

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(spraySide); // Set isCollapsed to the value of chemSide from Firebase
    }, [spraySide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };


    const calculateIngredients = () => {
        const chemMap = {};

        filteredSprays.forEach(spray => {
            spray.ingredients.forEach(ingredient => {
                const { name } = ingredient;

                if (!chemMap[name]) {
                    chemMap[name] = 1; // Initialize count
                } else {
                    chemMap[name] += 1; // Increment count
                }
            });
        });

        return Object.entries(chemMap).map(([name, count]) => ({
            name,
            totalValue: count
        }));
    };

    const calcTotalChemVol = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;
        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        return spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (areaNum * ((current.rate / 1000) / 128));
        }, 0).toFixed(2);

    }

    const calculateTotalCost = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;

        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
        }, 0).toFixed(2);

        return (totalChemPrice / spray.area).toFixed(2);
    };

    const calcCost = () => {
        return filteredSprays.map(spray => ({
            name: spray.name,
            cost: calculateTotalCost(spray)
        }));
    };

    const calculateAverageCostPerAcre = (sprays) => {
        const totalCost = sprays.reduce((accumulator, spray) => {
            const sprayCost = parseFloat(calculateTotalCost(spray));
            return accumulator + sprayCost;
        }, 0);

        const averageCost = totalCost / sprays.length;
        return averageCost.toFixed(2);
    };

    const calculateAverageCost = (sprays) => {
        const totalCost = sprays.reduce((accumulator, spray) => {
            const sprayCost = parseFloat(calculateTotalCost(spray));
            return accumulator + sprayCost;
        }, 0);

        return totalCost.toFixed(2);
    };

    const averageCost = calculateAverageCostPerAcre(filteredSprays);

    const averageTotalCost = calculateAverageCost(filteredSprays);

    const uniqueChems = calculateIngredients();
    const costs = calcCost();


    const chemBarChartData = {
        labels: uniqueChems.map(chem => chem.name.slice(0, 4)),
        datasets: [
            {
                label: 'Occurrences:',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: uniqueChems.map(chem => chem.totalValue.toFixed(2)),
            }
        ]
    };

    const sprayBarChartData2 = {
        labels: costs.map(spray => spray.name.slice(0,4)),
        datasets: [
            {
                label: 'Cost',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: costs.map(spray => spray.cost),
            }
        ]
    };


    return (
        <div className="bg-bgpage flex h-full w-full mt-16">


            <Dashboard/>

            <div className="relative">
                {/* Your existing UI components */}
                {alertMessage && (
                    <div
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-bglite text-white p-4 rounded-lg shadow-md shadow-gray-900">
                        {alertMessage}
                    </div>
                )}
            </div>


            <div className="w-full flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    <div className="flex flex-col items-center justify-center">

                        <div className="flex items-center justify-center w-full flex-row">

                            <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 w-full mr-2">

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Sprays:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1.5 border-solid border-darkgreen">{filteredSprays.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Coverage:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calculateTotalCoverage(spray)), 0).toFixed(2)) + " acres"}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Cost:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{"$" + numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calculateTotalPrice(spray)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <button className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2"
                                    onClick={toggleExpansion}>
                                    {expanded ? <FontAwesomeIcon icon={faCaretUp}/> :
                                        <FontAwesomeIcon icon={faCaretDown}/>}
                                </button>

                            </div>

                            <button onClick={downloadAsExcel} className="bg-darkgreen hover:bg-litegreen text-white flex font-bold p-2 rounded-lg focus:outline-none focus:shadow-outline">
                                <FontAwesomeIcon icon={faDownload} className="text-white mr-2"/>
                                <FontAwesomeIcon icon={faFileExcel} className="text-white"/>
                            </button>

                            <button className="ml-2 text-3xl text-white" onClick={toggleCollapse}>
                                {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                    <FontAwesomeIcon icon={faSquareCaretRight}/>}
                            </button>

                        </div>

                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div className="mt-2 mr-2 ml-2 p-2 bg-bgcolor flex items-center justify-center rounded-md shadow-mg shadow-gray-900 w-full">


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Acre Cost Comparison:</div>
                                            <Bar options={sprayOptions2} data={sprayBarChartData2} className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div className="w-full mr-2 rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Total Chem Volume:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(filteredSprays.reduce((total, spray) => total + parseFloat(calcTotalChemVol(spray)), 0).toFixed(2))} gal</div>
                                                </div>
                                            </div>


                                            <div className="w-full rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Avg Cost per Acre:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageCost)}</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div className="mr-auto ml-auto rounded-md bg-bglite pl-6 pr-6 pb-1 pt-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Total Price:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageTotalCost)}</div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Chemical Occurrences:</div>
                                            <Bar options={sprayOptions} data={chemBarChartData} className="ml-auto mr-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}

                    </div>



                    <div className="mt-2 w-full flex flex-row items-center">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white mr-2"/>
                        <input
                            type="text"
                            placeholder="Search Sprays By Name"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className="bg-bglite appearance-none rounded-md w-full mr-2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                        />

                        <div className="w-1/4 flex flex-row items-center">
                            <select
                                value={selectedPurpose}
                                onChange={(e) => filterSpraysByPurpose(e.target.value)}
                                className="bg-bglite appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">All Purposes</option>

                                <option value="Aeration Recovery">Aeration Recovery</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Pesticide Appplication">Pesticide Appplication</option>
                                <option value="Fertilizer Application">Fertilizer Application</option>
                                <option value="Herbicide Application">Herbicide Application</option>
                                <option value="Disease Control">Disease Control</option>
                                <option value="Insect Control">Insect Control</option>
                                <option value="Weed Control">Weed Control</option>
                                <option value="Fungicide Application">Fungicide Application</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Plant Growth Regulators">Plant Growth Regulators</option>
                                <option value="Drought Resistance">Drought Resistance</option>
                                <option value="Color Enhancement">Color Enhancement</option>
                                <option value="Sun Protection">Sun Protection</option>
                                <option value="Stress Management">Stress Management</option>
                                <option value="Algae Control">Algae Control</option>
                                <option value="pH Balancing">pH Balancing</option>
                                <option value="Seasonal Adjustments">Seasonal Adjustments</option>
                                <option value="Erosion Control">Erosion Control</option>
                                <option value="Water Quality Enhancement">Water Quality Enhancement</option>
                                <option value="Environmental Sustainability">Environmental Sustainability</option>
                                {/* Add more options as needed */}
                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>

                        </div>

                    </div>


                    <div className={` ${expanded ? 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-23.4rem)] scrollbar-none' : 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-11.25rem)] scrollbar-none'}`}>
                        {sprays.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                            <div
                                    className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a Spray</p>

                                    <p className="text-xs text-white mt-2">1] Fill out the panel on the right hand side
                                        of the screen</p>
                                    <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the
                                        Add Spray button</p>
                                    <p className="text-xs text-white mt-1">3] Press the Add Spray button to add it to
                                        your database</p>
                                    <p className="text-xs text-white mt-1">4] If your spray does not automatically show
                                        up on the screen refresh your page and it should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the
                                        Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredSprays.map((spray, index) => (
                                <SprayCard key={index} spray={spray} onDelete={() => deleteSprayFromFirestore(spray.id)}
                                           onEdit={fillFormWithSprayData}/>
                            ))
                        )}
                    </div>


                </div>


                <div className={` ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col rounded-md shadow-md shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/4'}`}>

                    <div className="text-white justify-center items-center text-center text-lg animate-fadeIn">Add Spray</div>

                    <button type="button" onClick={clearForm} className="absolute font-bold py-1 px-1 rounded-md mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                        <XCircleIcon className="w-6 hover:text-red-700 flex-none text-white" aria-hidden="true"/>
                    </button>

                    <form onSubmit={handleSubmit} className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                        <div className="text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mb-2 mr-5" htmlFor="name">
                                Name:
                            </label>

                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="date">
                                Date:
                            </label>

                            <input
                                type="text"
                                id="date"
                                name="date"
                                value={formData.date || currentDate}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to identify this spray in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">CLASSIFIERS
                        </div>


                        <div className="flex items-center justify-start mt-2 mb-2 w-full">
                            <label className="block text-white text-sm mr-5 w-1/4" htmlFor="purpose">
                                Purpose:
                            </label>

                            <select
                                id="purpose"
                                name="purpose"
                                value={formData.purpose}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 w-3/4 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Aeration Recovery">Aeration Recovery</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Pesticide Appplication">Pesticide Appplication</option>
                                <option value="Fertilizer Application">Fertilizer Application</option>
                                <option value="Herbicide Application">Herbicide Application</option>
                                <option value="Disease Control">Disease Control</option>
                                <option value="Insect Control">Insect Control</option>
                                <option value="Weed Control">Weed Control</option>
                                <option value="Fungicide Application">Fungicide Application</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Plant Growth Regulators">Plant Growth Regulators</option>
                                <option value="Drought Resistance">Drought Resistance</option>
                                <option value="Color Enhancement">Color Enhancement</option>
                                <option value="Sun Protection">Sun Protection</option>
                                <option value="Stress Management">Stress Management</option>
                                <option value="Algae Control">Algae Control</option>
                                <option value="pH Balancing">pH Balancing</option>
                                <option value="Seasonal Adjustments">Seasonal Adjustments</option>
                                <option value="Erosion Control">Erosion Control</option>
                                <option value="Water Quality Enhancement">Water Quality Enhancement</option>
                                <option value="Environmental Sustainability">Environmental Sustainability</option>


                            </select>
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-5" htmlFor="target">
                                Target Area:
                            </label>

                            <select
                                id="target"
                                name="target"
                                value={formData.target}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Greens">Greens</option>
                                <option value="Surrounds">Surrounds</option>
                                <option value="Collars">Collars</option>
                                <option value="Fairways">Fairways</option>
                                <option value="Tees">Tees</option>
                                <option value="Rough">Rough</option>

                            </select>
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this Spray in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">MEASUREMENTS
                        </div>


                        <div className="flex mb-2 mt-2">
                            <div className="w-full mr-5">
                                <label className="block text-white text-sm mb-2" htmlFor="area">
                                    Area:
                                </label>

                                <input
                                    type="number"
                                    id="area"
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange}
                                    className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <div className="w-full">
                                <label className="block text-white text-sm mb-2" htmlFor="areaUnit">
                                    Unit:
                                </label>

                                <select
                                    id="areaUnit"
                                    name="areaUnit"
                                    value={formData.areaUnit}
                                    onChange={handleChange}
                                    className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="sq. ft">sq. ft</option>
                                    <option value="acres">acres</option>

                                </select>
                            </div>
                        </div>


                        <div className="flex items-center mt-4 mb-2 justify-start">
                            <label className="block text-white text-sm mr-5" htmlFor="rate">
                                Rate:
                            </label>

                            <input
                                type="number"
                                id="rate"
                                name="rate"
                                value={formData.rate}
                                onChange={handleChange}
                                className="bg-bgcard w-1/3 appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900 mr-5"
                            />

                            <label className="block text-litegreen text-xs text-center" htmlFor="rate">
                                Gal per Acre:
                            </label>
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to calculate your volume
                        </div>


                        <div className="flex flex-row items-center justify-between">
                            <div className="w-1/3"></div>
                            <div className="w-1/3 text-white text-center text-xs">CHEMICALS</div>
                            <div className="w-1/3 flex justify-end">
                                <button type="button" onClick={handleAddIngredient} className="rounded-md text-center bg-darkgreen text-white h-9 w-9">
                                    <FontAwesomeIcon icon={faPlus} className="text-white"/>
                                </button>
                            </div>
                        </div>


                        {formData.ingredients.map((ingredient, index) => {
                            console.log('Ingredient:', ingredient); // Log the value of ingredient
                            return (
                                <div key={index} className="flex items-center mt-2 mb-2 justify-start">
                                    <select
                                        id={`ingredient-${index}`}
                                        name={`ingredient-${index}`}
                                        value={ingredient ? ingredient.name : ''}
                                        onChange={(e) => handleIngredientChange(index, "name", e)}
                                        className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900 mr-5"
                                    >
                                        <option value="">Select...</option>
                                        {chems.map(chem => (
                                            <option key={chem.id} value={chem.name}>{chem.name}</option>
                                        ))}
                                    </select>
                                    <button type="button" onClick={() => handleRemoveIngredients(index)}>
                                        <FontAwesomeIcon icon={faTrash} className="text-red-700"/>
                                    </button>
                                </div>
                            );
                        })}


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">TANKS</div>


                        <div className="flex items-center mt-2 mb-2 justify-start">

                            <label className="block text-white text-sm mr-5" htmlFor="tankNum">
                                Tanks:
                            </label>

                            <input
                                type="number"
                                id="tankNum"
                                name="tankNum"
                                value={formData.tankNum}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>

                        <div className="flex items-center mt-2 mb-2 justify-start">

                            <label className="block text-white text-sm mr-5" htmlFor="tankVol">
                                Volume:
                            </label>

                            <input
                                type="number"
                                id="tankVol"
                                name="tankVol"
                                value={formData.tankVol}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to Calculate your per tank analysis
                        </div>


                        {/* Add more input fields as needed */}
                        <div className="flex w-full justify-center mt-4">
                            {/* Add or Update Chem button */}
                            <button type="submit"
                                    className="w-full bg-darkgreen hover:bg-litegreen text-white font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                {addOrUpdateButtonText}
                            </button>
                        </div>


                    </form>

                </div>


            </div>


        </div>
    );
};


const SprayCard = ({spray, onDelete, onEdit}) => {
    const {currentUser} = useAuth();
    const [expanded, setExpanded] = useState(false); // State to track expansion
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [alertMessage, setAlertMessage] = useState(null);

    const showAlert = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage(null);
        }, 2000); // Display for 2 seconds
    };

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        onDelete(spray.id);
        setShowDeleteConfirmation(false);
    };


    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const areaUnit = spray.areaUnit;
    const area = spray.area;
    const tankNum = spray.tankNum;

    let areaNum;

    if (areaUnit === "acres") {
        areaNum = area * 43560; // Convert acres to square feet
    } else {
        areaNum = area; // Assume the area is already in square feet
    }

    const calculateNutrients = () => {

        const nutrientsMap = {};

        spray.ingredients.forEach(ingredient => {
            const volumeOZ = (areaNum * (ingredient.rate / 1000));
            const volumeGal = (volumeOZ / 128);

            const weight = ingredient.weight;

            ingredient.nutrients.forEach(nutrient => {
                const { name, percentage } = nutrient;
                const value = (((percentage / 100) * weight) * volumeGal);

                if (!nutrientsMap[name]) {
                    nutrientsMap[name] = parseFloat(value); // Parse value to number
                } else {
                    nutrientsMap[name] += parseFloat(value); // Parse value to number and add
                }
            });
        });

        return Object.entries(nutrientsMap).map(([name, totalValue]) => ({ name, totalValue }));
    };



    const uniqueNutrients = calculateNutrients();

    // console.log(uniqueNutrients)

    const nutrientChart = {
        labels: uniqueNutrients.map(nutrient => nutrient.name),
        datasets: [
            {
                label: 'lbs',
                data: uniqueNutrients.map(nutrient => nutrient.totalValue.toFixed(2)),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderWidth: 1,
            },
        ],
    };



    const totalNutrientWeight = uniqueNutrients.reduce((accumulator, current) => {
        return accumulator + (current.totalValue);
    }, 0).toFixed(2);



    const totalChemVol = spray.ingredients.reduce((accumulator, current) => {
        return accumulator + (areaNum * ((current.rate / 1000) / 128));
    }, 0).toFixed(2);

    const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
        return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
    }, 0).toFixed(2);


    const updateChemsDatabase = async () => {
        const batch = db.batch(); // Using batched writes for efficiency

        try {
            await Promise.all(spray.ingredients.map(async (ingredient, index) => {
                console.log(`Processing ingredient ${index + 1}/${spray.ingredients.length}`);
                console.log(`Ingredient:`, ingredient);

                // Ensure the ingredient has the 'chemID' property
                if (!ingredient.hasOwnProperty('chemID')) {
                    console.error(`Ingredient ${index + 1} does not have a 'chemID' property.`);
                    return; // Skip to the next iteration
                }

                const chemID = ingredient.chemID;

                const volumeOZ = areaNum * (ingredient.rate / 1000);
                const volumeGal = (volumeOZ / 128).toFixed(2);

                console.log(`Calculating for ingredient ${ingredient.chemID}:`);
                console.log(`Volume in gallons: ${volumeGal}`);

                // Reference to the document in the 'chems' collection
                const chemDocRef = db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemID);

                // Get the current chem data
                const chemDocSnapshot = await chemDocRef.get();

                if (chemDocSnapshot.exists) {
                    let currentQuantity = chemDocSnapshot.data().quantity;
                    let currentExcess = chemDocSnapshot.data().excess || 0; // Default to 0 if excess doesn't exist

                    const containersNeededFinal = ((volumeGal) / ingredient.containerVol).toFixed(2);
                    console.log(`Containers needed: ${containersNeededFinal}`);

                    // Calculate how many containers we need
                    const containersNeeded = Math.floor((volumeGal - currentExcess) / ingredient.containerVol);
                    console.log(`Containers needed: ${containersNeeded}`);

                    // Subtract containersNeeded from quantity
                    const newQuantity = currentQuantity - containersNeeded;
                    console.log(`New quantity: ${newQuantity}`);

                    // Calculate the remaining excess
                    const remainingExcess = parseFloat(((volumeGal) % ingredient.containerVol).toFixed(2));
                    console.log(`Remaining excess: ${remainingExcess}`);



                    // Update the quantity and excess fields
                    batch.update(chemDocRef, {
                        quantity: newQuantity,
                        excess: remainingExcess
                    });
                } else {
                    console.log(`Chem with ID ${ingredient.chemID} does not exist`);
                }
            }));

            // Update the 'updated' field of the current spray to true
            const sprayDocRef = db.collection('Users').doc(currentUser.uid).collection('sprays').doc(spray.id);
            batch.update(sprayDocRef, { updated: true });

            // Commit the batched writes
            await batch.commit();
            console.log('Chem quantities and excess updated successfully');
            showAlert('Chemical Database Updated Successfully!');

        } catch (error) {
            console.error('Error updating chem quantities and excess:', error);
        }
    };


    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (

        <div className="bg-bgcolor p-2 rounded-md items-center justify-center  shadow-md shadow-gray-900 mb-2 w-full animate-cascade">


            <div className="relative">
                {/* Your existing UI components */}
                {alertMessage && (
                    <div
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-bglite text-white p-4 rounded-lg shadow-md shadow-gray-900">
                        {alertMessage}
                    </div>
                )}
            </div>


            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-lg">
                        <p className="text-white">Are you sure you want to delete this Spray?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">

                <div className="mr-2">
                    <button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                    </button>
                </div>

                <div
                    className="bg-darkgreen h-full rounded-md flex flex-col items-center justify-center p-2 w-1/4 shadow-md shadow-gray-900 mr-5">
                    <div className="text-xl text-white text-center line-clamp-1">{spray.name}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{spray.purpose}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{spray.date}</div>
                </div>


                <div className="h-full w-1/2 ">

                    <div className="bg-bgcolor h-full flex rounded-md p-2 w-full shadow-md shadow-gray-900 mb-2.5">

                        <div className="w-full mr-5">
                            <div className="text-sm text-white text-center line-clamp-1">Tanks:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">{tankNum}</div>
                        </div>

                        <div className="w-full mr-5">
                            <div className="text-sm text-white text-center line-clamp-1">Ingredients:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">{spray.ingredients.length}</div>
                        </div>

                        <div className="w-full mr-5">
                            <div className="text-sm text-white text-center line-clamp-1">Tank Vol:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">{spray.tankVol + " gal"}</div>
                        </div>

                        <div className="w-full">
                            <div className="text-sm text-white text-center line-clamp-1">Target:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">{spray.target}</div>
                        </div>

                    </div>

                    <div className="bg-bgcolor h-full rounded-md p-2 flex w-full shadow-md shadow-gray-900">

                        <div className="w-full flex mr-5 items-center justify-center">
                            <div className="text-sm text-white text-center line-clamp-1 mr-2">Area:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">{area + " " + areaUnit}</div>
                        </div>

                        <div className="w-full flex items-center justify-center">
                            <div className="text-sm text-white text-center line-clamp-1 mr-2">Cost Per Acre:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">${numberWithCommas((totalChemPrice / area).toFixed(2))}</div>
                        </div>

                    </div>

                </div>


                <div className="h-full rounded-md flex items-center justify-center w-1/4">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Total Volume:</div>
                        <div className="text-md text-white text-center mb-2 line-clamp-1">{numberWithCommas((tankNum * spray.tankVol).toFixed(2)) + " gal"}</div>

                        <div
                            className="rounded-md border-2 p-1.5 border-solid border-darkgreen shadow-md shadow-gray-900">
                            <div className="text-lg text-darkgreen text-center line-clamp-1">Total Price:</div>
                            <div className="text-md text-white text-center line-clamp-1">${numberWithCommas(totalChemPrice)}</div>
                        </div>

                    </div>


                </div>

                <div className="flex flex-col items-center">


                    <button onClick={() => onEdit(spray)} className="text-md text-white px-2 mb-4 hover:text-litegreen">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>

                    <button className="text-xxl text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>

                    <div className="text-md text-white font-bold text-center mt-5">TANK DATA</div>
                    <div className="text-xs text-darkgreen text-center">All of this data is per individual tank</div>


                    <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mt-5">

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 w-full mr-5">
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Amount of Chems:</div>
                            <div className="text-xs text-white text-center line-clamp-1">{(totalChemVol / tankNum).toFixed(2)} gal</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 w-full mr-5">
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Amount of Water:</div>
                            <div className="text-xs text-white text-center line-clamp-1">{(spray.tankVol - (totalChemVol / tankNum)).toFixed(2)} gal</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 w-full mr-5">
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Cost:</div>
                            <div className="text-xs text-white text-center line-clamp-1">${(totalChemPrice / tankNum).toFixed(2)}</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 w-full mr-5">
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Spray Rate:</div>
                            <div className="text-xs text-white text-center line-clamp-1">{spray.rate + " Gal / Acre"}</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 w-full">
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Coverage:</div>
                            <div className="text-xs text-white text-center line-clamp-1">{(area / tankNum).toFixed(2) + " " + areaUnit}</div>
                        </div>

                    </div>

                    <div className="flex">

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className=" w-full items-center justify-center">
                            <div className="text-md text-white font-bold text-center mt-5">CHEMICAL DATA</div>
                            <div className="text-xs text-darkgreen text-center">All of this data is the amounts of each
                                Chemical
                            </div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">
                            <button
                                onClick={updateChemsDatabase}
                                className={`text-white text-xs p-2 rounded-lg focus:outline-none focus:shadow-outline ${
                                    spray.updated ? 'bg-gray-400 cursor-not-allowed' : 'bg-darkgreen hover:bg-litegreen'
                                }`}
                                disabled={spray.updated}
                            >
                                {spray.updated ? 'Already Updated' : 'Update Chemical Inventory'}
                            </button>
                        </div>


                    </div>


                    <div className="bg-bgcolor h-full rounded-md p-2 w-full shadow-md shadow-gray-900">


                        <div className="items-center justify-center rounded-2xl overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                            {spray.ingredients.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex items-center justify-center">
                                    <div
                                        className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-5">
                                        <p className="text-xl text-darkgreen">This Spray Has No Chemicals</p>
                                        <p className="text-lg text-litegreen mt-1">Adding a Chemical</p>

                                        <p className="text-xs text-white mt-2">1] Press the Edit Button to the left of
                                            the Spray Card</p>
                                        <p className="text-xs text-white mt-1">2] This will fill the panel to the right
                                            with your spray's data</p>
                                        <p className="text-xs text-white mt-1">3] Navigate to the Chemicals Section and
                                            add in your chemicals and then press Update Spray</p>
                                    </div>
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                spray.ingredients.map((ingredient, index) => (
                                    <IngredientCard key={index} ingredient={ingredient} area={area} areaUnit={areaUnit}
                                                    tankNum={tankNum}/>
                                ))
                            )}

                        </div>


                        <div
                            className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 mt-2.5 mb-2.5 w-full">

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Ingredient:</div>
                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{spray.ingredients.length}</div>
                                </div>
                            </div>

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Total Chem Volume:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{numberWithCommas(totalChemVol)} gal
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Total Price:</div>
                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">${numberWithCommas(totalChemPrice)}</div>
                                </div>
                            </div>

                        </div>


                    </div>


                    <div className="flex">

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className=" w-full items-center justify-center">
                            <div className="text-md text-white font-bold text-center mt-5">NUTRIENT DATA</div>
                            <div className="text-xs text-darkgreen text-center">All of this data is the amounts of each Nutrient</div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">

                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Nutrients per Acre:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{(totalNutrientWeight / spray.area).toFixed(2)} lbs/acre</div>
                            </div>

                        </div>


                    </div>


                    <div className="bg-bgcolor h-full rounded-md  p-3 w-full shadow-md shadow-gray-900">


                        <div className="w-full flex flex-row">

                            <div className="w-2/3">

                                <div className="items-center justify-center rounded-2xl overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                                    {uniqueNutrients.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center">
                                            <div
                                                className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-5">
                                                <p className="text-xl text-darkgreen">This Spray Has No Nutrients</p>
                                                <p className="text-lg text-litegreen mt-1">Potential Issues</p>
                                                <p className="text-xs text-white mt-2">1] You did not add in the
                                                    Chemicals Nutrients in the database</p>
                                                <p className="text-xs text-white mt-1">2] Your Chemicals you have added
                                                    do not contain Nutrients</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        uniqueNutrients.map((nutrient, index) => (
                                            <NutrientCard key={index} nutrient={nutrient} tankNum={tankNum}/>
                                        ))
                                    )}

                                </div>

                            </div>

                            <div className="w-1/3 max-w-screen-md h-full">

                                <Pie data={nutrientChart} className="mr-auto ml-auto"/>

                            </div>

                        </div>


                        <div
                            className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 mt-2.5 mb-2.5 w-full">

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Nutrients:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{uniqueNutrients.length}</div>
                                </div>
                            </div>

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Tank Nutrient Weight:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{(totalNutrientWeight / tankNum).toFixed(2)} lbs
                                    </div>
                                </div>
                            </div>

                            <div className="">
                            <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Total Nutrient Weight:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{numberWithCommas(totalNutrientWeight)} lbs
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </>
            )}

        </div>
    );
};


const IngredientCard = ({ingredient, area, areaUnit, tankNum}) => {

    let areaNum;

    if (areaUnit === "acres") {
        areaNum = area * 43560; // Convert acres to square feet
    } else {
        areaNum = area; // Assume the area is already in square feet
    }

    const rate = ingredient.rate;
    const containerVol = ingredient.containerVol;

    const volumeOZ = (areaNum * (rate / 1000)).toFixed(2);
    const volumeGal = (volumeOZ / 128).toFixed(2)

    const totprice = (volumeGal * ingredient.price).toFixed(2);

    const tankCont = ((volumeGal / tankNum) / containerVol).toFixed(2);
    const totalCont = (volumeGal / containerVol).toFixed(2);

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="bg-bgcolor flex items-center justify-center rounded-md p-1 m-2 shadow-md shadow-gray-900 mt-2 hover:bg-bglite hover:opacity-80">
            <div
                className="flex flex-col items-center justify-center shadow-d shadow-gray-900 rounded-md border-2 p-1 border-solid border-darkgreen mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">{ingredient.name}</div>
                <div className="text-sm text-white line-clamp-1">{rate + " oz / 1000 sqft"}</div>
            </div>

            <div className="flex flex-col items-center justify-center mr-5 w-full">
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center mb-2">
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Volume</div>
                    <div className="text-sm text-white line-clamp-1">{numberWithCommas((volumeGal / tankNum).toFixed(2)) + " gal"} | {numberWithCommas((volumeOZ / tankNum).toFixed(2)) + " oz"}</div>
                </div>
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center">
                    <div className="text-sm text-litegreen line-clamp-1">Total Volume</div>
                    <div className="text-sm text-white line-clamp-1">{numberWithCommas(volumeGal) + " gal"} | {numberWithCommas(volumeOZ) + " oz"}</div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mr-5 w-full">
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center mb-2">
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Price</div>
                    <div className="text-sm text-white line-clamp-1">${numberWithCommas((totprice / tankNum).toFixed(2))}</div>
                </div>
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center">
                    <div className="text-sm text-litegreen line-clamp-1">Total Price</div>
                    <div className="text-sm text-white line-clamp-1">${numberWithCommas(totprice)}</div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mr-5 w-full">
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center mb-2">
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Containers</div>
                    <div className="text-sm text-white line-clamp-1">{tankCont}</div>
                </div>
                <div className="flex flex-col shadow-md shadow-gray-900 p-1 rounded-md w-full items-center justify-center">
                    <div className="text-sm text-litegreen line-clamp-1">Total Containers</div>
                    <div className="text-sm text-white line-clamp-1">{totalCont}</div>
                </div>
            </div>

        </div>
    );
};



const NutrientCard = ({ nutrient , tankNum}) => {

    const handleDivision = (value) => {
        if (value < 0.1) {
            const ozValue = (value * 16).toFixed(2);
            return ozValue + " oz";
        } else {
            return value.toFixed(2) + " lbs";
        }
    };

    return (
        <div className="bg-bgcolor flex items-center justify-center rounded-md p-1 m-2 shadow-md shadow-gray-900 mt-2 hover:bg-bglite hover:opacity-80">

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 rounded-md border-2 p-1 border-solid border-darkgreen mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Name:</div>
                <div className="text-sm text-white line-clamp-1">{nutrient.name}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 rounded-md p-1 mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Tank Weight:</div>
                <div className="text-sm text-white line-clamp-1">{handleDivision(nutrient.totalValue / tankNum)}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 rounded-md p-1 mr-5 w-full">
                <div className="text-sm text-litegreen line-clamp-1">Total Weight:</div>
                <div className="text-sm text-white line-clamp-1">{handleDivision(nutrient.totalValue)}</div>
            </div>

        </div>
    );
};



export default Sprays;
