import React, { useEffect, useState } from 'react';
import Dashboard from '../../components/Dashboard';
import { useAuth } from '../../contexts/authContext';
import { db, storage } from '../../firebase/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Account = () => {
    const { currentUser } = useAuth();

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [defaultPage, setDefaultPage] = useState('');
    const [profileImage, setProfileImage] = useState('');


    const [metric, setMetric] = useState(false);
    const [dark, setDark] = useState(true);

    const [chem, setChem] = useState(true);
    const [spray, setSpray] = useState(true);
    const [fleet, setFleet] = useState(true);
    const [team, setTeam] = useState(true);


    const [greens, setGreens] = useState(0);
    const [surrounds, setSurrounds] = useState(0);
    const [collars, setCollars] = useState(0);
    const [fairways, setFairways] = useState(0);
    const [rough, setRough] = useState(0);
    const [tees, setTees] = useState(0);

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    // Fetch user document from Firestore based on the current user's UID
                    const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                    if (userDoc.exists) {
                        // Get the user data from the document
                        const userData = userDoc.data();
                        setFirstName(userData.fname);
                        setLastName(userData.lname);
                        setCompany(userData.company);
                        setTitle(userData.title);
                        setProfileImage(userData.profileImage);
                        setAddress(userData.address || ""); // Default to false if metric field doesn't exist

                        setMetric(userData.metric || false); // Default to false if metric field doesn't exist
                        setDark(userData.dark || true); // Default to false if metric field doesn't exist
                        setDefaultPage(userData.defaultPage || "");

                        setChem(userData.chemSide || false); // Default to false if metric field doesn't exist
                        setSpray(userData.spraySide || false); // Default to false if metric field doesn't exist
                        setFleet(userData.fleetSide || false); // Default to false if metric field doesn't exist
                        setTeam(userData.teamSide || false); // Default to false if metric field doesn't exist


                        setGreens(userData.greens || 0);
                        setSurrounds(userData.surrounds || 0);
                        setCollars(userData.collars || 0);
                        setFairways(userData.fairways || 0);
                        setRough(userData.rough || 0);
                        setTees(userData.tees || 0);

                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSavePreferences = async () => {
        if (currentUser) {
            try {
                let downloadURL = null;

                // Check if a file is selected for upload
                if (file) {
                    setUploading(true);
                    const storageRef = ref(storage, `images/${file.name}`);
                    await uploadBytes(storageRef, file);
                    downloadURL = await getDownloadURL(storageRef);
                    setUploading(false);
                }

                // Update user document in Firestore with new data
                await db.collection('Users').doc(currentUser.uid).update({
                    fname: firstName,
                    lname: lastName,
                    company: company,
                    title: title,
                    address: address,
                    metric: metric, // Update metric value in Firestore
                    dark: dark,
                    defaultPage: defaultPage,
                    chemSide: chem,
                    spraySide: spray,
                    fleetSide: fleet,
                    teamSide: team,
                    greens: parseFloat(greens),
                    surrounds: parseFloat(surrounds),
                    collars: parseFloat(collars),
                    fairways: parseFloat(fairways),
                    rough: parseFloat(rough),
                    tees: parseFloat(tees),
                    // Include the image URL if a file was uploaded
                    ...(downloadURL && { profileImage: downloadURL })
                });

                console.log('User data updated successfully');
                setSuccess('User data updated successfully');
            } catch (error) {
                console.error('Error updating user data:', error);
                setError(`Error updating user data: ${error.message}`);
            }
        } else {
            setError('No user is logged in.');
        }
    };

    const handleChange = (e) => {
        setDefaultPage(e.target.value);
    };

    return (
        <div className="bg-bgpage flex h-screen mt-16">
            <Dashboard />


            <div className="content pr-3 pt-3 pb-3 w-2/3 mt-3 mb-3 mr-3 rounded-md bg-bgcolor shadow-md shadow-gray-900">


                <div className="flex">
                    <div className="w-full items-center justify-center"></div>
                    <div className=" w-full items-center justify-center">
                        <div className="text-md text-litegreen font-bold text-center mt-5">Settings</div>
                    </div>
                    <div className="flex flex-col items-end justify-center w-full">
                        <button
                            type="button"
                            onClick={handleSavePreferences}
                            className="bg-darkgreen hover:bg-litegreen text-white font-bold py-2 px-2 rounded-md mr-2 focus:outline-none focus:shadow-outline">
                            Save Preferences
                        </button>
                    </div>
                </div>


                <div className="flex flex-col overflow-y-auto max-h-[calc(100vh-10rem)] scrollbar-none rounded-md">
                    <div className="text-white w-full text-center selected-option mt-2">Account Info</div>
                    <div
                        className="m-5 items-center justify-center mt-2 mb-4 bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5">
                        <form className="space-y-4">
                            <div className="flex">

                                <div className="w-1/2 flex mr-1 items-center justify-center">
                                    <label htmlFor="firstName"
                                           className=" w-1/2 text-litegreen text-sm mr-2">
                                        First Name:
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        autoComplete="given-name"
                                        required
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="w-full px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                    />
                                </div>

                                <div className="w-1/2 flex ml-1 items-center justify-center">
                                    <label htmlFor="lastName"
                                           className=" w-1/2 text-litegreen text-center text-sm mr-2">
                                        Last Name:
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        autoComplete="family-name"
                                        required
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="w-full px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                    />
                                </div>

                            </div>

                            <div className="flex items-center justify-center">
                                <label htmlFor="company" className="w-1/6 text-litegreen text-sm mr-2">
                                    Company:
                                </label>
                                <input
                                    type="text"
                                    id="company"
                                    autoComplete="organization"
                                    required
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="title" className=" w-1/6 text-litegreen text-sm mr-2">
                                    Title:
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    autoComplete="organization-title"
                                    required
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="title" className=" w-1/6 text-litegreen text-sm mr-2">
                                    Address:
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    autoComplete="organization-title"
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="title" className=" w-1/6 text-litegreen text-sm mr-2">
                                    Profile Image:
                                </label>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="border-bgcard border-2 p-1 rounded-lg text-gray-400 w-full mb-2 focus:outline-none focus:border-blue-500"
                                />
                            </div>

                        </form>
                    </div>


                    <div className="text-white w-full text-center selected-option mt-2">Account Preferences</div>


                    <form>

                        <div
                            className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="metric"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Metric System:
                            </label>
                            <label htmlFor="metric"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">
                                This conversion will switch all measurements to the metric system, except for the Spray
                                Rate input, which remains in ounces per 1000 square feet for now.
                            </label>
                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="metric"
                                    name="metric"
                                    checked={metric}
                                    onChange={(e) => setMetric(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="metric" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${metric ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                metric ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div
                            className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="dark"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Dark Mode:
                            </label>
                            <label htmlFor="dark"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">
                                Disabling Dark Mode will prepare GreenzHub platforms for upcoming Light Mode support,
                                although Light Mode is not yet available. </label>
                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="dark"
                                    name="dark"
                                    checked={dark}
                                    onChange={(e) => setDark(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="dark" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${dark ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                dark ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="default"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Default Page:
                            </label>
                            <label htmlFor="dark" className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">Tailor your app experience by picking your go-to page, so you always land where you want when logging in, making your time with us even smoother!</label>
                            <div className="w-1/4">
                                <select
                                    id="default"
                                    name="default"
                                    value={defaultPage}
                                    onChange={handleChange}
                                    className="bg-bglite appearance-none rounded-md py-2 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="Chemical Database">Chemical Database</option>
                                    <option value="Spray Database">Spray Database</option>
                                    <option value="Fleet Management">Fleet Management</option>
                                    <option value="Employee Management">Employee Management</option>
                                    <option value="Calendar">Calendar</option>
                                    <option value="Home">Home</option>
                                    <option value="Chemical Dashboard">Chemical Dashboard</option>
                                    <option value="Spray Dashboard">Spray Dashboard</option>
                                    <option value="Fleet Dashboard">Fleet Dashboard</option>

                                </select>
                            </div>
                        </div>


                        <div className="text-white w-full text-center mt-5 mb-5">Chemical Database</div>


                        <div
                            className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="chem"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Disable Sidebar:
                            </label>
                            <label htmlFor="chem"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">Disabling
                                the Chemical Database Sidebar will default the sidebar to be closed when the database is
                                opened. You will always be able to open the sidebar manually from the Database.</label>
                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="chem"
                                    name="chem"
                                    checked={chem}
                                    onChange={(e) => setChem(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="chem" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${chem ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                chem ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>


                        <div className="text-white w-full text-center mt-5 mb-5">Spray Database</div>


                        <div
                            className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="spray"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Disable Sidebar:
                            </label>
                            <label htmlFor="spray"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">Disabling
                                the Spray Database Sidebar will default the sidebar to be closed when the database is
                                opened. You will always be able to open the sidebar manually from the Database.</label>

                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="spray"
                                    name="spray"
                                    checked={spray}
                                    onChange={(e) => setSpray(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="spray" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${spray ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                spray ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>


                        <div className="text-white w-full text-center mt-5 mb-5">Fleet Management</div>


                        <div
                            className={`flex m-5 items-center justify-center mt-2 mb-4 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="fleet"
                                   className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Disable Sidebar:
                            </label>
                            <label htmlFor="fleet"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">Disabling
                                the Fleet Management Sidebar will default the sidebar to be closed when the database is
                                opened. You will always be able to open the sidebar manually from the Database.</label>

                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="fleet"
                                    name="fleet"
                                    checked={fleet}
                                    onChange={(e) => setFleet(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="fleet" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${fleet ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                fleet ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>


                        <div className="text-white w-full text-center mt-5 mb-5">Employee Management</div>


                        <div className={`flex m-5 items-center justify-center mt-2 relative bg-bgcolor shadow-md rounded-md shadow-gray-900 p-5`}>
                            <label htmlFor="team" className="w-1/4 text-white text-center text-sm cursor-pointer mr-5">
                                Disable Sidebar:
                            </label>
                            <label htmlFor="team"
                                   className="w-full text-center text-litegreen text-xs cursor-pointer mr-5">Disabling
                                the Employee Management Sidebar will default the sidebar to be closed when the database
                                is opened. You will always be able to open the sidebar manually from the
                                Database.</label>

                            <div className="w-1/4">
                                <input
                                    type="checkbox"
                                    id="team"
                                    name="team"
                                    checked={team}
                                    onChange={(e) => setTeam(e.target.checked)}
                                    className="sr-only"
                                />
                                <label htmlFor="team" className=" cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 bg-gray-600 rounded-full relative ${team ? 'bg-green-500' : 'bg-red-500'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${
                                                team ? 'bg-white translate-x-full' : 'bg-white'
                                            }`}></div>
                                    </div>
                                </label>
                            </div>
                        </div>


                    </form>

                </div>
            </div>





            <div className="content w-1/3 flex flex-col h-full">

                <div className="rounded-md bg-bgcolor p-4 h-1/2 mt-3 mr-3 mb-3 shadow-md shadow-gray-900">

                    <div className="text-white w-full">My Account</div>

                    <div className="justify-center items-center flex flex-col">
                        <div className="w-36 h-36 rounded-lg bg-bgcard flex items-center justify-center shadow-md shadow-gray-900 mb-5">
                            {/*<FontAwesomeIcon icon={faUser} className="text-white text-8xl"/>*/}

                            <img
                                src={profileImage}
                                alt="Profile"
                                className="object-cover w-full h-full rounded-md"
                                style={{minWidth: '100%', minHeight: '100%'}}
                            />

                        </div>


                        <div className="mt-5 bg-bgcolor h-full rounded-md flex p-1 w-full shadow-md shadow-gray-900">

                            <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                                <div className="text-xs text-white text-center mb-1 line-clamp-1">Full Name:</div>
                                <div className="text-xs text-white text-center mb-1 line-clamp-1">Title:</div>
                                <div className="text-xs text-white text-center mb-1 line-clamp-1">Email:</div>
                                <div className="text-xs text-white text-center line-clamp-1">Company:</div>
                            </div>

                            <div className="flex flex-col items-end p-1 justify-center w-full">

                                <div className="rounded-md border-2 p-1.5 border-solid border-darkgreen">
                                    <div
                                        className="text-xs text-white text-center mb-1 line-clamp-1">{`${firstName} ${lastName}`}</div>
                                    <div className="text-xs text-white text-center mb-1 line-clamp-1">{title}</div>
                                    <div
                                        className="text-xs text-white text-center mb-1 line-clamp-1">{currentUser.email}</div>
                                    <div className="text-xs text-white text-center line-clamp-1">{company}</div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="rounded-md bg-bgcolor p-4 h-1/2 mr-3 mb-3 shadow-md shadow-gray-900">
                    <div className="text-white w-full text-center">Golf Course Data</div>
                    <div className="text-litegreen text-xs w-full text-center">This is the area of the Golf Course areas in Acres</div>


                    <div className="flex flex-col overflow-y-auto max-h-[calc(100vh-32.5rem)] scrollbar-none rounded-md">
                        <form className="space-y-4">

                            <div className="flex items-center justify-center">
                                <label htmlFor="greens" className="w-1/4 text-litegreen text-sm mr-2">
                                    Greens:
                                </label>
                                <input
                                    type="number"
                                    id="greens"
                                    autoComplete="organization"
                                    required
                                    value={greens}
                                    onChange={(e) => setGreens(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="surrounds" className=" w-1/4 text-litegreen text-sm mr-2">
                                    Surrounds:
                                </label>
                                <input
                                    type="number"
                                    id="surrounds"
                                    autoComplete="organization-title"
                                    required
                                    value={surrounds}
                                    onChange={(e) => setSurrounds(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="collars" className=" w-1/4 text-litegreen text-sm mr-2">
                                    Collars:
                                </label>
                                <input
                                    type="number"
                                    id="collars"
                                    autoComplete="organization-title"
                                    required
                                    value={collars}
                                    onChange={(e) => setCollars(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className="flex items-center justify-center">
                                <label htmlFor="fairways" className="w-1/4 text-litegreen text-sm mr-2">
                                    Fairways:
                                </label>
                                <input
                                    type="number"
                                    id="fairways"
                                    autoComplete="organization"
                                    required
                                    value={fairways}
                                    onChange={(e) => setFairways(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="rough" className=" w-1/4 text-litegreen text-sm mr-2">
                                    Rough:
                                </label>
                                <input
                                    type="number"
                                    id="rough"
                                    autoComplete="organization-title"
                                    required
                                    value={rough}
                                    onChange={(e) => setRough(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                            <div className=" flex items-center justify-center">
                                <label htmlFor="tees" className=" w-1/4 text-litegreen text-sm mr-2">
                                    Tees:
                                </label>
                                <input
                                    type="number"
                                    id="tees"
                                    autoComplete="organization-title"
                                    required
                                    value={tees}
                                    onChange={(e) => setTees(e.target.value)}
                                    className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>

                        </form>
                    </div>


                </div>

            </div>
        </div>
    );
};

export default Account;
