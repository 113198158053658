import React, {useEffect, useState} from 'react';
import Dashboard from '../../components/Dashboard';
import { useAuth } from "../../contexts/authContext";
import { db } from "../../firebase/firebase";
import axios from 'axios';
import {AdvancedMarker, APIProvider, Map, Pin, useAdvancedMarkerRef} from '@vis.gl/react-google-maps';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretDown, faEdit,
    faSquareCaretLeft, faSquareCaretRight, faTrash,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
// import {DrawingManager} from "@react-google-maps/api";

const MapView = () => {
    const { currentUser } = useAuth();
    const [address, setAddress] = useState('');
    const [customLocations, setCustomLocations] = useState([]);
    const [mapClickCoords, setMapClickCoords] = useState({ lat: 0, lng: 0 });
    const [locationName, setLocationName] = useState('');
    const [locationType, setLocationType] = useState('');
    const [mapZoom, setMapZoom] = useState(16);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [infoWindowData, setInfoWindowData] = useState(null);
    const [originalLocations, setOriginalLocations] = useState([]);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
    const [isChecked, setIsChecked] = useState({
        Green: true,
        Tee: true,
        Fairway: true,
        Structure: true,
        Irrigation: true,
        Bunker: true,
    });

    // eslint-disable-next-line
    const [markerRef, marker] = useAdvancedMarkerRef();

    const handleMarkerClick = (location) => {
        setInfoWindowData(location);
    };

    const handleCloseWindow = () => {
        setInfoWindowData(null);
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;

        if (id === "selectAll") {
            setIsSelectAllChecked(checked);
            setIsChecked({
                Green: checked,
                Tee: checked,
                Fairway: checked,
                Structure: checked,
                Irrigation: checked,
                Bunker: checked,
            });
        } else {
            setIsChecked(prevState => ({ ...prevState, [id]: checked }));

            if (!checked) {
                setIsSelectAllChecked(false);
            } else {
                const allChecked = Object.keys(isChecked).every(key => key === id || isChecked[key]);
                if (allChecked) {
                    setIsSelectAllChecked(true);
                }
            }
        }
    };

    const filterData = () => {
        if (isSelectAllChecked) {
            setCustomLocations(originalLocations);
            return;
        }

        const filtered = originalLocations.filter(item => {
            if (item.type === 'Green' && isChecked.Green) return true;
            if (item.type === 'Tee' && isChecked.Tee) return true;
            if (item.type === 'Fairway' && isChecked.Fairway) return true;
            if (item.type === 'Structure' && isChecked.Structure) return true;
            if (item.type === 'Irrigation' && isChecked.Irrigation) return true;
            if (item.type === 'Bunker' && isChecked.Bunker) return true;
            return false;
        });
        setCustomLocations(filtered);
    };

    useEffect(() => {
        filterData();
        // eslint-disable-next-line
    }, [isChecked, isSelectAllChecked]);

    useEffect(() => {
        const fetchCustomLocations = async () => {
            if (currentUser) {
                try {
                    const storedLocations = localStorage.getItem(`customLocations_${currentUser.uid}`);
                    if (storedLocations) {
                        const locationsData = JSON.parse(storedLocations);
                        setCustomLocations(locationsData);
                        setOriginalLocations(locationsData);
                    } else {
                        const locationsSnapshot = await db.collection('Users').doc(currentUser.uid).collection('holes').get();
                        const locationsData = locationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setCustomLocations(locationsData);
                        setOriginalLocations(locationsData);
                        localStorage.setItem(`customLocations_${currentUser.uid}`, JSON.stringify(locationsData));
                        console.log("Fetched Locations:", locationsData);
                    }
                } catch (error) {
                    console.error('Error fetching custom locations:', error);
                }
            }
        };

        fetchCustomLocations();
    }, [currentUser]);

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                    params: {
                        address: address,
                        key: 'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E',
                    },
                });
                const { results } = response.data;
                if (results && results.length > 0) {
                    const { lat, lng } = results[0].geometry.location;
                    setMapCenter({ lat: lat, lng: lng });
                } else {
                    console.error('No coordinates found for the address');
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setAddress(userData.address || "");
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setAddress(userData.address || "");
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        if (address) {
            fetchCoordinates();
        }
    }, [currentUser, address]);

    const onUpdate = async () => {
        try {
            const locationsSnapshot = await db.collection('Users').doc(currentUser.uid).collection('holes').get();
            const locationsData = locationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCustomLocations(locationsData);
            localStorage.setItem(`customLocations_${currentUser.uid}`, JSON.stringify(locationsData));
            console.log("Fetched Locations:", locationsData);
        } catch (error) {
            console.error('Error updating location data:', error);
        }
    };

    const addCustomLocation = async () => {
        if (currentUser && mapClickCoords && locationName.trim() !== '') {
            try {
                await db.collection('Users').doc(currentUser.uid).collection('holes').add({
                    latitude: mapClickCoords.lat,
                    longitude: mapClickCoords.lng,
                    name: locationName.trim(),
                    type: locationType,
                });

                setLocationName('');
                setMapClickCoords({ lat: 0, lng: 0 }); // Reset mapClickCoords after adding the location
                await onUpdate();
            } catch (error) {
                console.error('Error adding custom location:', error);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        addCustomLocation();
        handleClose();
    };

    const handleClose = () => {
        setLocationName('');
        setMapClickCoords({lat: 0, lng: 0});
    };

    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const getPinColors = (type) => {
        switch (type) {
            case 'Green':
                return {
                    background: '#147E1F', // Green color
                    borderColor: '#4BB862',
                    glyphColor: '#1B2430'
                };
            case 'Fairway':
                return {
                    background: '#81A43B', // Light green for fairways
                    borderColor: '#5F7F2B',
                    glyphColor: '#1B2430'
                };
            case 'Tee':
                return {
                    background: '#008080', // Default color (Green)
                    borderColor: '#4BB862',
                    glyphColor: '#1B2430'
                };
            case 'Structure':
                return {
                    background: '#4F617A', // Dark blue for structures
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            case 'Irrigation':
                return {
                    background: '#000000',
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            case 'Bunker':
                return {
                    background: '#ffffff',
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            default:
                return {
                    background: '#FF5733', // Bright orange for tees
                    borderColor: '#E74821',
                    glyphColor: '#1B2430'
                };
        }
    };

    const numberOf = (items, itemType) => {
        return items.filter(item => item.type === itemType).length;
    };

    const handleChangeSlider = (event) => {
        setMapZoom(event.target.valueAsNumber);
    };

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const deleteMarkerFromFirestore = async (MarkerID) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('holes').doc(MarkerID).delete();
            console.log('Chem item deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting chem item:', error);
        }
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        deleteMarkerFromFirestore(infoWindowData.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };


    // const drawing = useMapsLibrary('drawing');


    return (
        <div className="bg-bgpage flex h-screen mt-16">
            <Dashboard />
            <div className="content w-full flex flex-row mb-6">

                <div className="flex flex-col h-full w-full mr-3 mt-2 mb-3">

                    <div className="w-full flex flex-row mb-1">

                        <div className="w-full flex flex-row items-center">

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Total:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-tees">{originalLocations.length}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Greens:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-darkgreen">{numberOf(originalLocations, 'Green')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Fairways:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-fairways">{numberOf(originalLocations, 'Fairway')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Tees:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-bgTeal">{numberOf(originalLocations, 'Tee')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Structures:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-structures">{numberOf(originalLocations, 'Structure')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Irrigation:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-black">{numberOf(originalLocations, 'Irrigation')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className="text-md text-white mr-2">Bunker:</div>
                                    <div className="text-sm text-white rounded-md bg-bgcolor border-2 p-1 border-solid border-white">{numberOf(originalLocations, 'Bunker')}</div>
                                </div>
                            </div>

                        </div>

                        <button className="ml-2 text-3xl text-white"
                                onClick={toggleCollapse}>
                            {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                <FontAwesomeIcon icon={faSquareCaretRight}/>}
                        </button>

                    </div>


                    <div className="relative justify-center h-full w-full flex flex-row items-center rounded-md bg-bgcolor shadow-md shadow-gray-900">
                        {address === "" ? (
                            <div className="flex items-center justify-center h-full">
                                <div className="bg-bgcolor py-2 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Map is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding your Address</p>

                                    <p className="text-xs text-white mt-2">1] On the left hand side of the screen navigate to the Account Page</p>
                                    <p className="text-xs text-white mt-1">2] Once in the Account Page enter your address into the field under settings</p>
                                    <p className="text-xs text-white mt-1">3] Make sure your address is properly formatted</p>
                                    <p className="text-xs text-white mt-1">4] Click on the [Save Preferences] button and that will save your address to your account</p>
                                    <p className="text-xs text-white mt-1">5] Once your address is saved you may navigate back to this page to see your map</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            <div className="relative w-full h-full p-1.5 rounded-md bg-bgcolor shadow-md shadow-gray-900 animate-fadeIn">
                                {mapCenter.lat !== 0 && mapCenter.lng !== 0 && (
                                    <APIProvider apiKey={'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E'} libraries={['marker']}>
                                        <Map
                                            mapId={'e93a6ae3c62f080a'}
                                            defaultCenter={mapCenter}
                                            defaultZoom={mapZoom}
                                            zoomControl={true}
                                            mapTypeId={"satellite"}
                                            gestureHandling={'greedy'}
                                            disableDefaultUI={true}
                                            onClick={(event) => setMapClickCoords({ lat: event.detail.latLng.lat, lng: event.detail.latLng.lng })}
                                        >
                                            {customLocations.map((location, index) => (
                                                <>
                                                    <AdvancedMarker
                                                        key={index}
                                                        ref={markerRef}
                                                        position={{lat: location.latitude, lng: location.longitude}}
                                                        title={location.name}
                                                        onClick={() => handleMarkerClick(location)}>
                                                        <Pin{...getPinColors(location.type)}
                                                            scale={0.6}>
                                                            <div className="text-white text-xxs mt-16 text-center bg-bgcolor rounded-md p-1">{location.name}</div>
                                                        </Pin>

                                                    </AdvancedMarker>



                                                    {infoWindowData ? (
                                                        <div className="absolute bottom-3 left-0 p-4" style={{ pointerEvents: 'none' }}>
                                                            <div className="bg-bgcolor p-2 rounded-md shadow-sm w-56 relative" style={{ pointerEvents: 'auto' }}>

                                                                <div className="flex flex-row">
                                                                    <button className="absolute top-2 left-2" onClick={toggleDeleteConfirmation}>
                                                                        <FontAwesomeIcon className="text-red-700 hover:text-red-500 text-sm" icon={faTrash}/>
                                                                    </button>

                                                                    <button className="absolute top-2 right-2" onClick={handleCloseWindow}>
                                                                        <FontAwesomeIcon className="text-white hover:text-litegreen text-sm" icon={faEdit}/>
                                                                    </button>
                                                                </div>

                                                                <div className="text-sm text-litegreen text-center font-semibold mb-2">{infoWindowData.name}</div>

                                                                <div className="mb-2 text-white text-xs">
                                                                    <span className="font-semibold text-litegreen">Type:</span> {infoWindowData.type}
                                                                </div>

                                                                <div className="mb-2 text-white text-xs">
                                                                    <span className="font-semibold text-litegreen">Latitude:</span> {infoWindowData.latitude}
                                                                </div>

                                                                <div className="mb-2 text-white text-xs">
                                                                    <span className="font-semibold text-litegreen">Longitude:</span> {infoWindowData.longitude}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="absolute bottom-3 left-0 p-4" style={{ pointerEvents: 'none' }}>
                                                            <div className="bg-bgcolor p-4 rounded-md shadow-sm w-64 relative text-center text-white" style={{ pointerEvents: 'auto' }}>
                                                                <p className="text-lg text-litegreen">No Marker Selected</p>

                                                                <p className="text-xs text-white mt-1">Click on a marker to see details here.</p>
                                                            </div>
                                                        </div>
                                                    )}


                                                    {/* Deletion confirmation popup */}
                                                    {showDeleteConfirmation && (
                                                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                                            <div className="bg-bgcolor p-4 rounded-md shadow-sm">
                                                                <p className="text-white">Are you sure you want to delete {infoWindowData.name}</p>
                                                                <div className="flex mt-2">
                                                                    <button className="w-full rounded-md bg-red-700 hover:bg-red-500 text-white p-1 mr-5" onClick={handleDelete}>Yes</button>
                                                                    <button className="w-full rounded-md bg-darkgreen hover:bg-litegreen text-white p-1" onClick={toggleDeleteConfirmation}>No</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}




                                                </>

                                            ))}

                                        </Map>
                                    </APIProvider>
                                )}




                                {mapClickCoords.lat !== 0 && mapClickCoords.lng !== 0 && (
                                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                                        <div className="">
                                            <div className="bg-bgcolor w-96 p-4 rounded-md shadow-md shadow-gray-900">

                                                <button type="cancel" className="rounded-lg text-2xl text-white"
                                                        onClick={handleClose}>
                                                    <FontAwesomeIcon icon={faXmark}/>
                                                </button>

                                                <div className="text-sm text-litegreen font-bold text-center mb-2">Add a New Location to the Map</div>

                                                <form onSubmit={handleSubmit}>

                                                    <div className="flex items-center justify-center mt-2 mb-2">
                                                        <label className="block text-white text-sm mr-5"
                                                               htmlFor="serviceDate">
                                                            Name:
                                                        </label>

                                                        <input
                                                            type="text"
                                                            placeholder="Location Name"
                                                            className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                                            value={locationName}
                                                            onChange={(e) => setLocationName(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="flex items-center w-full justify-start mt-2 mb-2">
                                                        <label className="block text-white text-sm mr-3"
                                                               htmlFor="classification">
                                                            Classification:
                                                        </label>

                                                        <div className="w-full flex items-center justify-end mt-2 mb-2">
                                                            <select
                                                                id="classification"
                                                                name="classification"
                                                                value={locationType}
                                                                onChange={(e) => setLocationType(e.target.value)}
                                                                className="bg-bglite appearance-none w-full rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                                            >
                                                                <option value="">Select...</option>

                                                                <option value="Green">Green</option>
                                                                <option value="Fairway">Fairway</option>
                                                                <option value="Tee">Tee</option>
                                                                <option value="Structure">Structure</option>
                                                                <option value="Irrigation">Irrigation</option>
                                                                <option value="Bunker">Bunker</option>

                                                            </select>

                                                            <div className="text-white ml-2 text-xl">
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                            </div>
                                                        </div>


                                                    </div>


                                                    <div className="flex w-full">
                                                        <button type="submit" className="w-full rounded-lg bg-darkgreen text-white p-1 hover:bg-litegreen">
                                                            Submit
                                                        </button>
                                                    </div>



                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                )}



                            </div>
                        )}
                    </div>


                </div>

                <div className={` ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col h-full rounded-md shadow-md shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/6'}`}>

                    <div className="flex flex-col overflow-y-auto h-full max-h-[calc(100vh-9rem)] scrollbar-none rounded-md">

                        <h2 className="text-lg text-white mb-4 text-center">Filter Locations</h2>

                        <div className="flex flex-col text-sm text-white">
                            <label className="mb-2">
                                <input type="checkbox" id="selectAll" checked={isSelectAllChecked} onChange={handleCheckboxChange}/>
                                <span className="ml-2">Select All</span>
                            </label>

                            <div className="ml-4 flex flex-col justify-center items-start">
                                <label className="mb-2">
                                    <input type="checkbox" id="Green" checked={isChecked.Green} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Greens</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Fairway" checked={isChecked.Fairway} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Fairways</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Tee" checked={isChecked.Tee} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Tees</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Structure" checked={isChecked.Structure} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Structures</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Irrigation" checked={isChecked.Irrigation} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Irrigation</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Bunker" checked={isChecked.Bunker} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Bunker</span>
                                </label>

                            </div>

                            <div className="mb-4 text-lg text-center text-white">Set Map Zoom:</div>

                            <div className="flex flex-col mb-4 items-center justify-center">
                                <div className="w-32">
                                    <input
                                        type="range"
                                        min="1"
                                        max="18"
                                        value={mapZoom}
                                        onChange={handleChangeSlider}
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                    />
                                    <div className="flex justify-between text-xs text-white mt-1">
                                        <span>1</span>
                                        <span>18</span>
                                    </div>
                                </div>
                                <div className="text-md text-litegreen">
                                    Value: {mapZoom}
                                </div>
                            </div>

                            <div className="flex w-full mb-4">
                                <button type="submit" className="w-full rounded-lg bg-darkgreen text-white p-1 hover:bg-litegreen">
                                    ReCenter
                                </button>
                            </div>

                            <div className="flex items-center justify-center flex-col">
                                <p className="text-lg text-white">Quick Start</p>

                                <p className="text-md text-litegreen mt-2">Adding Markers</p>

                                <p className="text-xs text-white mt-2">1] Click anywhere on the Map </p>
                                <p className="text-xs text-white mt-2">2] Fill-out the form in the Popup Window</p>
                                <p className="text-xs text-white mt-2">3] Once Submitted you will see your marker appear</p>

                                <p className="text-md text-litegreen mt-2">Viewing Details</p>

                                <p className="text-xs text-white mt-2">1] Click on the marker you desire</p>
                                <p className="text-xs text-white mt-2">2] in the lower left corner of the screen you will be able to view the details</p>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default MapView;
