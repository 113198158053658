import React, { useState, useEffect } from 'react';
import Dashboard from "../Dashboard";
import {useAuth} from "../../contexts/authContext";
import {db} from "../../firebase/firebase";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {Pie, Bar, Doughnut} from 'react-chartjs-2';

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);


export const sprayOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Cost',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

export const nutrientOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Weight',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

const SpraysDash = () => {

    const { currentUser } = useAuth();

    // eslint-disable-next-line
    const [sprays, setSprays] = useState([]);
    const [purposeCounts, setPurposeCounts] = useState({});
    const [chemCounts, setChemCounts] = useState({});
    const [targetCounts, setTargetCounts] = useState({});
    const [rateCounts, setRateCounts] = useState({});
    const [sprayBarChartData, setSpraysBarChartData] = useState(null);
    const [nutrientBarChartData, setNutrientBarChartData] = useState(null);



    useEffect(() => {
        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const spraysCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
                    const snapshot = await spraysCollectionRef.get();

                    if (!snapshot.empty) {
                        const spraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setSprays(spraysData);

                        const barChartData = {
                            labels: spraysData.map(spray => spray.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Total Cost',
                                    backgroundColor: 'rgba(75, 205, 129, 0.2)',
                                    borderColor: 'rgba(75, 205, 129, 0.8)',
                                    borderWidth: 2,
                                    data: spraysData.map(spray => calculateTotalSprayPrice(spray))
                                }
                            ]
                        };
                        setSpraysBarChartData(barChartData);

                        const nutrientChartData = {
                            labels: spraysData.map(spray => spray.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Weight [lbs]',
                                    backgroundColor: 'rgba(122, 197, 158, 0.2)',
                                    borderColor: 'rgba(122, 197, 159, 0.8)',
                                    borderWidth: 2,
                                    data: spraysData.map(spray => calculateNutrientAcre(spray))
                                }
                            ]
                        };
                        setNutrientBarChartData(nutrientChartData);

                        const counts = {};
                        spraysData.forEach(doc => {
                            const purpose = doc.purpose;
                            counts[purpose] = (counts[purpose] || 0) + 1;
                        });
                        setPurposeCounts(counts);

                        const counts2 = {};
                        spraysData.forEach(doc => {
                            const ingredients = doc.ingredients;
                            ingredients.forEach(chem => {
                                const name = chem.name;
                                counts2[name] = (counts2[name] || 0) + 1;
                            });
                        });
                        setChemCounts(counts2);

                        const counts3 = {};
                        spraysData.forEach(doc => {
                            const target = doc.target;
                            counts3[target] = (counts3[target] || 0) + 1;
                        });
                        setTargetCounts(counts3);

                        const counts4 = {};
                        spraysData.forEach(doc => {
                            const rate = doc.rate;
                            counts4[rate] = (counts4[rate] || 0) + 1;
                        });
                        setRateCounts(counts4);

                    } else {
                        console.log('No sprays found');
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        fetchSprays();
    }, [currentUser]);

    const spraysChart = {
        labels: Object.keys(purposeCounts),
        datasets: [
            {
                label: '# of Sprays',
                data: Object.values(purposeCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const spraysChart2 = {
        labels: Object.keys(chemCounts),
        datasets: [
            {
                label: '# of Uses',
                data: Object.values(chemCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const spraysChart3 = {
        labels: Object.keys(targetCounts),
        datasets: [
            {
                label: '# of Sprays',
                data: Object.values(targetCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const spraysChart4 = {
        labels: Object.keys(rateCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(rateCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],
                borderWidth: 1,
            },
        ],
    };

    const calculateTotalSprayPrice = (spray) => {

        const areaUnit = spray.areaUnit;
        const area = spray.area;

        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
            return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
        }, 0).toFixed(2);

        return (totalChemPrice);
    };

    const calculateNutrientAcre = (spray) => {
        const areaUnit = spray.areaUnit;
        const area = spray.area;
        let areaNum;

        if (areaUnit === "acres") {
            areaNum = area * 43560; // Convert acres to square feet
        } else {
            areaNum = area; // Assume the area is already in square feet
        }

        const calculateNutrients = () => {

            const nutrientsMap = {};

            spray.ingredients.forEach(ingredient => {
                const volumeOZ = (areaNum * (ingredient.rate / 1000));
                const volumeGal = (volumeOZ / 128);

                const weight = ingredient.weight;

                ingredient.nutrients.forEach(nutrient => {
                    const { name, percentage } = nutrient;
                    const value = (((percentage / 100) * weight) * volumeGal);

                    if (!nutrientsMap[name]) {
                        nutrientsMap[name] = parseFloat(value); // Parse value to number
                    } else {
                        nutrientsMap[name] += parseFloat(value); // Parse value to number and add
                    }
                });
            });

            return Object.entries(nutrientsMap).map(([name, totalValue]) => ({ name, totalValue }));
        };

        const uniqueNutrients = calculateNutrients();

        const totalNutrientWeight = uniqueNutrients.reduce((accumulator, current) => {
            return accumulator + (current.totalValue);
        }, 0).toFixed(2);

        return totalNutrientWeight;
    }

    return (
        <div className="bg-bgpage flex h-screen mt-16">

            <Dashboard/>


            <div className="flex-col w-full rounded-lg mr-3 mt-3 mb-3 overflow-y-auto max-h-[calc(100vh-5.5rem)] scrollbar-none">

                <div className="justify-center flex flex-row w-flex h-1/2 items-center ">


                    <div className="w-1/2 h-full mr-3 justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Cost per Spray</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full">
                                {sprays.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {sprayBarChartData && <Bar options={sprayOptions} data={sprayBarChartData}/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Spray Purposes</div>
                        <div
                            className="flex justify-center items-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={spraysChart} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Chemical Utilization</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={spraysChart2} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="justify-center flex flex-row w-flex h-1/2 items-center">


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Target Area Breakdown</div>
                        <div
                            className="flex justify-center items-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={spraysChart3} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Application Rate Makeup</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={spraysChart4} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 h-full">
                        <div className="text-litegreen w-full text-center mb-1">Nutrient Output Per Acre</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full">
                                {sprays.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {sprayBarChartData && <Bar options={nutrientOptions} data={nutrientBarChartData}/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>


            </div>


        </div>
    );
};


export default SpraysDash;