import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faFileUpload, faMagnifyingGlass,
    faSquareCaretLeft,
    faSquareCaretRight, faXmark
} from "@fortawesome/free-solid-svg-icons";
import {db} from "../../firebase/firebase";

const Vision = () => {
    const { currentUser } = useAuth();

    const [loading, setLoading] = useState(false);

    const [greens, setGreens] = useState([]);
    const [filteredGreens, setFilteredGreens] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [showHoles, setShowHoles] = useState(true);

    const toggleHoles = () => {
        setShowHoles(!showHoles);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const [image, setImage] = useState(null);
    const [analysisData, setAnalysisData] = useState([]);

    const handleUpload = async (file) => {
        setLoading(true);
        setImage(URL.createObjectURL(file));
        const data = await analyzeImage(file);
        setAnalysisData(data);
        setLoading(false);
    };

    useEffect(() => {
        const fetchCustomLocations = async () => {
            if (currentUser) {
                try {
                    const locationsSnapshot = await db.collection('Users').doc(currentUser.uid).collection('holes').get();
                    const locationsData = locationsSnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(location => location.type === 'Green'); // Filter locations with type 'Greens'

                    setGreens(locationsData);
                    setFilteredGreens(locationsData)
                    console.log("Fetched Locations:", locationsData);
                } catch (error) {
                    console.error('Error fetching custom locations:', error);
                }
            }
        };

        fetchCustomLocations();
    }, [currentUser]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = greens.filter(green =>
            green.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredGreens(filtered);
    };

    return (
        <div className="bg-bgpage flex h-full w-full mt-16">
            <Dashboard/>

            <div className="w-full h-full flex relative">

                <div className="flex flex-col w-full h-full">

                    <div className="mt-3 flex items-center justify-center w-full flex-row">

                        <div
                            className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-mg shadow-gray-900 w-full mr-2">

                            <div className="w-1/2">
                                <div className="flex items-center justify-center">
                                    <div className="w-1/4 text-center text-sm text-white mr-2">Moisture Level:</div>
                                    <div className="w-full h-5 rounded-md" style={{
                                        background: 'linear-gradient(to right, var(--tw-custom-gradient-start), var(--tw-custom-gradient-mid1), var(--tw-custom-gradient-mid2), var(--tw-custom-gradient-mid3), var(--tw-custom-gradient-end))'
                                    }}>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/4">
                                <div className="flex items-center justify-center">
                                    <div className="w/1/4 text-sm text-white mr-2 ml-2">Bunkers:</div>
                                    <div className="w-full h-5 rounded-md" style={{
                                        background: 'linear-gradient(to right, var(--tw-custom-gradient-bmid1), var(--tw-custom-gradient-bmid2), var(--tw-custom-gradient-bmid3), var(--tw-custom-gradient-bend))'
                                    }}>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/4">
                                <div className="flex items-center justify-center">
                                    <div className="w/1/4 text-sm text-white mr-2 ml-2">Water:</div>
                                    <div className="w-full h-5 rounded-md" style={{
                                        background: 'linear-gradient(to right, var(--tw-custom-gradient-wmid1), var(--tw-custom-gradient-wmid2), var(--tw-custom-gradient-wmid3), var(--tw-custom-gradient-wend))'
                                    }}>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <button className="ml-2 mr-3 text-3xl text-white" onClick={toggleCollapse}>
                            {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                <FontAwesomeIcon icon={faSquareCaretRight}/>}
                        </button>

                    </div>


                    <div className="relative h-full flex flex-row w-full">

                        <div className={`container pb-3 h-auto ${isCollapsed ? 'w-full pr-3 mr-auto ml-auto' : 'w-full pr-3 mr-auto ml-auto'}`}>

                            <div className="w-11/12 h-full mr-auto ml-auto flex justify-center items-center">


                                {image === null ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Image Selection is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Uploading an Image</p>

                                            <p className="text-xs text-white mt-2">1] On the Side Panel click "Select A Photo"</p>
                                            <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the Add Chem button</p>
                                            <p className="text-xs text-white mt-1">3] Press the Add Spray button to add it to your database</p>
                                            <p className="text-xs text-white mt-1">4] If your spray does not automatically show up on the screen</p>

                                            <p className="text-lg text-litegreen mt-2">Saving an Image to a Hole</p>

                                            <p className="text-xs text-white mt-2">1] You do not have any chemicals that match that Classification</p>

                                            <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                            <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {loading ? <SpinningProgressBar/> : (image &&
                                            <HeatMap image={image} analysisData={analysisData}/>)}
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className={`absolute top-0 right-0 h-[calc(100%-1.25rem)] ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col rounded-md shadow-md shadow-gray-900 mr-3 mb-3 mt-2 p-4 w-1/4'}`}>
                            <ImageUploader onUpload={handleUpload}/>

                            <button className="w-full rounded-md bg-darkgreen text-white p-2 mt-6" onClick={toggleHoles}>View Greens ({greens.length})</button>


                        </div>

                    </div>

                </div>


                {showHoles && (
                    <div className="absolute items-center justify-center flex w-full h-full">

                        <div className="w-3/4 h-3/4 bg-bgcolor rounded-md shadow-md shadow-gray-900 p-2">

                            <button className="w-full text-end text-white text-lg" onClick={toggleHoles}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </button>

                            <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 mr-24 ml-24">

                                <div className="mr-10">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm text-white mr-2 line-clamp-1">Total Greens:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{filteredGreens.length}</div>
                                    </div>
                                </div>

                                <div className="mr-10">
                                    <div className="flex items-center justify-center">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white mr-2"/>
                                        <input
                                            type="text"
                                            placeholder="Search Greens by Name"
                                            value={searchQuery}
                                            onChange={(e) => handleSearch(e.target.value)}
                                            className="bg-bglite appearance-none rounded-md w-full mr-2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                        />
                                    </div>
                                </div>

                                <div className="">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm text-white mr-2 line-clamp-1">Total Area:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{filteredGreens.length} acres</div>
                                    </div>
                                </div>

                            </div>


                            <div className='mt-2 w-full rounded-md overflow-y-auto max-h-[calc(80vh-11rem)] scrollbar-none'>

                                {filteredGreens.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Adding a Green</p>

                                            <p className="text-xs text-white mt-2">1] Fill out the panel on the right hand side of the screen</p>
                                            <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the Add Chem button</p>
                                            <p className="text-xs text-white mt-1">3] Press the Add Spray button to add it to your database</p>
                                            <p className="text-xs text-white mt-1">4] If your spray does not automatically show up on the screen refresh your page and it should appear</p>

                                            <p className="text-lg text-litegreen mt-2">Missing Classification</p>

                                            <p className="text-xs text-white mt-2">1] You do not have any chemicals that match that Classification</p>

                                            <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                            <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display GreensCard components if greens array is not empty
                                    filteredGreens.map((green, index) => (
                                        <GreensCard key={index} green={green}/>
                                    ))
                                )}


                            </div>


                        </div>

                    </div>
                )}


            </div>


        </div>
    );
};

const SpinningProgressBar = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
    );
};

const ImageUploader = ({onUpload}) => {
    // eslint-disable-next-line
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(URL.createObjectURL(file));
        onUpload(file);
    };

    return (
        <div className="flex flex-col items-center">
            <label className="w-full rounded-md bg-litegreen text-white text-xs text-center p-1 items-center justify-center">
                <FontAwesomeIcon icon={faFileUpload} className="text-lg mr-2"/>
                <span className="mt-2 text-center text-xs leading-normal">Upload Photo</span>
                <input type="file" accept="image/*" className="hidden" onChange={handleImageChange}/>
            </label>
            {/*{image && <img src={image} alt="Uploaded" className="mt-4 max-w-full h-auto"/>}*/}
        </div>
    );
};

const HeatMap = ({image, analysisData}) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = image;
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            analysisData.forEach(({x, y, intensity, fillColor}) => {
                ctx.fillStyle = fillColor;
                ctx.beginPath();
                ctx.arc(x, y, 1, 0, Math.PI * 2);
                ctx.fill();
            });
        };
    }, [image, analysisData]);

    return <canvas ref={canvasRef} className="w-full h-auto rounded-xl shadow-md shadow-gray-900"/>;
};


const analyzeImage = (imageFile) => {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        const specificColors = [
            // Green Colors
            {hex: '#0e2508', name: 'Green1', fillColor: 'rgba(37, 53, 17, 1)'},
            {hex: '#1a2913', name: 'Green2', fillColor: 'rgba(39, 59, 20, 0.9)'},
            {hex: '#214c05', name: 'Green3', fillColor: 'rgba(55, 78, 38, 0.5)'},
            {hex: '#1f4309', name: 'Green4', fillColor: 'rgba(90, 118, 60, 0.1)'},
            {hex: '#29441c', name: 'Green5', fillColor: 'rgba(50, 64, 31, 0.6)'},
            {hex: '#2b5413', name: 'Green6', fillColor: 'rgba(45, 74, 26, 0.8)'},
            {hex: '#335b24', name: 'Green7', fillColor: 'rgba(72, 107, 44, 0.4)'},
            {hex: '#37570e', name: 'Green8', fillColor: 'rgba(85, 104, 60, 0.3)'},
            {hex: '#3c6e25', name: 'Green9', fillColor: 'rgba(45, 60, 28, 0.7)'},
            {hex: '#3f6a21', name: 'Green10', fillColor: 'rgba(102, 126, 68, 0.5)'},
            {hex: '#477c1c', name: 'Green11', fillColor: 'rgba(87, 119, 56, 0.2)'},
            {hex: '#477b25', name: 'Green12', fillColor: 'rgba(134, 151, 101, 0.2)'},
            {hex: '#4d7e14', name: 'Green13', fillColor: 'rgba(96, 119, 62, 0.9)'},
            {hex: '#507b2c', name: 'Green14', fillColor: 'rgba(96, 125, 61, 0.8)'},
            {hex: '#5d9a33', name: 'Green15', fillColor: 'rgba(99, 122, 66, 0.7)'},
            {hex: '#6fa647', name: 'Green16', fillColor: 'rgba(102, 128, 64, 0.6)'},
            {hex: '#70a440', name: 'Green17', fillColor: 'rgba(134, 151, 101, 0.3)'},
            {hex: '#83b93e', name: 'Green18', fillColor: 'rgba(121, 146, 88, 0.4)'},
            {hex: '#accc83', name: 'Green19', fillColor: 'rgba(121, 146, 88, 0.4)'},


            // Dry Colors
            { hex: '#9aa98c', name: 'Dry1', fillColor: 'rgba(139, 0, 0, 0.2)' },
            { hex: '#a7c392', name: 'Dry2', fillColor: 'rgba(165, 42, 42, 0.8)' },
            { hex: '#9fbd7d', name: 'Dry3', fillColor: 'rgba(178, 34, 34, 0.8)' },
            { hex: '#8c9a77', name: 'Dry4', fillColor: 'rgba(220, 20, 60, 0.8)' },
            { hex: '#847860', name: 'Dry5', fillColor: 'rgba(255, 69, 0, 0.8)' },
            { hex: '#d2a470', name: 'Dry6', fillColor: 'rgba(255, 99, 71, 0.8)' },
            // { hex: '#86875d', name: 'Dry7', fillColor: 'rgba(255, 127, 80, 0.8)' },
            //      { hex: '#858261', name: 'Dry8', fillColor: 'rgba(255, 140, 0, 0.8)' },
            // { hex: '#7f815a', name: 'Dry9', fillColor: 'rgba(255, 165, 0, 0.8)' },
            // { hex: '#7d7d47', name: 'Dry10', fillColor: 'rgba(255, 182, 193, 0.8)' },
            // { hex: '#7b7a4c', name: 'Dry11', fillColor: 'rgba(255, 192, 203, 0.8)' },
            // { hex: '#778458', name: 'Dry12', fillColor: 'rgba(255, 218, 185, 0.8)' },
            // { hex: '#696d3a', name: 'Dry13', fillColor: 'rgba(255, 222, 173, 0.8)' },
            // { hex: '#4f5829', name: 'Dry14', fillColor: 'rgba(255, 228, 181, 0.8)' },
            // { hex: '#49502e', name: 'Dry15', fillColor: 'rgba(255, 239, 213, 0.8)' },

            // Cart Path
            {hex: '#778182', name: 'CartPath1', fillColor: 'rgba(64, 64, 64, 0.8)'},
            {hex: '#b6b9be', name: 'CartPath2', fillColor: 'rgba(64, 64, 64, 0.8)'},
            // {hex: '#a3a49f', name: 'CartPath3', fillColor: 'rgba(64, 64, 64, 0.8)'},
            // {hex: '#676662', name: 'CartPath4', fillColor: 'rgba(64, 64, 64, 0.8)'},
            // {hex: '#aaa9a4', name: 'CartPath5', fillColor: 'rgba(64, 64, 64, 0.8)'},
            // {hex: '#b8b7b3', name: 'CartPath6', fillColor: 'rgba(64, 64, 64, 0.8)'},

            // Bunker
            { hex: '#c5b59b', name: 'Bunker1', fillColor: 'rgba(153, 153, 0, 0.8)' },
            { hex: '#bfb099', name: 'Bunker2', fillColor: 'rgba(160, 160, 0, 0.8)' },
            { hex: '#beb7a7', name: 'Bunker3', fillColor: 'rgba(170, 170, 0, 0.8)' },
            { hex: '#c3b59a', name: 'Bunker4', fillColor: 'rgba(180, 180, 0, 0.8)' },
            { hex: '#ebebe9', name: 'Bunker5', fillColor: 'rgba(180, 180, 0, 0.8)' },


            // Water
            // { hex: '#1e311e', name: 'Water1', fillColor: 'rgba(0, 25, 51, 1)' },
            // { hex: '#222d27', name: 'Water2', fillColor: 'rgba(0, 38, 77, 0.9)' },
            // { hex: '#394249', name: 'Water3', fillColor: 'rgba(0, 51, 102, 0.8)' },
            // { hex: '#617085', name: 'Water4', fillColor: 'rgba(0, 64, 128, 0.7)' },


        ]; // Add your specific colors here
        const colorThreshold = 30; // Adjust this value to be more specific

        img.src = URL.createObjectURL(imageFile);
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, img.width, img.height);
            const data = imageData.data;
            const analysisData = [];

            const hexToRgb = (hex) => {
                const bigint = parseInt(hex.slice(1), 16);
                const r = (bigint >> 16) & 255;
                const g = (bigint >> 8) & 255;
                const b = (bigint & 255);
                return {r, g, b};
            };

            const colorDistance = (color1, color2) => {
                return Math.sqrt(
                    Math.pow(color1.r - color2.r, 2) +
                    Math.pow(color1.g - color2.g, 2) +
                    Math.pow(color1.b - color2.b, 2)
                );
            };

            specificColors.forEach(color => {
                const targetColor = hexToRgb(color.hex);

                for (let y = 0; y < img.height; y++) {
                    for (let x = 0; x < img.width; x++) {
                        const index = (y * img.width + x) * 4;
                        const r = data[index];
                        const g = data[index + 1];
                        const b = data[index + 2];

                        if (colorDistance({ r, g, b }, targetColor) <= colorThreshold) {
                            analysisData.push({
                                x: x,
                                y: y,
                                intensity: 1, // Full intensity for matched colors
                                fillColor: color.fillColor // Use the fill color associated with the detected color
                            });
                        }
                    }
                }
            });

            resolve(analysisData);
        };
    });
};


const GreensCard = ({green}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion
    // eslint-disable-next-line
    const [image, setImage] = useState(null);

    // const handleGreenUpload = async (file) => {
    //     setImage(URL.createObjectURL(file));
    //     console.log("Image Selected:", image);
    // };

    const handleUpload = async (file) => {
        // setLoading(true);
        setImage(URL.createObjectURL(file));
        console.log("Image Selected:", file);
        // const data = await analyzeImage(file);
        // setAnalysisData(data);
        // setLoading(false);
    };
    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };


    return (
        <div className="bg-bgcolor p-2 rounded-md items-center justify-center  shadow-md shadow-gray-900 mb-2 w-full">


            <div className="flex items-center justify-center">

                <div
                    className="bg-darkgreen h-full rounded-md flex flex-col items-center justify-center p-2 w-full shadow-md shadow-gray-900 mr-5">
                    <div className="text-xl text-white text-center line-clamp-1">{green.name}</div>
                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mr-5">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Longitude:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Latitude:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className="rounded-md border-2 p-1 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{green.longitude}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{green.latitude}</div>
                        </div>

                    </div>

                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mr-5">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Total Area:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Total Photos:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className="rounded-md border-2 p-1 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{0}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{0}</div>
                        </div>

                    </div>

                </div>

                <div className="h-full w-1/2 rounded-md flex items-center justify-center mr-2">

                    <div className="flex flex-col items-center justify-center w-full">

                        {/*<button onClick={handleUpload} className="w-full rounded-md bg-litegreen text-white text-xs p-1">*/}
                        {/*    <FontAwesomeIcon icon={faFileUpload} className="mr-2"/>Upload Photo*/}
                        {/*</button>*/}

                        <ImageUploader onUpload={handleUpload}/>

                    </div>


                </div>

                <div className="flex flex-col items-center">

                    <button className="text-xxl text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>



                </>
            )}

        </div>
    );
};


export default Vision;


// { hex: '#253511', name: 'Green1', fillColor: 'rgba(32, 48, 14, 0.8)' },
// { hex: '#273b14', name: 'Green2', fillColor: 'rgba(34, 54, 17, 0.8)' },
// { hex: '#2d4a1a', name: 'Green3', fillColor: 'rgba(40, 69, 23, 0.8)' },
// { hex: '#2d3c1c', name: 'Green4', fillColor: 'rgba(40, 55, 25, 0.8)' },
// { hex: '#32401f', name: 'Green5', fillColor: 'rgba(45, 59, 28, 0.8)' },
// { hex: '#374e26', name: 'Green6', fillColor: 'rgba(50, 73, 35, 0.8)' },
// { hex: '#466a2c', name: 'Green7', fillColor: 'rgba(66, 102, 41, 0.8)' },
// { hex: '#486b2c', name: 'Green8', fillColor: 'rgba(78, 99, 56, 0.8)' },
// { hex: '#4e6e2b', name: 'Green9', fillColor: 'rgba(80, 114, 52, 0.8)' },
// { hex: '#4f7135', name: 'Green10', fillColor: 'rgba(82, 113, 54, 0.8)' },
// { hex: '#55683c', name: 'Green11', fillColor: 'rgba(85, 114, 56, 0.8)' },
// { hex: '#55683c', name: 'Green12', fillColor: 'rgba(90, 120, 55, 0.8)' },
// { hex: '#5a763c', name: 'Green13', fillColor: 'rgba(92, 117, 60, 0.8)' },
// { hex: '#60773e', name: 'Green14', fillColor: 'rgba(95, 122, 59, 0.8)' },
// { hex: '#607d3d', name: 'Green15', fillColor: 'rgba(95, 120, 63, 0.8)' },
// { hex: '#63803e', name: 'Green16', fillColor: 'rgba(112, 140, 83, 0.8)' },
// { hex: '#637a42', name: 'Green17', fillColor: 'rgba(124, 145, 96, 0.8)' },
// { hex: '#658345', name: 'Green18', fillColor: 'rgba(135, 157, 108, 0.8)' },
// { hex: '#667e44', name: 'Green19', fillColor: 'rgba(142, 164, 114, 0.8)' },
// { hex: '#668040', name: 'Green20', fillColor: 'rgba(150, 171, 124, 0.8)' },
// { hex: '#6a8747', name: 'Green21', fillColor: 'rgba(156, 176, 130, 0.8)' },
// { hex: '#6b874d', name: 'Green22', fillColor: 'rgba(162, 182, 135, 0.8)' },
// { hex: '#6c884e', name: 'Green23', fillColor: 'rgba(168, 187, 140, 0.8)' },
// { hex: '#708a4d', name: 'Green24', fillColor: 'rgba(174, 192, 145, 0.8)' },
// { hex: '#728b52', name: 'Green25', fillColor: 'rgba(180, 198, 150, 0.8)' },
// { hex: '#799258', name: 'Green26', fillColor: 'rgba(186, 203, 156, 0.8)' },
// { hex: '#7f815a', name: 'Green27', fillColor: 'rgba(190, 207, 161, 0.8)' },
// { hex: '#858261', name: 'Green28', fillColor: 'rgba(194, 211, 166, 0.8)' },
// { hex: '#869765', name: 'Green29', fillColor: 'rgba(198, 215, 171, 0.8)' },
// { hex: '#869765', name: 'Green30', fillColor: 'rgba(202, 219, 176, 0.8)' },


// {hex: '#253511', name: 'Green1', fillColor: 'rgba(37, 53, 17, 1)'},
// {hex: '#273b14', name: 'Green2', fillColor: 'rgba(39, 59, 20, 0.9)'},
// {hex: '#2d4a1a', name: 'Green3', fillColor: 'rgba(45, 74, 26, 0.8)'},
// {hex: '#2d3c1c', name: 'Green4', fillColor: 'rgba(45, 60, 28, 0.7)'},
// {hex: '#32401f', name: 'Green5', fillColor: 'rgba(50, 64, 31, 0.6)'},
// {hex: '#374e26', name: 'Green6', fillColor: 'rgba(55, 78, 38, 0.5)'},
// {hex: '#486b2c', name: 'Green7', fillColor: 'rgba(72, 107, 44, 0.4)'},
// {hex: '#55683c', name: 'Green8', fillColor: 'rgba(85, 104, 60, 0.3)'},
// {hex: '#577738', name: 'Green9', fillColor: 'rgba(87, 119, 56, 0.2)'},
// {hex: '#5a763c', name: 'Green10', fillColor: 'rgba(90, 118, 60, 0.1)'},
// {hex: '#65894c', name: 'Green102', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#1d2f19', name: 'Green103', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#1a3212', name: 'Green104', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#041300', name: 'Green105', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#264019', name: 'Green106', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#132a0d', name: 'Green107', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // {hex: '#2a421e', name: 'Green108', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // // {hex: '#264019', name: 'Green109', fillColor: 'rgba(90, 118, 60, 0.1)'},
// // // {hex: '#132a0d', name: 'Green1010', fillColor: 'rgba(90, 118, 60, 0.1)'},
//
// {hex: '#60773e', name: 'Green11', fillColor: 'rgba(96, 119, 62, 0.9)'},
// {hex: '#607d3d', name: 'Green12', fillColor: 'rgba(96, 125, 61, 0.8)'},
// {hex: '#637a42', name: 'Green13', fillColor: 'rgba(99, 122, 66, 0.7)'},
// {hex: '#668040', name: 'Green14', fillColor: 'rgba(102, 128, 64, 0.6)'},
// {hex: '#667e44', name: 'Green15', fillColor: 'rgba(102, 126, 68, 0.5)'},
// {hex: '#799258', name: 'Green16', fillColor: 'rgba(121, 146, 88, 0.4)'},
// {hex: '#869765', name: 'Green17', fillColor: 'rgba(134, 151, 101, 0.3)'},
// {hex: '#869468', name: 'Green18', fillColor: 'rgba(134, 151, 101, 0.2)'},
