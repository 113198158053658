import React, {useEffect, useState} from 'react'
import { Navigate, Link } from 'react-router-dom'
import { doSignInWithEmailAndPassword, doPasswordReset } from '../../../firebase/auth' // Import password reset function
import { useAuth } from '../../../contexts/authContext'
import {db} from "../../../firebase/firebase";

const Login = () => {
    const { userLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [defaultPage, setDefaultPage] = useState('')

    const [isSigningIn, setIsSigningIn] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [showResetPopup, setShowResetPopup] = useState(false)
    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            await doSignInWithEmailAndPassword(email, password)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (userLoggedIn) {
                try {
                    const userDoc = await db.collection('Users').doc(userLoggedIn.uid).get();
                    if (userDoc.exists) {
                        const userData = userDoc.data();
                        const userDefaultPage = userData.defaultPage || '';
                        console.log('User defaultPage:', userDefaultPage);
                        setDefaultPage(userDefaultPage);
                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [userLoggedIn]);

    console.log('Default page:', defaultPage);

    const handleResetSubmit = async (e) => {
        e.preventDefault()
        try {
            await doPasswordReset(resetEmail)
            alert('Password reset email sent! Please check your inbox.')
            setShowResetPopup(false)
        } catch (error) {
            console.error('Error resetting password:', error.message)
        }
    }

    return (
        <div>

            {userLoggedIn && (
                defaultPage === 'Calendar' ? <Navigate to={'/Calendar'} replace={true} /> :
                defaultPage === 'Home' ? <Navigate to={'/Home'} replace={true} /> :
                defaultPage === 'Chemical Database' ? <Navigate to={'/Chems'} replace={true} /> :
                defaultPage === 'Spray Database' ? <Navigate to={'/Sprays'} replace={true} /> :
                // Add more conditions for other default pages as needed
                <Navigate to={'/Home'} replace={true} /> // Default to home page if defaultPage is null or empty
            )}

            <Link to={'/landing'} className="absolute top-4 left-4 text-white text-lg font-medium border border-white p-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200">Return to Home</Link>

            <main className="w-full h-screen flex self-center place-content-center place-items-center bg-bgcolor">

                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-2xl rounded-xl ">

                    <div className="text-center">
                        <div className="mt-2">
                            <h3 className="text-white text-xl font-semibold sm:text-2xl">Sign In</h3>
                        </div>
                    </div>
                    <form
                        onSubmit={onSubmit}
                        className="space-y-5"
                    >
                        <div>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                placeholder="Email:"
                                value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />

                        </div>


                        <div>
                            <input
                                type="password"
                                autoComplete='current-password'
                                required
                                placeholder="Password:"
                                value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isSigningIn}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isSigningIn ? 'bg-gray-300 cursor-not-allowed' : 'bg-darkgreen hover:bg-litegreen hover:shadow-xl transition duration-300'}`}
                        >
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>
                    </form>
                    <p className="text-center text-sm text-white">Don't have an account? <Link to={'/register'} className="hover:underline font-bold ">Sign up</Link></p>

                    <button onClick={() => setShowResetPopup(true)} className="hover:underline font-bold mt-4">Forgot Password?</button>
                </div>

            </main>


            {/* Reset Password Popup */}
            {showResetPopup && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-bgcolor p-8 rounded-lg shadow-lg">
                        <h2 className="text-xl text-white font-semibold mb-4">Reset Password</h2>
                        <form onSubmit={handleResetSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                className="w-full px-3 py-2 mb-4 mt-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                required
                            />
                            <div className="flex justify-end">
                                <button type="submit" className="px-4 py-2 bg-darkgreen text-white rounded-lg">Reset Password</button>
                                <button onClick={() => setShowResetPopup(false)} className="ml-4 px-4 py-2 bg-red-700 text-white rounded-lg">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Login
