import React, { useState, useEffect } from 'react';
import Dashboard from "../Dashboard";
import {useAuth} from "../../contexts/authContext";
import {db} from "../../firebase/firebase";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {Pie, Bar, Doughnut} from 'react-chartjs-2';

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const payOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Employee Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Pay Rate',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

export const tenureOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Employee Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Length',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

const TeamDash = () => {

    const { currentUser } = useAuth();

    // eslint-disable-next-line
    const [employees, setEmployees] = useState([]);
    const [payCounts, setPayCounts] = useState({});
    const [typeCounts, setTypeCounts] = useState({});
    const [positionCounts, setPositionCounts] = useState({});
    const [departmentCounts, setDepartmentCounts] = useState({});
    const [employeeBarChartData, setEmployeeBarChartData] = useState(null);
    // const [priceBarChartData, setPriceBarChartData] = useState(null);



    useEffect(() => {
        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const CollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                    const snapshot = await CollectionRef.get();

                    if (!snapshot.empty) {
                        const Data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setEmployees(Data);

                        const barChartData = {
                            labels: Data.map(employee => employee.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Pay Rate Hourly',
                                    backgroundColor: 'rgba(75, 205, 129, 0.2)',
                                    borderColor: 'rgba(75, 205, 129, 0.8)',
                                    borderWidth: 2,
                                    data: Data.map(employee => employee.payRate) // Calculating total price for each document
                                }
                            ]
                        };
                        setEmployeeBarChartData(barChartData);

                        // const priceBarChartData = {
                        //     labels: chemsData.map(chem => chem.name), // Using document IDs as labels
                        //     datasets: [
                        //         {
                        //             label: 'Unit Price',
                        //             backgroundColor: 'rgba(122, 197, 158, 0.2)',
                        //             borderColor: 'rgba(122, 197, 159, 0.8)',
                        //             borderWidth: 2,
                        //             data: chemsData.map(chem => chem.unitprice) // Calculating total price for each document
                        //         }
                        //     ]
                        // };
                        // setPriceBarChartData(priceBarChartData);

                        const counts = {};
                        Data.forEach(doc => {
                            const pay = doc.payRate;
                            counts[pay] = (counts[pay] || 0) + 1;
                        });
                        setPayCounts(counts);

                        const counts2 = {};
                        Data.forEach(doc => {
                            const type = doc.type;
                            counts2[type] = (counts2[type] || 0) + 1;
                        });
                        setTypeCounts(counts2);

                        const counts3 = {};
                        Data.forEach(doc => {
                            const position = doc.position;
                            counts3[position] = (counts3[position] || 0) + 1;
                        });
                        setPositionCounts(counts3);

                        const counts4 = {};
                        Data.forEach(doc => {
                            const depart = doc.department;
                            counts4[depart] = (counts4[depart] || 0) + 1;
                        });
                        setDepartmentCounts(counts4);


                    } else {
                        console.log('No chems found');
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };
        fetchEmployees();
    }, [currentUser]);


    const employeeChart = {
        labels: Object.keys(payCounts),
        datasets: [
            {
                label: '# of Employees',
                data: Object.values(payCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const employeeChart2 = {
        labels: Object.keys(typeCounts),
        datasets: [
            {
                label: '# of Employees',
                data: Object.values(typeCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const employeeChart3 = {
        labels: Object.keys(positionCounts),
        datasets: [
            {
                label: '# of Employees',
                data: Object.values(positionCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };

    const employeeChart4 = {
        labels: Object.keys(departmentCounts),
        datasets: [
            {
                label: '# of Employees',
                data: Object.values(departmentCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="bg-bgpage flex h-screen mt-16">

            <Dashboard/>


            <div className="flex-col w-full rounded-lg mr-3 mt-3 mb-3 overflow-y-auto max-h-[calc(100vh-5.5rem)] scrollbar-none">

                <div className="justify-center flex flex-row w-flex h-1/2 items-center ">


                    <div className="w-1/2 h-full mr-3 justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Pay Rate Per Employee</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full">
                                {employees.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div className="bg-bgcolor p-4 rounded-xl shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data displayed in these charts you must first add data to your database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {employeeBarChartData && <Bar options={payOptions} data={employeeBarChartData} className="p-2"/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Pay Rate Breakdown</div>
                        <div className="flex justify-center items-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={employeeChart} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Employee Makeup</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={employeeChart2} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                </div>





                <div className="justify-center flex flex-row w-flex h-1/2 items-center">


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Position Breakdown</div>
                        <div
                            className="flex justify-center items-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={employeeChart3} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Department Breakdown</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={employeeChart4} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 h-full">
                        <div className="text-litegreen w-full text-center mb-1">Unit Price Per Chemical</div>
                        <div className="flex justify-center rounded-xl bg-bgcolor shadow-md shadow-gray-800">
                            <div className="w-full max-w-screen-md h-full">
                                {employees.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {/*{chemsBarChartData && <Bar options={priceOptions} data={priceBarChartData} className="p-2"/>}*/}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>


            </div>


        </div>
    );
};


export default TeamDash;
