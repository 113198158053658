import React from 'react';
import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Chems from "./components/Chems";
import Landing from "./components/landing";
import Sprays from "./components/Sprays";
import Account from "./components/Account"
import FAQ from "./components/FAQ"
import Home from "./components/Home";
import Fleet from "./components/Fleet";
import LandingChems from "./components/LandingChems";
import LandingSprays from "./components/LandingSprays";
import LandingFleet from "./components/LandingFleet";
import ChemsDash from "./components/ChemsDash";
import SpraysDash from "./components/SpraysDash";
import FleetDash from "./components/FleetDash";
import MyCalendar from "./components/Calendar";
import Team from "./components/Team";
import LandingTeam from "./components/LandingTeam";
import LandingCalendar from "./components/LandingCalendar";
import TeamDash from "./components/TeamDash";
import MapView from "./components/Map";
import Vision from "./components/Vision";

function App() {
  const routesArray = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/Chems",
      element: <Chems />,
    },
    {
      path: "/ChemsDash",
      element: <ChemsDash />,
    },
    {
      path: "/Sprays",
      element: <Sprays />,
    },
    {
      path: "/SpraysDash",
      element: <SpraysDash />,
    },
    {
      path: "/Fleet",
      element: <Fleet />,
    },
    {
      path: "/FleetDash",
      element: <FleetDash />,
    },
    {
      path: "/Team",
      element: <Team />,
    },
    {
      path: "/TeamDash",
      element: <TeamDash />,
    },
    {
      path: "/Calendar",
      element: <MyCalendar />,
    },
    {
      path: "/Account",
      element: <Account />,
    },
    {
      path: "/FAQ",
      element: <FAQ />,
    },
    {
      path: "/Home",
      element: <Home />,
    },
    {
      path: "/Map",
      element: <MapView />,
    },
    {
      path: "/Vision",
      element: <Vision />,
    },
    {
      path: "*", // Wildcard route should be at the end
      element: <Landing />,
    },
    {
      path: "/LandingChems", // Wildcard route should be at the end
      element: <LandingChems />,
    },
    {
      path: "/LandingSprays", // Wildcard route should be at the end
      element: <LandingSprays />,
    },
    {
      path: "/LandingFleet", // Wildcard route should be at the end
      element: <LandingFleet />,
    },
    {
      path: "/LandingTeam", // Wildcard route should be at the end
      element: <LandingTeam />,
    },
    {
      path: "/LandingCalendar", // Wildcard route should be at the end
      element: <LandingCalendar />,
    },
  ];

  const routesElement = useRoutes(routesArray);

  return (
      <AuthProvider>
        {/*<Header />*/}
        <div className="w-full h-screen flex flex-col">{routesElement}</div>
      </AuthProvider>
  );
}

export default App;
