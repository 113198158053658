import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import { db } from '../../firebase/firebase';
import {
    faTrash,
    faEdit,
    faDownload,
    faFileExcel,
    faCaretDown,
    faCaretUp,
    faMagnifyingGlass, faSquareCaretLeft, faSquareCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar} from "react-chartjs-2";
import {XCircleIcon} from "@heroicons/react/24/outline";

export const employeeOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Hourly',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const employeeOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Salary',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Team = () => {
    const { currentUser } = useAuth();
    const [employees, setEmployees] = useState([]);
    const [teamSide, setTeamSide] = useState(false);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPosition, setSelectedPosition] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        numId: '',
        position: '',
        department: '',
        phone: '',
        email: '',
        eName: '',
        ePhone: '',
        eRelation: '',
        startDate: '',
        payRate: 0,
        payType: '',
        type: '',
    });
    const [editingId, setEditingId] = useState(null);

    const onUpdate = async () => {
        try {
            const employeeCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
            const snapshot = await employeeCollectionRef.get();
            const teamData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEmployees(teamData);
            setFilteredEmployees(teamData);
            localStorage.setItem(`teamData_${currentUser.uid}`, JSON.stringify(teamData));
        } catch (error) {
            console.error('Error updating employee data:', error);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const storedTeamData = localStorage.getItem(`teamData_${currentUser.uid}`);
                    if (storedTeamData) {
                        const teamData = JSON.parse(storedTeamData);
                        setEmployees(teamData);
                        setFilteredEmployees(teamData);
                    } else {
                        const teamCollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                        const snapshot = await teamCollectionRef.get();
                        if (!snapshot.empty) {
                            const teamData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setEmployees(teamData);
                            setFilteredEmployees(teamData);
                            localStorage.setItem(`teamData_${currentUser.uid}`, JSON.stringify(teamData));
                        } else {
                            console.log('No employees found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setTeamSide(userData.teamSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setTeamSide(userData.teamSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchEmployees();
    }, [currentUser]);

    const deleteEmployeeFromFirestore = async (employeeId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('team').doc(employeeId).delete();
            console.log('Employee deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    const fillFormWithEmployeeData = (employee) => {
        setFormData({
            name: employee.name,
            numId: employee.numId,
            position: employee.position,
            department: employee.department,
            phone: employee.phone,
            email: employee.email,
            eName: employee.eName,
            ePhone: employee.ePhone,
            eRelation: employee.eRelation,
            startDate: employee.startDate,
            payRate: employee.payRate,
            payType: employee.payType,
            type: employee.type,
        });
        setEditingId(employee.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            numId: '',
            position: '',
            department: '',
            phone: '',
            email: '',
            eName: '',
            ePhone: '',
            eRelation: '',
            startDate: '',
            payRate: 0,
            payType: '',
            type: '',
        });
        setEditingId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('team');

        // If date field is empty, set it to the current date
        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            startDate: formData.startDate || currentDate
        };

        const parsedFormData = {
            ...formData,
            ...formDataWithDate,
            payRate: parseFloat(formData.payRate),
        };

        try {
            if (editingId) {
                await collectionRef.doc(editingId).update(parsedFormData);
                console.log('Employee updated successfully!');
                await onUpdate();
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Employee added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEmployees(updatedData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating Employee: ', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Function to filter chems based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = employees.filter(employee =>
            employee.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredEmployees(filtered);
    };

    const filterEmployeesByPosition = (position) => {
        setSelectedPosition(position);
        const filtered = employees.filter(employee => position === '' || employee.position === position);
        setFilteredEmployees(filtered);
    };

    const calculateTotalHourly = (employee) => {
        if(employee.payType === "Hourly"){
            return (employee.payRate);
        } else {
            return 0;
        }
    };

    const calculateTotalSalary = (employee) => {
        if(employee.payType === "Salary"){
            return (employee.payRate);
        } else {
            return 0;
        }
    };

    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');

    const downloadAsExcel = () => {
        const columnHeaders = ['department', 'email', 'name', 'numId', 'payRate', 'payType', 'phone', 'position', 'startDate', 'type', 'eName', 'ePhone', 'eRelation'];
        const worksheetData = [columnHeaders, ...employees.map(employee => [employee.department, employee.email, employee.name, employee.numId, employee.payRate, employee.payType, employee.phone, employee.position, employee.startDate, employee.type, employee.eName, employee.ePhone, employee.eRelation])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Employees');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'EmployeeData.xlsx');
    };



    const addOrUpdateButtonText = editingId ? 'Update Employee' : 'Add Employee';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(teamSide); // Set isCollapsed to the value of chemSide from Firebase
    }, [teamSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const calculateAverageHourly = (employees) => {
        const HourlyEmployees = employees.filter(employee => employee.payType === "Hourly");

        if (HourlyEmployees.length === 0) {
            return 0;
        }

        const totalCost = HourlyEmployees.reduce((accumulator, employee) => {
            return accumulator + employee.payRate;
        }, 0);

        const average = totalCost / HourlyEmployees.length;
        return average.toFixed(2);
    };

    const calculateAverageSalary = (employees) => {
        const SalaryEmployees = employees.filter(employee => employee.payType === "Salary");

        if (SalaryEmployees.length === 0) {
            return 0;
        }

        const totalCost = SalaryEmployees.reduce((accumulator, employee) => {
            return accumulator + employee.payRate;
        }, 0);

        const average = totalCost / SalaryEmployees.length;
        return average.toFixed(2);
    };

    const calcRates = (employees) => {
        const filteredHourEmployees = employees.filter(employee => employee.payType === "Hourly");

        return filteredHourEmployees.map(employee => ({
            name: employee.name,
            rate: employee.payRate
        }));
    };

    const calcSalaries = (employees) => {
        const filteredSalEmployees = employees.filter(employee => employee.payType === "Salary");

        return filteredSalEmployees.map(employee => ({
            name: employee.name,
            rate: employee.payRate
        }));
    };


    const rates = calcRates(filteredEmployees);
    const salaries = calcSalaries(filteredEmployees);


    const averageHourly = calculateAverageHourly(filteredEmployees);
    const averageSalary = calculateAverageSalary(filteredEmployees);

    const employeeBarChartData2 = {
        labels: salaries.map(employee => employee.name),
        datasets: [
            {
                label: 'Rate',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: salaries.map(employee => employee.rate.toFixed(2)),
            }
        ]
    };

    const employeeBarChartData = {
        labels: rates.map(employee => employee.name),
        datasets: [
            {
                label: 'Rate',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: rates.map(employee => employee.rate.toFixed(2)),
            }
        ]
    };



    return (
        <div className="bg-bgpage flex h-full w-full mt-16">


            <Dashboard/>


            <div className="w-full flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    <div className="flex flex-col justify-center items-center">

                        <div className="flex items-center justify-center w-full">

                            <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 w-full mr-2">

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Employees:</div>
                                        <div className="text-md text-white rounded-md border-2 p-1 border-solid border-darkgreen">{filteredEmployees.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Hourly Rate:</div>
                                        <div className="text-md text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(filteredEmployees.reduce((total, employee) => total + parseFloat(calculateTotalHourly(employee)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Salary:</div>
                                        <div className="text-md text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(filteredEmployees.reduce((total, employee) => total + parseFloat(calculateTotalSalary(employee)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <button className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2" onClick={toggleExpansion}>
                                    {expanded ? <FontAwesomeIcon icon={faCaretUp}/> :
                                        <FontAwesomeIcon icon={faCaretDown}/>}
                                </button>

                            </div>

                            <button onClick={downloadAsExcel} className="bg-darkgreen hover:bg-litegreen text-white flex font-bold p-2 rounded-md focus:outline-none focus:shadow-outline">
                                <FontAwesomeIcon icon={faDownload} className="text-white mr-2"/>
                                <FontAwesomeIcon icon={faFileExcel} className="text-white"/>
                            </button>

                            <button className="ml-2 text-3xl text-white" onClick={toggleCollapse}>
                                {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                    <FontAwesomeIcon icon={faSquareCaretRight}/>}
                            </button>

                        </div>


                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div className="mt-2 mr-2 ml-2 p-2 bg-bgcolor flex items-center justify-center rounded-md shadow-mg shadow-gray-900 w-full">


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Hourly Comparison:</div>
                                            <Bar options={employeeOptions} data={employeeBarChartData} className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div className="w-full mr-2 rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Hourly:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageHourly)}</div>
                                                </div>
                                            </div>


                                            <div className="w-full rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Salary:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageSalary)}</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div className="mr-auto ml-auto rounded-md bg-bglite pl-6 pr-6 pb-1 pt-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Service Cost:</div>
                                                    {/*<div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageTotalCost)}</div>*/}
                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Salary Comparison:</div>
                                            <Bar options={employeeOptions2} data={employeeBarChartData2} className="mr-auto ml-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}


                    </div>


                    <div className="mt-2 w-full flex flex-row items-center">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white mr-2"/>
                        <input
                            type="text"
                            placeholder="Search Employees By Name"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className="bg-bglite appearance-none rounded-md w-full mr-2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                        />

                        <div className="w-1/4 flex flex-row items-center">
                            <select
                                value={selectedPosition}
                                onChange={(e) => filterEmployeesByPosition(e.target.value)}
                                className="bg-bglite appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">All Positions</option>

                                <option value="SuperIntendent">SuperIntendent</option>
                                <option value="GreensKeeper">GreensKeeper</option>
                                <option value="Assistant">Assistant</option>
                                <option value="Mechanic">Mechanic</option>
                                <option value="Assistant Mechanic">Assistant Mechanic</option>
                                <option value="Cart Staff">Cart Staff</option>
                                {/* Add more options as needed */}
                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>

                        </div>

                    </div>


                    <div className={` ${expanded ? 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-23.4rem)] scrollbar-none' : 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-11.25rem)] scrollbar-none'}`}>


                        {employees.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div className="bg-bgcolor p-4 rounded-md shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a employee</p>

                                    <p className="text-xs text-white mt-2">1] Fill out the panel on the right hand side
                                        of the screen</p>
                                    <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the
                                        Add Chem button</p>
                                    <p className="text-xs text-white mt-1">3] Press the Add Spray button to add it to
                                        your database</p>
                                    <p className="text-xs text-white mt-1">4] If your spray does not automatically show
                                        up on the screen refresh your page and it should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the
                                        Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredEmployees.map((employee, index) => (
                                <EmployeeCard key={index} employee={employee}
                                              onDelete={() => deleteEmployeeFromFirestore(employee.id)}
                                              onEdit={fillFormWithEmployeeData}/>
                            ))
                        )}


                    </div>


                </div>


                <div className={` ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col rounded-md shadow-lg shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/4'}`}>

                    <div className="text-white justify-center items-center text-center text-lg animate-fadeIn">Add Employee</div>

                    <button type="button" onClick={clearForm} className="absolute font-bold py-1 px-1 rounded-md mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                        <XCircleIcon className="w-6 hover:text-red-700 flex-none text-white" aria-hidden="true"/>
                    </button>

                    <form onSubmit={handleSubmit} className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                        <div className="text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>

                        <div className="flex items-center justify-center mt-2 mb-2">

                            <label className="block text-white text-sm mb-2 mr-5" htmlFor="name">
                                Name:
                            </label>

                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="numId">
                                ID Num:
                            </label>

                            <input
                                type="text"
                                id="numId"
                                name="numId"
                                value={formData.numId}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="position">
                                Position:
                            </label>

                            <select
                                id="position"
                                name="position"
                                value={formData.position}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 w-full text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="SuperIntendent">SuperIntendent</option>
                                <option value="GreensKeeper">GreensKeeper</option>
                                <option value="Assistant">Assistant</option>
                                <option value="Mechanic">Mechanic</option>
                                <option value="Assistant Mechanic">Assistant Mechanic</option>
                                <option value="Cart Staff">Cart Staff</option>

                            </select>
                        </div>

                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="department">
                                Department:
                            </label>

                            <select
                                id="department"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 w-full text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Grounds Crew">Grounds Crew</option>
                                <option value="Maintance">Maintance</option>
                                <option value="Cart Staff">Cart Staff</option>
                                <option value="Admin">Admin</option>

                            </select>
                        </div>


                        <div className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">CONTACT
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="phone">
                                Phone:
                            </label>

                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="email">
                                Email:
                            </label>

                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">EMERGENCY
                            CONTACT
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="eName">
                                Name:
                            </label>

                            <input
                                type="text"
                                id="eName"
                                name="eName"
                                value={formData.eName}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="ePhone">
                                Phone:
                            </label>

                            <input
                                type="text"
                                id="ePhone"
                                name="ePhone"
                                value={formData.ePhone}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/3" htmlFor="eRelation">
                                Relation:
                            </label>

                            <input
                                type="text"
                                id="eRelation"
                                name="eRelation"
                                value={formData.eRelation}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">EMPLOYEE DATA
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5 w-1/2" htmlFor="startDate">
                                Start Date:
                            </label>

                            <input
                                type="text"
                                id="startDate"
                                name="startDate"
                                value={formData.startDate || currentDate}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex mb-2">
                            <div className="w-full mr-5">
                                <label className="block text-white text-sm mb-2" htmlFor="payRate">
                                    Pay Rate:
                                </label>

                                <input
                                    type="number"
                                    id="payRate"
                                    name="payRate"
                                    value={formData.payRate}
                                    onChange={handleChange}
                                    className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <div className="w-full">
                                <label className="block text-white text-sm mb-2" htmlFor="payType">
                                    Type:
                                </label>

                                <select
                                    id="payType"
                                    name="payType"
                                    value={formData.payType}
                                    onChange={handleChange}
                                    className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="Salary">Salary</option>
                                    <option value="Hourly">Hourly</option>

                                </select>
                            </div>
                        </div>

                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="type">
                                Employment:
                            </label>

                            <select
                                id="type"
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 w-full text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Full Time">Full Time</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Seasonal">Seasonal</option>

                            </select>
                        </div>


                        {/* Add more input fields as needed */}
                        <div className="flex justify-center mt-4 w-full">
                            {/* Add or Update Chem button */}
                            <button type="submit"
                                    className="w-full bg-darkgreen hover:bg-litegreen text-white text-sm font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                {addOrUpdateButtonText}
                            </button>
                        </div>


                    </form>

                </div>


            </div>


        </div>
    );
};


const EmployeeCard = ({employee, onDelete, onEdit}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(employee.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const DateDifference = ({ dateString }) => {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const differenceMs = currentDate - inputDate;
        const differenceDate = new Date(differenceMs);
        const years = differenceDate.getUTCFullYear() - 1970;
        const months = differenceDate.getUTCMonth();
        const days = differenceDate.getUTCDate() - 1;

        let differenceString = '';

        if (days === 0) {
            differenceString += `Hired Today!`;
        }
        if (days > 0) {
            differenceString += `${days} day${days > 1 ? 's' : ''}`;
        }
        if (months > 0) {
            differenceString += `${differenceString ? ', ' : ''}${months} month${months > 1 ? 's' : ''}`;
        }
        if (years > 0) {
            differenceString += `${differenceString ? ', ' : ''}${years} year${years > 1 ? 's' : ''}`;
        }

        return (
            <div>
                <p className="text-white text-center w-full">{differenceString}</p>
            </div>
        );
    }

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="bg-bgcolor p-2 rounded-md items-center justify-center shadow-md shadow-gray-900 mb-2 w-full animate-cascade">


            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-lg">
                        <p className="text-white">Are you sure you want to delete this Employee?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">

                <div className="mr-2">
                    <button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                    </button>
                </div>

                <div className="bg-darkgreen h-full rounded-md flex flex-col items-center justify-center p-2 w-full shadow-md shadow-gray-900 mr-5">
                    <div className="text-xl text-white text-center line-clamp-1">{employee.name}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{employee.position}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{employee.type}</div>
                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mr-5">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Start Date:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Department:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Number:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className="rounded-md border-2 p-1.5 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{employee.startDate}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{employee.department}</div>
                            <div className="text-xs text-white text-center line-clamp-1">{employee.numId}</div>

                        </div>

                    </div>

                </div>


                <div className="h-full flex flex-col w-full">

                    <div className="text-sm text-red-700 text-center font-bold line-clamp-1">Emergency Contact:</div>

                    <div className="bg-bgcolor h-full rounded-md flex pr-3 pl-3 pt-1 pb-1 w-full shadow-md shadow-gray-900">

                        <div className="flex flex-col items-start p-1 justify-center w-1/3">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">Name:</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">Phone:</div>
                            <div className="text-xs text-white text-center line-clamp-1">Relation:</div>
                        </div>

                        <div className="flex flex-col items-end p-1 justify-center w-2/3">

                            <div className="rounded-md border-4 p-1 border-solid border-red-700">
                                <div className="text-xs text-white text-center mb-1 line-clamp-1">{employee.eName}</div>
                                <div className="text-xs text-white text-center mb-1 line-clamp-1">{employee.ePhone}</div>
                                <div className="text-xs text-white text-center line-clamp-1">{employee.eRelation}</div>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="h-full rounded-md flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-md text-darkgreen text-center line-clamp-1">Pay Rate:</div>
                        <div className="text-md text-white text-center mb-2 line-clamp-1">${numberWithCommas(employee.payRate) + " " + employee.payType}</div>

                        <div className="rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">
                            <div className="text-md text-darkgreen text-center line-clamp-1">Employee Tenure:</div>
                            <DateDifference dateString={employee.startDate} />
                        </div>

                    </div>


                </div>

                <div className="flex flex-col items-center">


                    <button onClick={() => onEdit(employee)} className="text-md text-white px-2 mb-4 hover:text-litegreen">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>

                    <button className="text-xxl text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>

                    <div className="text-md text-white font-bold text-center mt-5">APPLICATION RATE</div>

                </>
            )}

        </div>
    );
};

export default Team;
