import React from "react";
import Footer from "../LandingFooter";
import Navbar from "../LandingNavBar";
import dash from "../../sprayDash.png";
import dash2 from "../../sprayDash2.png";
import dash3 from "../../sprayDash3.png";
import dash4 from "../../sprayDash4.png";

import { Typewriter } from "react-simple-typewriter";


const LandingSprays = () => {
    return (
        <>
            <Navbar transparent/>

            <main className="bg-bgcolor w-full pt-16">

                <section id='hero'>

                    <div
                        className="top-0 mx-auto mt-[-96px] flex h-screen w-full max-w-screen-xl flex-col items-center justify-center text-center text-white">
                        <p className="p-2 font-bold uppercase text-litegreen">
                            Optimized Chemical Application
                        </p>
                        <h1 className="text-5xl font-bold sm:text-6xl md:text-7xl">
                            Calculate Sprays with Precision.
                        </h1>
                        <div className="mt-4 flex text-xl font-bold sm:text-3xl md:text-4xl">
                            <p className="mr-2 md:mr-3">Accurately calculate</p>
                            <Typewriter
                                words={["Chemical Application Rates", "Spray Volume Requirements", "Product Mixtures", "Coverage Areas", "And More"]}
                                loop={0}
                                cursor={1}
                                typeSpeed={120}
                            />
                        </div>

                        <p className=" mt-4 w-[90vw] text-xl font-bold text-gray-400 md:text-2xl">
                            Seamlessly connect your chemical database to our spray calculator for precise and efficient
                            application.
                        </p>
                    </div>

                </section>

                <section id='features' className="pb-16">

                    <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
                        <div className='flex flex-col space-y-12 md:w-1/2'>
                            <h2 className='max-w-md text-4xl font-bold text-center text-white md:text-left'>
                                What Makes Our Spray Calculator Unique?
                            </h2>
                            <p className='max-w-sm text-center text-white md:text-left'>
                                Our spray calculator offers a user-friendly interface and seamless integration with your
                                chemical
                                database, providing accurate calculations for optimized chemical application on your
                                golf course.
                            </p>
                        </div>

                        <div className='flex flex-col space-y-8 md:w-1/2'>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            01
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Enhanced Chemical Application Accuracy
                                    </h3>
                                    <p className='text-white'>
                                        Achieve precise chemical application rates tailored to your golf course's needs,
                                        leading to
                                        improved efficacy and minimized waste.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            02
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Integration with Chemical Database
                                    </h3>
                                    <p className='text-white'>
                                        Seamlessly connect your chemical database to our spray calculator for quick
                                        access to
                                        accurate chemical information and application rates.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            03
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Real-time Application Recommendations
                                    </h3>
                                    <p className='text-white'>
                                        Receive real-time recommendations based on weather conditions, turf type, and
                                        chemical
                                        properties to optimize application timing and effectiveness.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash}
                                 alt="/"/>
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Enhanced Spray Calculation
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Streamline Chemical Application Efficiency
                                </h1>
                                <p className="mt-2 text-white">
                                    Experience seamless management of chemical application with our powerful spray
                                    calculator.
                                    Utilize real-time data from your chemical database to optimize application rates and
                                    ensure
                                    precise coverage.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Precise Chemical Quantities
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Optimize Spray Efficiency
                                </h1>
                                <p className="mt-2 text-white">
                                    Our software revolutionizes chemical application by calculating precise quantities
                                    of each chemical
                                    and nutrient per spray. Utilizing sophisticated algorithms and real-time data from
                                    your chemical
                                    database, it ensures optimal application rates, minimizing waste and maximizing
                                    effectiveness.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash2}
                                 alt="/"/>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash3}
                                 alt="/"/>
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Innovative Spray Card Design
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Simplify Spray Management
                                </h1>
                                <p className="mt-2 text-white">
                                    Experience the efficiency of our innovative spray card design, providing all
                                    essential information at a moment's
                                    notice. Streamline your spray management process, access critical data instantly,
                                    and optimize spray operations
                                    with ease.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Precise Ingredient Cards
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Enhance Chemical Precision
                                </h1>
                                <p className="mt-2 text-white">
                                    Discover the power of our ingredient cards, breaking down the exact quantities of
                                    each chemical for total spray
                                    and per sprayer tank. Simplify your chemical management process, ensure precise
                                    application, and optimize chemical
                                    usage with our detailed ingredient breakdown feature.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash4}
                                 alt="/"/>
                        </div>
                    </div>
                </section>

                <Footer/>

            </main>


        </>
    );
}

export default LandingSprays;