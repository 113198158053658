import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import { db } from '../../firebase/firebase';
import {
    faTrash,
    faEdit,
    faDownload,
    faFileExcel,
    faCaretDown,
    faCaretUp,
    faPlus,
    faMagnifyingGlass,
    faSquareCaretLeft, faSquareCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar, Pie} from "react-chartjs-2";
import {XCircleIcon} from "@heroicons/react/24/outline";


export const chemOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Occurrences',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const chemOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Spray Rate',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Chems = () => {
    const { currentUser } = useAuth();
    const [chems, setChems] = useState([]);
    const [chemSide, setChemSide] = useState(false);
    const [filteredChems, setFilteredChems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClassification, setSelectedClassification] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        manufacturer: '',
        classification: '',
        type: '',
        quantity: 0,
        volume: 0,
        unit: '',
        excess: 0,
        weight: 0,
        unitweight: '',
        nutrients: [],
        rate: 0,
        unitprice: 0,
    });
    const [editingChemId, setEditingChemId] = useState(null);

    const onUpdate = async () => {
        try {
            const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
            const snapshot = await chemsCollectionRef.get();
            const chemData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChems(chemData);
            setFilteredChems(chemData);
            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemData));
        } catch (error) {
            console.error('Error updating chem data:', error);
        }
    };

    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                        setFilteredChems(chemsData);
                    } else {
                        const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                        const snapshot = await chemsCollectionRef.get();

                        if (!snapshot.empty) {
                            const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setChems(chemsData);
                            setFilteredChems(chemsData);
                            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemsData));
                        } else {
                            console.log('No chems found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setChemSide(userData.chemSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setChemSide(userData.chemSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchChems();
    }, [currentUser]);





    const deleteChemFromFirestore = async (chemId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemId).delete();
            console.log('Chem item deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting chem item:', error);
        }
    };

    const fillFormWithChemData = (chem) => {
        setFormData({
            name: chem.name,
            manufacturer: chem.manufacturer,
            classification: chem.classification,
            type: chem.type,
            quantity: chem.quantity,
            volume: chem.volume,
            unit: chem.unit,
            excess: chem.excess,
            weight: chem.weight,
            unitweight: chem.unitweight,
            nutrients: chem.nutrients,
            rate: chem.rate,
            unitprice: chem.unitprice,
        });
        setEditingChemId(chem.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            manufacturer: '',
            classification: '',
            type: '',
            quantity: 0,
            volume: 0,
            unit: '',
            excess: 0,
            weight: 0,
            unitweight: '',
            nutrients: [],
            rate: 0,
            unitprice: 0,
        });
        setEditingChemId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');

        const parsedFormData = {
            ...formData,
            quantity: parseFloat(formData.quantity),
            volume: parseFloat(formData.volume),
            excess: parseFloat(formData.excess),
            weight: parseFloat(formData.weight),
            rate: parseFloat(formData.rate),
            unitprice: parseFloat(formData.unitprice),
        };

        try {
            if (editingChemId) {
                await collectionRef.doc(editingChemId).update(parsedFormData);
                console.log('Chem updated successfully!');
                await onUpdate();
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Chem added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedChemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChems(updatedChemsData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating chem: ', error);
        }
    };


    const calculateTotalVolume = (chem) => {
        return ((chem.quantity * chem.volume) + chem.excess);
    };

    const calculateTotalContainer = (chem) => {
        return (chem.quantity + (chem.excess / chem.volume));
    };

    const calculateTotalExcess = (chem) => {
        return (chem.excess);
    };

    const calculateTotalPrice = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice);
    };

    const calculateTotalWeight = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.weight);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleNutrientChange = (index, fieldName, e) => {
        const { value } = e.target;
        // Parse the percentage value to a number
        const parsedValue = fieldName === 'percentage' ? parseFloat(value) : value;
        setFormData(prevState => ({
            ...prevState,
            nutrients: prevState.nutrients.map((nutrient, i) =>
                i === index ? { ...nutrient, [fieldName]: parsedValue } : nutrient
            )
        }));
    };



    const handleAddNutrient = () => {
        setFormData({ ...formData, nutrients: [...formData.nutrients, { name: '', percentage: '' }] });
    };

    const handleRemoveNutrient = (index) => {
        const updatedNutrients = [...formData.nutrients];
        updatedNutrients.splice(index, 1);
        setFormData({ ...formData, nutrients: updatedNutrients });
    };




    // Function to filter chems based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = chems.filter(chem =>
            chem.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredChems(filtered);
    };

    const filterChemsByClassification = (classification) => {
        setSelectedClassification(classification);
        const filtered = chems.filter(chem => classification === '' || chem.classification === classification);
        setFilteredChems(filtered);
    };



    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Manufacturer', 'Classification', 'Type', 'Quantity', 'Volume', 'Unit', 'Excess', 'Weight', 'Unit Weight', 'Rate', 'Unit Price', 'Total Volume', 'Total Price'];
        const worksheetData = [columnHeaders, ...chems.map(chem => [chem.name, chem.manufacturer, chem.classification, chem.type, chem.quantity, chem.volume, chem.unit, chem.excess, chem.weight, chem.unitweight, chem.rate, chem.unitprice, ((chem.quantity * chem.volume) + chem.excess).toFixed(2), (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice).toFixed(2)])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Chemicals');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'ChemicalInventory.xlsx');
    };

    const addOrUpdateButtonText = editingChemId ? 'Update Chem' : 'Add Chem';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(chemSide); // Set isCollapsed to the value of chemSide from Firebase
    }, [chemSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };



    const calculateNutrients = () => {
        const nutrientsMap = {};

        filteredChems.forEach(chem => {
            chem.nutrients.forEach(nutrient => {
                const { name } = nutrient;

                if (!nutrientsMap[name]) {
                    nutrientsMap[name] = 1; // Initialize count
                } else {
                    nutrientsMap[name] += 1; // Increment count
                }
            });
        });

        return Object.entries(nutrientsMap).map(([name, count]) => ({
            name,
            totalValue: count
        }));
    };

    const calcRates = () => {
        return filteredChems.map(chem => ({
            name: chem.name,
            rate: chem.rate
        }));
    };




    const uniqueNutrients = calculateNutrients();
    const rates = calcRates();


    const nutrientBarChartData = {
        labels: uniqueNutrients.map(nutrient => nutrient.name.slice(0, 1)),
        datasets: [
            {
                label: 'Occurrences',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: uniqueNutrients.map(nutrient => nutrient.totalValue.toFixed(2)),
            }
        ]
    };

    const nutrientBarChartData2 = {
        labels: rates.map(chem => chem.name.slice(0,4)),
        datasets: [
            {
                label: 'Rate',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: rates.map(chem => chem.rate.toFixed(2)),
            }
        ]
    };

    return (
        <div className="bg-bgpage flex h-full w-full mt-16">


            <Dashboard/>


            <div className="w-full flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    <div className="flex flex-col items-center justify-center">

                        <div className=" flex items-center justify-center w-full flex-row">

                            <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-mg shadow-gray-900 w-full mr-2">

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm text-white mr-2">Total Items:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{filteredChems.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm text-white mr-2">Total Volume:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">  {numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalVolume(chem)), 0).toFixed(2)) + " gal"}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm text-white mr-2">Total Price:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalPrice(chem)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <button className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2"
                                        onClick={toggleExpansion}>
                                    {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                                </button>

                            </div>

                            <button onClick={downloadAsExcel} className="bg-darkgreen hover:bg-litegreen text-white flex font-bold p-2 rounded-md focus:outline-none focus:shadow-outline">
                                <FontAwesomeIcon icon={faDownload} className="text-white mr-2"/>
                                <FontAwesomeIcon icon={faFileExcel} className="text-white"/>
                            </button>

                            <button className="ml-2 text-3xl text-white" onClick={toggleCollapse}>
                                {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> : <FontAwesomeIcon icon={faSquareCaretRight}/>}
                            </button>

                        </div>

                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div className="mt-2 mr-2 ml-2 p-2 bg-bgcolor flex items-center justify-center rounded-md shadow-mg shadow-gray-900 w-full">


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Spray Rate Comparison:</div>
                                            <Bar options={chemOptions2} data={nutrientBarChartData2} className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div className="w-full mr-2 rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Total Containers:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalContainer(chem)), 0).toFixed(2))}</div>
                                                </div>
                                            </div>


                                            <div className="w-full rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Total Excess:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalExcess(chem)), 0).toFixed(2))} gal</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div className="mr-auto ml-auto rounded-md bg-bglite pl-6 pr-6 pb-1 pt-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Total Weight:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalWeight(chem)), 0).toFixed(2))} lbs</div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Nutrient Occurrences:</div>
                                            <Bar options={chemOptions} data={nutrientBarChartData} className="ml-auto mr-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}

                    </div>


                    <div className="mt-2 w-full flex flex-row items-center">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white mr-2"/>
                        <input
                            type="text"
                            placeholder="Search chems by name"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className="bg-bglite appearance-none rounded-md w-full mr-2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                        />

                        <div className="w-1/4 flex flex-row items-center">
                            <select
                                value={selectedClassification}
                                onChange={(e) => filterChemsByClassification(e.target.value)}
                                className="bg-bglite appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">All Classifications</option>

                                <option value="Fungicide">Fungicide</option>
                                <option value="Herbicide">Herbicide</option>
                                <option value="Pesticide">Pesticide</option>
                                <option value="Growth Reg">Growth Reg</option>
                                <option value="Fertilizer">Fertilizer</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Micronutrient">Micronutrient</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Soil Amends">Soil Amends</option>
                                <option value="2nd Nutrient">2nd Nutrient</option>
                                {/* Add more options as needed */}
                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>

                        </div>

                    </div>

                    <div className={` ${expanded ? 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-23rem)] scrollbar-none' : 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-11rem)] scrollbar-none'}`}>

                        {filteredChems.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a Chem</p>

                                    <p className="text-xs text-white mt-2">1] Fill out the panel on the right hand side of the screen</p>
                                    <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the Add Chem button</p>
                                    <p className="text-xs text-white mt-1">3] Press the Add Spray button to add it to your database</p>
                                    <p className="text-xs text-white mt-1">4] If your spray does not automatically show up on the screen refresh your page and it should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Missing Classification</p>

                                    <p className="text-xs text-white mt-2">1] You do not have any chemicals that match that Classification</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the
                                        Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredChems.map((chem, index) => {
                                return (
                                    <ChemCard
                                        key={index}
                                        chem={chem}
                                        onDelete={() => deleteChemFromFirestore(chem.id)}
                                        onEdit={fillFormWithChemData}
                                    />
                                );
                            })
                        )}


                    </div>


                </div>


                <div className={` ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col rounded-md shadow-md shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/4'}`}>

                    <div className="text-white justify-center items-center text-center text-lg animate-fadeIn">Add Chemical</div>

                    <button type="button" onClick={clearForm} className="absolute font-bold py-1 px-1 rounded-md mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                        <XCircleIcon className="w-6 hover:text-red-700 flex-none text-white" aria-hidden="true"/>
                    </button>

                    <form onSubmit={handleSubmit} className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                        <div className="text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-3" htmlFor="name">
                                Name:
                            </label>

                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-3" htmlFor="manufacturer">
                                Manufacturer:
                            </label>

                            <input
                                type="text"
                                id="manufacturer"
                                name="manufacturer"
                                value={formData.manufacturer}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">CLASSIFIERS
                        </div>


                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-3" htmlFor="classification">
                                Classification:
                            </label>

                            <select
                                id="classification"
                                name="classification"
                                value={formData.classification}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md w-1/2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Fungicide">Fungicide</option>
                                <option value="Herbicide">Herbicide</option>
                                <option value="Pesticide">Pesticide</option>
                                <option value="Growth Reg">Growth Reg</option>
                                <option value="Fertilizer">Fertilizer</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Micronutrient">Micronutrient</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Soil Amends">Soil Amends</option>
                                <option value="2nd Nutrient">2nd Nutrient</option>

                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-3" htmlFor="type">
                                Type:
                            </label>

                            <select
                                id="type"
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Liquid">Liquid</option>
                                <option value="Granular">Granular</option>
                                <option value="Foliar">Foliar</option>
                                <option value="Soluable">Soluable</option>

                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">MEASUREMENTS
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-3" htmlFor="quantity">
                                Quantity:
                            </label>

                            <input
                                type="number"
                                id="quantity"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex mb-2">
                            <div className="w-full mr-5">
                                <label className="block text-white text-sm mb-2" htmlFor="volume">
                                    Volume:
                                </label>

                                <input
                                    type="number"
                                    id="volume"
                                    name="volume"
                                    value={formData.volume}
                                    onChange={handleChange}
                                    className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <div className="w-full">
                                <label className="block text-white text-sm mb-2" htmlFor="unit">
                                    Unit:
                                </label>

                                <div className="flex flex-row mb-2">
                                    <select
                                        id="unit"
                                        name="unit"
                                        value={formData.unit}
                                        onChange={handleChange}
                                        className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                    >
                                        <option value="">Select...</option>

                                        <option value="gal">gal</option>
                                        <option value="oz">oz</option>
                                        <option value="lbs">lbs</option>
                                        <option value="ml">ml</option>
                                        <option value="L">L</option>

                                    </select>

                                    <div className="text-white ml-2 text-xl">
                                        <FontAwesomeIcon icon={faCaretDown}/>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-3" htmlFor="excess">
                                Excess:
                            </label>

                            <input
                                type="number"
                                id="excess"
                                name="excess"
                                value={formData.excess}
                                onChange={handleChange}
                                className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex">
                            <div className="w-full mr-5">
                                <label className="block text-white text-sm mb-2" htmlFor="weight">
                                    Weight per gal:
                                </label>

                                <input
                                    type="number"
                                    id="weight"
                                    name="weight"
                                    value={formData.weight}
                                    onChange={handleChange}
                                    className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <div className="w-full">
                                <label className="block text-white text-sm mb-2" htmlFor="unitweight">
                                    Unit:
                                </label>

                                <select
                                    id="unitweight"
                                    name="unitweight"
                                    value={formData.unitweight}
                                    onChange={handleChange}
                                    className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="lbs">lbs</option>
                                    <option value="oz">oz</option>
                                    <option value="kg">kg</option>
                                    <option value="g">g</option>

                                </select>
                            </div>
                        </div>


                        <div className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to calculate your volume
                        </div>


                        <div className="flex flex-row items-center justify-between">
                            <div className="w-1/3"></div>
                            <div className="w-1/3 text-white text-center text-xs">NUTRIENTS</div>
                            <div className="w-1/3 flex justify-end">
                                <button type="button" onClick={handleAddNutrient}
                                        className="rounded-md text-center bg-darkgreen text-white h-8 w-8">
                                    <FontAwesomeIcon icon={faPlus} className="text-white"/>
                                </button>
                            </div>
                        </div>

                        {formData.nutrients.map((nutrient, index) => (
                            <div key={index} className="flex items-center mt-2 mb-2 justify-start">

                                <select
                                    id="unitweight"
                                    name="unitweight"
                                    value={nutrient.name}
                                    onChange={(e) => handleNutrientChange(index, 'name', e)} // Pass the field name explicitly
                                    className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900 mr-3"
                                >
                                    <option value="">Select...</option>

                                    <option value="Potassium PolyPhosphite">Potassium PolyPhosphite</option>
                                    <option value="Nitrogen[n]">Nitrogen[n]</option>
                                    <option value="Phosphate[p2o5]">Phosphate[p2o5]</option>
                                    <option value="Potassium[k]">Potassium[k]</option>
                                    <option value="Calcium[ca]">Calcium[ca]</option>
                                    <option value="Magnesium[Mg]">Magnesium[Mg]</option>
                                    <option value="Sulfur[s]">Sulfur[s]</option>
                                    <option value="Boron[B]">Boron[B]</option>
                                    <option value="Cobalt[Co]">Cobalt[Co]</option>
                                    <option value="Copper[Cu]">Copper[Cu]</option>
                                    <option value="Iron[Fe]">Iron[Fe]</option>
                                    <option value="Manganese[Mn]">Manganese[Mn]</option>
                                    <option value="Molybdate[Mo]">Molybdate[Mo]</option>
                                    <option value="Zinc[Zn]">Zinc[Zn]</option>
                                    <option value="Humic Acid">Humic Acid</option>
                                    <option value="Carbohydrates">Carbohydrates</option>
                                    <option value="Amino Acid Substrates">Amino Acid Substrates</option>
                                    <option value="Soluble Potash">Soluble Potash</option>


                                </select>
                                <input
                                    type="number"
                                    value={nutrient.percentage}
                                    onChange={(e) => handleNutrientChange(index, 'percentage', e)} // Pass the field name explicitly
                                    placeholder="%"
                                    className="bg-bgcard w-14 appearance-none text-center rounded-md py-1 text-white leading-tight shadow-md shadow-gray-900 mr-3"
                                />
                                <button type="button" onClick={() => handleRemoveNutrient(index)}>
                                    <FontAwesomeIcon icon={faTrash} className="text-red-700"/>
                                </button>
                            </div>
                        ))}

                        <div className="text-white mt-4 justify-center items-center text-center text-xs">APPLICATION
                            RATES
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-3" htmlFor="rate">
                                Rate:
                            </label>

                            <input
                                type="number"
                                id="rate"
                                name="rate"
                                value={formData.rate}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to Calculate your spray formulas
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">PRICE</div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-3" htmlFor="unitprice">
                                UnitPrice:
                            </label>

                            <input
                                type="number"
                                id="unitprice"
                                name="unitprice"
                                value={formData.unitprice}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">
                            This is the data that the system uses to calculate your cost analysis
                        </div>


                        {/* Add more input fields as needed */}
                        <div className="flex w-full justify-center mt-4">
                            {/* Add or Update Chem button */}
                            <button type="submit" className="w-full bg-darkgreen hover:bg-litegreen text-white font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                {addOrUpdateButtonText}
                            </button>
                        </div>


                    </form>

                </div>


            </div>


        </div>
    );
};


const ChemCard = ({chem, onDelete, onEdit}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion
    const volume = ((chem.quantity * chem.volume) + chem.excess).toFixed(2);
    const price = (volume * chem.unitprice).toFixed(2);
    const adjustedExcess = (chem.excess * 1).toFixed(2);
    const adjustedVolume = (chem.volume * 1).toFixed(2);
    const adjustedPrice = (chem.unitprice * 1).toFixed(2);

    const rate = chem.rate;
    const nutrients = chem.nutrients;

    const Rate = (rate * 1).toFixed(2);
    const galRate = (((rate / 128) / 1000) * 43560).toFixed(2);
    const mlRate = (((rate * 29.5735) / 1000) * 1076.39).toFixed(2);
    const lRate = (((rate / 33.814) / 1000) * 107639).toFixed(2);

    const Weight = (chem.weight * 1).toFixed(2);
    const containerWeight = (chem.weight * chem.volume).toFixed(2);
    const totalWeight = (volume * chem.weight).toFixed(2);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(chem.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const nutrientChart = {
        labels: nutrients.map(nutrient => nutrient.name),
        datasets: [
            {
                label: '%',
                data: nutrients.map(nutrient => nutrient.percentage),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderWidth: 1,
            },
        ],
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }




    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const totalContainerValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * chem.volume * chem.weight);
    }, 0).toFixed(2);

    const totalNutrientValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * volume * chem.weight);
    }, 0).toFixed(2);

    return (
        <div className="bg-bgcolor p-2 rounded-md items-center justify-center shadow-md shadow-gray-900 mb-2 w-full animate-cascade">



            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-md">
                        <p className="text-white">Are you sure you want to delete this Chemical?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">

                <div className="mr-2">
                    <button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                    </button>
                </div>

                <div className="bg-darkgreen h-full rounded-md flex flex-col items-center justify-center p-2 w-full shadow-md shadow-gray-900 mr-5">
                    <div className="text-xl text-white text-center line-clamp-1">{chem.name}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{chem.manufacturer}</div>
                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mr-5">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Containers:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Volume Per:</div>
                        <div className="text-xs text-white text-center line-clamp-1">Excess:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className="rounded-md border-2 p-1.5 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{chem.quantity}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{adjustedVolume + " " + chem.unit}</div>
                            <div className="text-xs text-white text-center line-clamp-1">{adjustedExcess + " " + chem.unit}</div>
                        </div>

                    </div>

                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Classification:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Consistency:</div>
                        <div className="text-xs text-white text-center line-clamp-1">Unit Price:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center  w-full">

                        <div className="rounded-md border-2 p-1.5 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{chem.classification}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{chem.type}</div>
                            <div className="text-xs text-white text-center line-clamp-1">${adjustedPrice}</div>
                        </div>

                    </div>

                </div>


                <div className="h-full rounded-md flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Total Volume:</div>
                        <div className="text-md text-white text-center mb-2 line-clamp-1">{numberWithCommas(volume) + " " + chem.unit}</div>

                        <div className="rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900">
                            <div className="text-lg text-darkgreen text-center line-clamp-1">Total Price:</div>
                            <div className="text-md text-white text-center line-clamp-1">{"$" + numberWithCommas(price)}</div>
                        </div>

                    </div>


                </div>

                <div className="flex flex-col items-center">


                    <button onClick={() => onEdit(chem)} className="text-md text-white px-2 mb-4 hover:text-litegreen">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>

                    <button className="text-xxl text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>

                <div className="text-md text-white font-bold text-center mt-5">APPLICATION RATE</div>


                    <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mt-5">

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-2 w-full mr-5">
                            <div className="text-xs text-white font-bold text-center mb-1 line-clamp-1">Fluid oz / 1000 sq ft.:</div>
                            <div className="text-xs text-white text-center rounded-md border-2 p-1.5 border-solid border-darkgreen line-clamp-1">{Rate + " oz"}</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-2 w-full mr-5">
                            <div className="text-xs text-white font-bold text-center mb-1 line-clamp-1">Gal / 1 acre:</div>
                            <div className="text-xs text-white text-center rounded-md border-2 p-1.5 border-solid border-darkgreen line-clamp-1">{galRate + " gal"}</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-2 w-full mr-5">
                            <div className="text-xs text-white font-bold text-center mb-1 line-clamp-1">ML / 100 sq. m:</div>
                            <div className="text-xs text-white text-center rounded-md border-2 p-1.5 border-solid border-darkgreen line-clamp-1">{mlRate + " ml"}</div>
                        </div>

                        <div className="bg-bgcolor h-full rounded-md flex flex-col items-center justify-center shadow-md shadow-gray-900 p-2 w-full">
                            <div className="text-xs text-white font-bold text-center mb-1 line-clamp-1">L / HA:</div>
                            <div className="text-xs text-white text-center rounded-md border-2 p-1.5 border-solid border-darkgreen line-clamp-1">{lRate + " L"}</div>
                        </div>

                    </div>


                    <div className="text-md text-white font-bold text-center mt-5">NUTRIENT DATA</div>


                    <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 mt-5 mr-24 ml-24">

                        <div className="mr-10">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Weight per gal:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{Weight + " " + chem.unitweight}</div>
                            </div>
                        </div>

                        <div className="mr-10">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Container Weight:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{containerWeight + " " + chem.unitweight}</div>
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Total Weight:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{numberWithCommas(totalWeight) + " " + chem.unitweight}</div>
                            </div>
                        </div>

                    </div>


                    <div className="bg-bgcolor h-full rounded-md  p-3 w-full shadow-md shadow-gray-900 mt-5">


                        <div className="w-full flex flex-row">

                            <div className="w-2/3">

                                <div className="items-center justify-center overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                                    {chem.nutrients.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center">
                                            <div className="bg-bgcolor p-4 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-5">
                                                <p className="text-xl text-darkgreen">This Chem Has No Nutrients</p>
                                                <p className="text-lg text-litegreen mt-1">Adding a Nutrient</p>

                                                <p className="text-xs text-white mt-2">1] Press the Edit Button to the right of the Chem Card</p>
                                                <p className="text-xs text-white mt-1">2] This will fill the panel to the right with your chem's data</p>
                                                <p className="text-xs text-white mt-1">3] Navigate to the Nutrients Section and add in your nutrients and then press Update Chem</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        chem.nutrients.map((nutrient, index) => (
                                            <NutrientCard key={index} nutrient={nutrient} Vol={volume}
                                                          Weight={chem.weight}
                                                          galVol={chem.volume}/>
                                        ))
                                    )}

                                </div>

                            </div>

                            <div className="w-1/3 max-w-screen-md h-full">

                                <div className="text-sm font-bold text-white mr-2 text-center line-clamp-1">NUTRIENT MAKEUP:</div>

                                <div className="flex justify-center items-center">
                                    <Pie data={nutrientChart} className="mr-auto ml-auto"/>
                                </div>

                            </div>

                        </div>


                        <div
                            className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 mt-2.5 mb-2.5 w-full">

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Nutrients:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{chem.nutrients.length}</div>
                                </div>
                            </div>

                            <div className="mr-10">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Container Nutrients:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{totalContainerValue + " lbs"}</div>
                                </div>
                            </div>

                            <div className="">
                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-white mr-2 line-clamp-1">Total Nutrients:</div>
                                    <div
                                        className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{numberWithCommas(totalNutrientValue) + " lbs"}</div>
                                </div>
                            </div>

                        </div>

                    </div>

                </>
            )}

        </div>
    );
};


const NutrientCard = ({nutrient, Vol, Weight, galVol}) => {
    const percent = nutrient.percentage / 100;

    // Function to handle dividing values less than 0.1 by 16 and converting to ounces if necessary
    const handleDivision = (value) => {
        if (value < 0.1) {
            const ozValue = (value * 16).toFixed(2);
            return ozValue + " oz";
        } else {
            return value.toFixed(2) + " lbs";
        }
    };

    return (
        <div className="bg-bgcolor flex items-center justify-center rounded-md p-1 m-2 shadow-md shadow-gray-900 mt-2 ">
            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 rounded-md border-2 p-1.5 border-solid border-darkgreen mr-2 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Name:</div>
                <div className="text-sm text-white line-clamp-1">{nutrient.name}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-2 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Percentage:</div>
                <div className="text-sm text-white line-clamp-1">{(nutrient.percentage * 1) + "%"}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-2 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">gal Weight:</div>
                <div className="text-sm text-white line-clamp-1">{handleDivision(percent * Weight)}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-2 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Cont Weight:</div>
                <div className="text-sm text-white line-clamp-1">{handleDivision(percent * Weight * galVol)}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-2 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Total Weight:</div>
                <div className="text-sm text-white line-clamp-1">{handleDivision(percent * Weight * Vol)}</div>
            </div>
        </div>
    );
};



export default Chems;
