import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';
import imageSrc from '../../FullLogo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import {BellAlertIcon} from "@heroicons/react/24/outline"; // Corrected import path

const Header = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="w-full z-20 p-3 h-16 fixed top-0 left-0 bg-bgpage">

            <div className="bg-bgDark  flex flex-row justify-between items-center w-full rounded-md" >

                <Link className='w-full text-sm text-white underline mx-5 p-1' to={'/landing'}>
                    <img src={imageSrc} alt="" className="h-12"/> {/* Adjust height as needed */}
                </Link>

                <div className="flex w-full justify-center items-center">
                    <div className="mr-5 text-sm text-white bg-darkgreen rounded-md p-1 shadow-md shadow-gray-900">{time}</div>
                    <div className="mr-5 text-sm text-white bg-darkgreen rounded-md p-1 shadow-md shadow-gray-900">{date}</div>
                </div>

                <div className="w-full flex justify-end items-center">

                    <div className="">
                        <div className="flex mr-5 bg-bglite rounded-md h-8 p-1" onClick={toggleExpansion}>
                            <div className="flex flex-row p-2 items-center">
                                <div className="w-5 h-5 mr-2 rounded bg-bgcard p-3 flex items-center justify-center shadow-md shadow-gray-900">
                                    <BellAlertIcon className="w-4 flex-none text-white animate-bounce" aria-hidden="true"/>
                                </div>

                                <div className="text-white text-xs mr-2">Notifications</div>

                                <button className="text-2xl text-white font-bold hover:text-litegreen ml-auto"
                                    onClick={toggleExpansion}>
                                    {expanded ? <FontAwesomeIcon icon={faCaretUp}/> :
                                        <FontAwesomeIcon icon={faCaretDown}/>}
                                </button>
                            </div>
                        </div>

                        {expanded && (
                            <>
                                <div className="flex absolute justify-center items-start flex-col bg-bglite p-2 mt-2 rounded-md">

                                    <li className={`cursor-pointer text-white text-xs mb-3 flex items-start`}>
                                        Chems Dashboard
                                    </li>

                                    <li className={`cursor-pointer text-white text-xs mb-3 flex items-start`}>
                                        Sprays Dashboard
                                    </li>

                                    <li className={`cursor-pointer text-white text-xs mb-3 flex items-start`}>
                                        Fleet Dashboard
                                    </li>

                                    <li className={`cursor-pointer text-white text-xs flex items-start`}>
                                        Team Dashboard
                                    </li>


                                </div>
                            </>
                        )}
                    </div>

                    <nav>
                        {

                            userLoggedIn
                                ?


                                <button onClick={() => {
                                    doSignOut().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                        className='text-sm text-white w-40 h-8 rounded-md hover:bg-red-500 bg-red-700 p-1 flex items-center justify-center shadow-md shadow-gray-900 mr-2'>Logout</button>
                                :
                                <>
                                    <Link className='text-sm text-white underline mx-2' to={'/login'}>Login</Link>
                                    <Link className='text-sm text-white underline mx-2' to={'/register'}>Register New
                                        Account</Link>
                                </>
                        }
                    </nav>

                </div>

            </div>
        </div>
    );
};

export default Header;
