import React from "react";
import Footer from "../LandingFooter";
import Navbar from "../LandingNavBar";
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import dash from "../../chemDash.png";
import dash2 from "../../chemDash.png";
import calendar from "../../calendar.png"
import chart from "../../chart.png";
// import tim from "../../tim.jpg";
// import spencer from "../../spencer.jpg";
// import ken from "../../ken.jpg";
// import rohith from "../../rohith.jpg";
import {
    CloudArrowUpIcon,
    LockClosedIcon,
    ServerIcon,
    ChartBarIcon,
    CalendarDaysIcon,
    UserGroupIcon, TruckIcon, MapIcon, TableCellsIcon
} from '@heroicons/react/20/solid'

const features = [
    {
        name: 'Effortless Cloud Storage.',
        description:
            'Access and manage your golf course data seamlessly from anywhere, ensuring smooth operations.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Data Security.',
        description: 'Protect your golf course data with advanced encryption and privacy features for confidentiality.',
        icon: LockClosedIcon,
    },
    {
        name: 'Automated Backups.',
        description: 'Ensure data safety with automated backups, providing peace of mind and disaster recovery readiness.',
        icon: ServerIcon,
    },
]



const Landing = () => {
    return (
        <>
            <Navbar transparent />

            <main className="bg-bgcolor">
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                     style={{
                         minHeight: "75vh"
                     }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                         style={{
                             backgroundImage: `url(${require('../../courseLarge.webp')})`
                             // backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
                         }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                                <div className="pr-12">
                                    <h1 className="text-white font-semibold text-5xl">
                                        Where Greens Thrive
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        Elevate golf course management: precision sprays, chemical tracking, and fleet
                                        management integrated seamlessly. Optimize operations for superior turf care.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <section className="pb-20 bg-bgcolor -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">

                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-gray-900 w-full mb-8 shadow-lg shadow-litegreen rounded-xl">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-darkgreen">
                                            <MapIcon className="mt-1 h-5 w-5 flex-none text-white text-xl" aria-hidden="true"/>
                                        </div>
                                        <h6 className="text-xl font-semibold text-litegreen">Spray Calculator</h6>
                                        <p className="mt-2 mb-4 text-gray-300">
                                            Optimize your golf course care with precision. Calculate sprays
                                            effortlessly, ensuring immaculate greens. Elevate your maintenance game
                                            today!
                                        </p>

                                        <Link
                                            className='signup text-white text-lg font-medium bg-darkgreen px-6 py-2 rounded-full hover:text-white hover:bg-litegreen transition duration-200'
                                            to={'/LandingSprays'}>Learn More</Link>

                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-gray-900 w-full mb-8 shadow-lg shadow-litegreen rounded-xl">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-darkgreen">
                                            <TableCellsIcon className="mt-1 h-5 w-5 flex-none text-white text-xl" aria-hidden="true"/>
                                        </div>
                                        <h6 className="text-xl font-semibold text-litegreen">
                                            Chemical Tracker
                                        </h6>
                                        <p className="mt-2 mb-4 text-gray-300">
                                            Stay ahead with our advanced chemical tracking software. Monitor inventories
                                            seamlessly, ensuring environmental compliance and top-notch turf quality.
                                        </p>

                                        <Link
                                            className='signup text-white text-lg font-medium bg-darkgreen px-6 py-2 rounded-full hover:text-white hover:bg-litegreen transition duration-200'
                                            to={'/LandingChems'}>Learn More</Link>

                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words bg-gray-900 w-full mb-8 shadow-lg shadow-litegreen rounded-xl">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-darkgreen">
                                            <TruckIcon className="mt-1 h-5 w-5 flex-none text-white text-xl" aria-hidden="true"/>
                                        </div>
                                        <h6 className="text-xl font-semibold text-litegreen">
                                            Fleet Manager
                                        </h6>
                                        <p className="mt-2 mb-4 text-gray-300">
                                            Efficiently manage your golf course fleet. Track vehicles, schedule
                                            maintenance, and maximize productivity. Streamline operations for a smoother
                                            course management experience.
                                        </p>

                                        <Link
                                            className='signup text-white text-lg font-medium bg-darkgreen px-6 py-2 rounded-full hover:text-white hover:bg-litegreen transition duration-200'
                                            to={'/LandingFleet'}>Learn More</Link>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <section className="">
                    <div className="bg-bgcolor">
                        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
                            <div
                                className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                                <svg viewBox="0 0 1024 1024"
                                     className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                                     aria-hidden="true">

                                    <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                                            fillOpacity="0.7"/>

                                    <defs>
                                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                            <stop stopColor="#147E1F"/>
                                            <stop offset={1} stopColor="#4BB862"/>
                                        </radialGradient>
                                    </defs>

                                </svg>
                                <div
                                    className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                        Boost your productivity.
                                        <br/>
                                        Start using our app today.
                                    </h2>
                                    <p className="mt-6 text-lg leading-8 text-gray-300">
                                        Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada
                                        adipiscing sagittis vel nulla.
                                    </p>
                                    <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                        <Link
                                            className='rounded-md bg-darkgreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-litegreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
                                            to={'/register'}>Get started</Link>

                                        <Link
                                            className="text-sm font-semibold leading-6 text-white"
                                            to={'/LandingChems'}>Learn More</Link>

                                    </div>
                                </div>
                                <div className="relative mt-16 h-80 lg:mt-8">
                                    <img
                                        className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                                        src={dash2}
                                        alt="App screenshot"
                                        width={1824}
                                        height={1080}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="bg-bgcolor">
                    <div className="overflow-hidden bg-bgcolor py-24 sm:py-32">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div
                                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

                                <div className="lg:pr-8 lg:pt-4">
                                    <div className="lg:max-w-lg">
                                        <h2 className="text-base font-semibold leading-7 text-litegreen">Deploy
                                            faster</h2>
                                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">A
                                            better workflow</p>
                                        <p className="mt-6 text-lg leading-8 text-gray-300">
                                            Optimize workflow with our comprehensive software, managing chemical
                                            inventory, fleet, scheduling, and analytics. Streamline operations for
                                            enhanced efficiency and focus on business goals effortlessly.
                                        </p>
                                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                                            {features.map((feature) => (
                                                <div key={feature.name} className="relative pl-9">
                                                    <dt className="inline font-semibold text-darkgreen">
                                                        <feature.icon
                                                            className="absolute left-1 top-1 h-5 w-5 text-litegreen"
                                                            aria-hidden="true"/>
                                                        {feature.name}
                                                    </dt>
                                                    {' '}
                                                    <dd className="inline">{feature.description}</dd>
                                                </div>
                                            ))}
                                        </dl>
                                    </div>
                                </div>
                                <img
                                    src={dash2}
                                    alt="Product screenshot"
                                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="bg-bgcolor">
                    <div
                        className="relative isolate overflow-hidden bg-bgcolor px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                        <div className="absolute inset-0 -z-10 overflow-hidden">
                            <svg
                                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                                aria-hidden="true">
                            </svg>
                        </div>
                        <div
                            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                            <div
                                className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                <div className="lg:pr-4">
                                    <div className="lg:max-w-lg">
                                        <p className="text-base font-semibold leading-7 text-litegreen">Effortless
                                            Management</p>
                                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Optimized
                                            Golf Course Operations</h1>
                                        <p className="mt-6 text-xl leading-8 text-gray-300">
                                            Simplify management tasks and optimize golf course operations seamlessly
                                            with our comprehensive software suite.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                                <img
                                    className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                                    src={calendar}
                                    alt=""
                                />
                            </div>
                            <div
                                className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                <div className="lg:pr-4">
                                    <div className="max-w-xl text-base leading-7 text-gray-300 lg:max-w-lg">
                                        <p>
                                            Our software empowers you to streamline golf course management effortlessly.
                                            From chemical storage to fleet management, employee scheduling, and data
                                            analytics, our suite offers all the tools you need for efficient operations.
                                        </p>

                                        <ul className="mt-8 space-y-8 text-gray-600">

                                            <li className="flex gap-x-3">

                                                <TableCellsIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong
                                                        className="font-semibold text-darkgreen">Chemical Storage:</strong> Organize and manage chemicals efficiently to ensure safety and compliance.
                                                </span>
                                            </li>

                                            <li className="flex gap-x-3">
                                                <MapIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong
                                                        className="font-semibold text-darkgreen">Spray Calculator and Tracker:</strong> Track and calculate the application of sprays for efficient pest and weed management.
                                                </span>
                                            </li>

                                            <li className="flex gap-x-3">

                                                <TruckIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong className="font-semibold text-darkgreen">Fleet Management:</strong> Optimize the use of vehicles and equipment for maintenance and service tasks.
                                                </span>
                                            </li>

                                            <li className="flex gap-x-3">

                                                <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong
                                                        className="font-semibold text-darkgreen">Employee Management:</strong> Streamline employee scheduling and task assignments for seamless operations.
                                                </span>
                                            </li>

                                            <li className="flex gap-x-3">

                                                <CalendarDaysIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong
                                                        className="font-semibold text-darkgreen">Calendar:</strong> Manage events, maintenance schedules, and other activities efficiently with an integrated calendar feature.
                                                </span>
                                            </li>

                                            <li className="flex gap-x-3">

                                                <ChartBarIcon className="mt-1 h-5 w-5 flex-none text-litegreen" aria-hidden="true"/>

                                                <span className="text-gray-300">
                                                    <strong
                                                        className="font-semibold text-darkgreen">Data Analytics:</strong> Gain valuable insights and optimize decision-making with advanced data analytics tools.
                                                </span>
                                            </li>

                                        </ul>
                                        <p className="mt-8">
                                            Elevate your golf course management with our intuitive software, designed to
                                            streamline operations and enhance productivity effortlessly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="bg-bgcolor">

                    <div className="flex flex-wrap items-center">
                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                            <div
                                className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-darkgreen">
                                <FontAwesomeIcon icon={faUser} className="text-white text-xl"/>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal text-litegreen">
                                Creating an Account is Easy!
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
                                Creating an account on ChemBase is as effortless as it gets. With our user-friendly
                                interface and intuitive design,
                                you can sign up in just a few clicks. We prioritize your security, employing robust
                                encryption protocols to safeguard
                                your personal information. Rest assured, your data remains confidential and
                                protected at all times.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-white">
                                At ChemBase, your peace of mind is paramount. Whether you're a seasoned professional
                                or new to golf course management,
                                our platform offers a seamless account creation process. Experience the ease of
                                managing your chemical inventory with ChemBase,
                                where simplicity meets security for your convenience
                            </p>
                            <Link
                                className='signup text-white text-lg font-medium border border-white px-6 py-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200'
                                to={'/register'}>Sign Up</Link>
                        </div>

                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                            <div
                                className="relative flex flex-col min-w-0 break-words bg-litegreen w-full mb-6 shadow-xl rounded-lg">
                                <img
                                    alt="..."
                                    src={dash}
                                    className="w-full align-middle rounded-t-lg"
                                />
                                <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block"
                                        style={{
                                            height: "95px",
                                            top: "-94px"
                                        }}
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-darkgreen fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-white">
                                        Easy to Use User-Interface
                                    </h4>
                                    <p className="text-md font-light mt-2 text-white">
                                        Our software boasts an intuitive and easy-to-navigate user interface,
                                        designed to streamline your workflow and enhance productivity. With its
                                        user-friendly design,
                                        even new users can quickly familiarize themselves and efficiently utilize
                                        its features.
                                    </p>
                                </blockquote>
                            </div>
                        </div>

                    </div>

                </section>


                <section className="relative py-20 bg-bgcolor">

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img
                                    alt="..."
                                    className="max-w-full mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen"
                                    src={chart}
                                />
                            </div>
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <div
                                        className="text-darkgreen p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-darkgreen">
                                        <i className="fas fa-rocket text-xl"></i>
                                    </div>
                                    <h3 className="text-3xl text-litegreen font-semibold">
                                        Comprehensive Data Analytics
                                    </h3>
                                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                        Explore our innovative software tailored specifically for golf courses,
                                        offering advanced solutions for chemical inventory management, spray
                                        calculation,
                                        and fleet management. With our platform, effortlessly manage your chemical
                                        inventory,
                                        accurately calculate spray requirements, and streamline fleet operations,
                                        all while accessing insightful analytics presented in intuitive graphs.
                                        Revolutionize
                                        your golf course management with our comprehensive software, designed to
                                        optimize
                                        efficiency and elevate performance.
                                    </p>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                  <span
                                                      className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-litegreen mr-3">
                                                    <i className="fas fa-fingerprint"></i>
                                                  </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-white">Carefully calculated chemical usage
                                                        statistics</h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                  <span
                                                      className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-litegreen mr-3">
                                                    <i className="fab fa-html5"></i>
                                                  </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-white">Carefully calculated spray tracking
                                                        statistics</h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                  <span
                                                      className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-litegreen mr-3">
                                                    <i className="far fa-paper-plane"></i>
                                                  </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-white">Carefully calculated machine usage
                                                        statistics</h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*<section className=" relative pt-20 pb-48 bg-bgcolor">*/}

                {/*    <div className="container mx-auto px-4">*/}
                {/*        <div className="flex flex-wrap justify-center text-center mb-24">*/}
                {/*            <div className="w-full lg:w-6/12 px-4">*/}
                {/*                <h2 className="text-4xl font-semibold text-litegreen">*/}
                {/*                    Our Team*/}
                {/*                </h2>*/}
                {/*                <p className="text-lg leading-relaxed m-4 text-white">*/}
                {/*                    Started at the University of New Haven*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="flex flex-wrap">*/}
                {/*            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">*/}
                {/*                <div className="px-6">*/}
                {/*                    <img*/}
                {/*                        alt="..."*/}
                {/*                        src={spencer}*/}
                {/*                        className="shadow-lg rounded-full max-w-full mx-auto"*/}
                {/*                        style={{maxWidth: "120px"}}*/}
                {/*                    />*/}
                {/*                    <div className="pt-6 text-center">*/}
                {/*                        <h5 className="text-xl font-bold text-white">*/}
                {/*                            Spencer Wondolowski*/}
                {/*                        </h5>*/}
                {/*                        <p className="mt-1 text-sm text-darkgreen uppercase font-semibold">*/}
                {/*                            Project Lead*/}
                {/*                        </p>*/}
                {/*                        <div className="mt-6">*/}
                {/*                            <button*/}
                {/*                                className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-twitter"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-facebook-f"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-dribbble"></i>*/}
                {/*                            </button>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">*/}
                {/*                <div className="px-6">*/}
                {/*                    <img*/}
                {/*                        alt="..."*/}
                {/*                        src={ken}*/}
                {/*                        className="shadow-lg rounded-full max-w-full mx-auto"*/}
                {/*                        style={{maxWidth: "120px"}}*/}
                {/*                    />*/}
                {/*                    <div className="pt-6 text-center">*/}
                {/*                        <h5 className="text-xl font-bold text-white">*/}
                {/*                            Kenneth Yao*/}
                {/*                        </h5>*/}
                {/*                        <p className="mt-1 text-sm text-darkgreen uppercase font-semibold">*/}
                {/*                            Data Analytics*/}
                {/*                        </p>*/}
                {/*                        <div className="mt-6">*/}
                {/*                            <button*/}
                {/*                                className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-google"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-facebook-f"></i>*/}
                {/*                            </button>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">*/}
                {/*                <div className="px-6">*/}
                {/*                    <img*/}
                {/*                        alt="..."*/}
                {/*                        src={tim}*/}
                {/*                        className="shadow-lg rounded-full max-w-full mx-auto"*/}
                {/*                        style={{maxWidth: "120px"}}*/}
                {/*                    />*/}
                {/*                    <div className="pt-6 text-center">*/}
                {/*                        <h5 className="text-xl font-bold text-white">*/}
                {/*                            Timothy Fraedrich*/}
                {/*                        </h5>*/}
                {/*                        <p className="mt-1 text-sm text-darkgreen uppercase font-semibold">*/}
                {/*                            Project Manager*/}
                {/*                        </p>*/}
                {/*                        <div className="mt-6">*/}
                {/*                            <button*/}
                {/*                                className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-google"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-twitter"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-instagram"></i>*/}
                {/*                            </button>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">*/}
                {/*                <div className="px-6">*/}
                {/*                    <img*/}
                {/*                        alt="..."*/}
                {/*                        src={rohith}*/}
                {/*                        className="shadow-lg rounded-full max-w-full mx-auto"*/}
                {/*                        style={{maxWidth: "120px"}}*/}
                {/*                    />*/}
                {/*                    <div className="pt-6 text-center">*/}
                {/*                        <h5 className="text-xl font-bold text-white">*/}
                {/*                            Rohith Tangadipalli*/}
                {/*                        </h5>*/}
                {/*                        <p className="mt-1 text-sm text-darkgreen uppercase font-semibold">*/}
                {/*                            Engineering*/}
                {/*                        </p>*/}
                {/*                        <div className="mt-6">*/}
                {/*                            <button*/}
                {/*                                className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-dribbble"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-google"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-twitter"></i>*/}
                {/*                            </button>*/}
                {/*                            <button*/}
                {/*                                className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                {/*                                type="button"*/}
                {/*                            >*/}
                {/*                                <i className="fab fa-instagram"></i>*/}
                {/*                            </button>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}


                <section className="bg-bgcolor pb-16">

                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">

                        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                            <h2 className="mb-4 text-4xl tracking-tight font-semibold text-litegreen">Our Platforms</h2>
                            <p className="text-lg leading-relaxed m-4 text-white">Here at ChemBase we are available on
                                three platforms for your convenience</p>
                        </div>

                        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">


                            <div
                                className="flex flex-col p-6 mx-auto max-w-lg text-center text-white bg-bgcolor shadow-lg shadow-litegreen rounded-xl">

                                <h3 className="mb-4 text-2xl font-semibold text-litegreen">Android</h3>
                                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                    Best option for personal use & for your next project.
                                </p>

                                <div className="flex justify-center items-baseline my-8">

                                </div>

                                <ul className="mb-8 space-y-4 text-left">
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Chemical Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray History Tracking</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Fleet Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Service Tracking for Equipment</span>
                                    </li>
                                </ul>
                                <Link
                                    className='signup text-white text-lg font-medium border border-white px-6 py-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200'
                                    to={'/'}>Download Coming Soon</Link>
                            </div>


                            <div
                                className="flex flex-col p-6 mx-auto max-w-lg text-center text-white bg-bgcolor shadow-lg shadow-litegreen rounded-xl">
                                <h3 className="mb-4 text-2xl font-semibold text-litegreen">Web</h3>
                                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                    Relevant for multiple users, extended & premium support.
                                </p>

                                <div className="flex justify-center items-baseline my-8">

                                </div>

                                <ul className="mb-8 space-y-4 text-left">
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Chemical Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray History Tracking</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Fleet Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Service Tracking for Equipment</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Comprehensive Data Analytics</span>
                                    </li>
                                </ul>

                                <Link
                                    className='signup text-white text-lg font-medium border border-white px-6 py-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200'
                                    to={'/register'}>Sign Up</Link>

                            </div>


                            <div
                                className="flex flex-col p-6 mx-auto max-w-lg text-center text-white bg-bgcolor shadow-lg shadow-litegreen rounded-xl">
                                <h3 className="mb-4 text-2xl text-litegreen font-semibold">IOS</h3>
                                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                                    Best for large scale uses and extended redistribution rights.
                                </p>

                                <div className="flex justify-center items-baseline my-8">

                                </div>

                                <ul className="mb-8 space-y-4 text-left">
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Chemical Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray History Tracking</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Spray Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Fleet Database</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span>Service Tracking for Equipment</span>
                                    </li>
                                </ul>
                                <Link
                                    className='signup text-white text-lg font-medium border border-white px-6 py-2 rounded-full hover:bg-darkgreen hover:text-white hover:border-darkgreen transition duration-200'
                                    to={'/'}>Download Coming Soon</Link>
                            </div>


                        </div>
                    </div>
                </section>


            </main>
            <Footer/>
        </>
    );
}

export default Landing;
