import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { db } from '../../firebase/firebase'; // Import the db object from your firebase configuration file
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown, faCaretLeft,
    faCaretRight,
    faCaretUp,
} from '@fortawesome/free-solid-svg-icons';

import Header from "../header";
import {
    BeakerIcon,
    ChartBarSquareIcon, ChartPieIcon,
    EyeIcon,
    HomeIcon, PresentationChartBarIcon, PresentationChartLineIcon,
    QuestionMarkCircleIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import {CalculatorIcon} from "@heroicons/react/24/outline";
import {TruckIcon} from "@heroicons/react/24/outline";
import {UserGroupIcon} from "@heroicons/react/24/outline";
import {MapIcon} from "@heroicons/react/24/outline";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {ChartBarIcon} from "@heroicons/react/24/outline";




const Dashboard = () => {
    // Get the current pathname

    const { currentUser } = useAuth();
    const [userName, setUserName] = useState('');
    // const [userEmail, setUserEmail] = useState('');
    const [userTitle, setUserTitle] = useState('');
    const [userCompany, setUserCompany] = useState('');

    const [profileImage, setProfileImage] = useState('');

    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath



    const [expanded, setExpanded] = useState(currentPath === '/ChemsDash' || currentPath === '/SpraysDash' || currentPath === '/FleetDash' || currentPath ==="/EmployeeDash");

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setUserName(userData.fname);
                        setUserTitle(userData.title);
                        setUserCompany(userData.company);
                        setProfileImage(userData.profileImage);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setUserName(userData.fname);
                            setUserTitle(userData.title);
                            setUserCompany(userData.company);
                            setProfileImage(userData.profileImage);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);
    return (

        <div className={`flex flex-col justify-between ${collapse ? 'w-16 m-3' : 'w-80 p-3'}`}>

            <Header/>

            <div className={`overflow-y-auto bg-bgDark rounded-md shadow-md shadow-gray-750 h-full max-h-[calc(100vh-5.5rem)] scrollbar-none ${collapse ? 'p-1' : 'p-4'}`}>

                <div className="flex flex-row">
                    <div className={`text-2xl w-full text-center text-white mb-1 ${collapse ? 'hidden' : ''}`}>Dashboard</div>

                    <button className="justify-center items-end text-xl absolute left-1 text-white font-bold mt-auto mb-auto hover:text-litegreen bg-darkgreen w-8 h-8 rounded-md" onClick={toggleCollapse}>
                        {collapse ? <FontAwesomeIcon icon={faCaretRight}/> : <FontAwesomeIcon icon={faCaretLeft}/>}
                    </button>
                </div>


                <div className="p-1 flex items-center rounded-md">
                    <div className={`rounded-md bg-bgcard flex items-center justify-center shadow-md shadow-gray-850 ${collapse ? 'w-12 h-12 mb-2 mt-10' : 'w-12 h-12 mr-2'}`}>
                        {/*<FontAwesomeIcon icon={faUser} className="text-white text-xl"/>*/}
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="object-cover w-full h-full rounded-md"
                            style={{minWidth: '100%', minHeight: '100%'}}
                        />
                    </div>

                    <ul className="menu" style={{listStyleType: 'none', padding: 0}}>

                        <li className={`text-white p-1.5 rounded-md ${collapse ? 'hidden' : ''}`}>
                            <div className="text-sm text-white mb-2">{userName}</div>
                            <div className="text-xs/[8px] text-litegreen ml-2 mb-1">{userTitle}</div>
                        </li>

                    </ul>
                </div>


                <div className={`text-sm text-darkgreen font-bold mb-1.5 ${collapse ? 'hidden' : ''}`}>{userCompany}</div>


                <div className={`bg-greyText h-0.5 w-full shadow-lg shadow-gray-900 ${collapse ? 'mb-2' : 'mb-5'}`}></div>


                <ul className="menu justify-center items-center" style={{listStyleType: 'none', padding: 0}}>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Home" className={`flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Home' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faHouse} className="text-white text-xs"/>*/}
                                <HomeIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Home</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Map" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Map' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faMapLocation} className="text-white text-xs"/>*/}
                                <MapIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Map</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Calendar" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Calendar' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faCalendarDays} className="text-white text-xs"/>*/}
                                <CalendarDaysIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Calendar</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Vision" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Vision' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faGlasses} className="text-white text-xs"/>*/}
                                <EyeIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Greens Vision</div>
                        </Link>
                    </li>

                    <li className={` ${expanded ? 'cursor-pointer mb-3 flex flex-col rounded-md' : 'cursor-pointer mb-3 flex flex-col rounded-md hover:bg-bglite hover:opacity-20'}`}
                        onClick={toggleExpansion}>

                        <div className={`flex flex-row p-2 mb-3 items-center ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faChartSimple} className="text-white text-xs"/>*/}
                                <ChartBarIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>

                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Dashboards</div>

                            <button className={`text-2xl text-white font-bold mt-auto mb-auto hover:text-litegreen ml-auto ${collapse ? 'hidden' : ''}`}
                                onClick={toggleExpansion}>
                                {expanded ? <FontAwesomeIcon icon={faCaretUp}/> :
                                    <FontAwesomeIcon icon={faCaretDown}/>}
                            </button>
                        </div>


                        {expanded && (
                            <>
                                <div className="flex flex-col hover:bg-bgDark">

                                    <li className={`cursor-pointer ml-5 mb-3 flex items-center`}>
                                        <Link to="/ChemsDash" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/ChemsDash' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                                            <div className="w-5 h-5 mr-2 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900">
                                                {/*<FontAwesomeIcon icon={faChartLine} className="text-white text-xs"/>*/}
                                                <ChartBarSquareIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                                            </div>
                                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Chems
                                                Dashboard
                                            </div>
                                        </Link>
                                    </li>

                                    <li className={`cursor-pointer ml-5 mb-3 flex items-center`}>
                                        <Link to="/SpraysDash" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/SpraysDash' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                                            <div className="w-5 h-5 mr-2 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900">
                                                {/*<FontAwesomeIcon icon={faChartBar} className="text-white text-xs"/>*/}
                                                <PresentationChartLineIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                                            </div>
                                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Sprays
                                                Dashboard
                                            </div>
                                        </Link>
                                    </li>

                                    <li className={`cursor-pointer ml-5 mb-3 flex items-center`}>
                                        <Link to="/FleetDash" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/FleetDash' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                                            <div className="w-5 h-5 mr-2 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900">
                                                {/*<FontAwesomeIcon icon={faChartGantt} className="text-white text-xs"/>*/}
                                                <PresentationChartBarIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                                            </div>
                                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Fleet
                                                Dashboard
                                            </div>
                                        </Link>
                                    </li>

                                    <li className={`cursor-pointer ml-5 flex items-center`}>
                                        <Link to="/TeamDash" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/TeamDash' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                                            <div className="w-5 h-5 mr-2 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900">
                                                {/*<FontAwesomeIcon icon={faChartPie} className="text-white text-xs"/>*/}
                                                <ChartPieIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                                            </div>
                                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Team
                                                Dashboard
                                            </div>
                                        </Link>
                                    </li>


                                </div>
                            </>
                        )}
                    </li>


                    {/*----------------------------------------------------------------------*/}


                    <div className={`text-sm text-greyText mb-1 ${collapse ? 'text-center w-full' : ''}`}>Data</div>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Chems" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Chems' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faList} className="text-white text-xs"/>*/}
                                <BeakerIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Chemical Database</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Sprays" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Sprays' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faMap} className="text-white text-xs"/>*/}
                                <CalculatorIcon className="w-4 flex-none text-white" aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Spray Calculator</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Fleet" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Fleet' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faCar} className="text-white text-xs"/>*/}
                                <TruckIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Fleet Management</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Team" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Team' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faUsers} className="text-white text-xs"/>*/}
                                <UserGroupIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Employee Portal</div>
                        </Link>
                    </li>


                    {/*----------------------------------------------------------------------*/}


                    <div className={`text-sm text-greyText mb-1 ${collapse ? 'text-center w-full' : ''}`}>General</div>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/Account" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/Account' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faUser} className="text-white text-xs"/>*/}
                                <UserIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Account</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer mb-3 flex items-center`}>
                        <Link to="/FAQ" className={`text-white text-xs flex items-center w-full p-2 rounded-md hover:bg-bglite hover:opacity-20 hover:shadow-lg hover:shadow-gray-900 ${currentPath === '/FAQ' ? 'bg-bgcard bg-opacity-20 shadow-lg shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center' : ''}`}>
                            <div className={`w-5 h-5 rounded bg-bgcard p-4 flex items-center justify-center shadow-md shadow-gray-900 ${collapse ? '' : 'mr-2'}`}>
                                {/*<FontAwesomeIcon icon={faCircleQuestion} className="text-white text-xs"/>*/}
                                <QuestionMarkCircleIcon className="w-4 flex-none text-white" aria-hidden="true"/>

                            </div>
                            <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>FAQ</div>
                        </Link>
                    </li>


                </ul>
            </div>


        </div>
    );
};

export default Dashboard;

