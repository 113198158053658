import React, {useEffect, useState} from 'react';
import {useAuth} from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import {db} from '../../firebase/firebase';
import {
    faCaretDown,
    faCaretUp,
    faDownload,
    faEdit,
    faFileExcel,
    faMagnifyingGlass,
    faSquareCaretLeft,
    faSquareCaretRight,
    faTrash,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {utils as xlsxUtils, write as writeXlsx} from 'xlsx';
import {saveAs} from 'file-saver';
import imageSrc from '../../FleetID_Tag_Icon.png'; // Corrected import path
import {Bar, Line} from 'react-chartjs-2';
import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js';
import {XCircleIcon} from "@heroicons/react/24/outline";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const fleetOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Years',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const fleetOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Machine Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Service Cost',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Fleet = () => {
    const { currentUser } = useAuth();
    const [fleet, setFleet] = useState([]);
    const [fleetSide, setFleetSide] = useState(false);
    const [filteredFleet, setFilteredFleet] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        manufacturer: '',
        model: '',
        purchaseDate: '',
        year: 0,
        usage: 0,
        usageUnit: '',
        type: '',
        purchasePrice: 0,
        service: [],
        engineType: '',
        tag: false,
    });
    const [editingFleetId, setEditingFleetId] = useState(null);

    const onUpdate = async () => {
        try {
            const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
            const snapshot = await fleetCollectionRef.get();
            const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFleet(fleetData);
            setFilteredFleet(fleetData);
            localStorage.setItem(`fleetData_${currentUser.uid}`, JSON.stringify(fleetData));
        } catch (error) {
            console.error('Error updating fleet data:', error);
        }
    };

    useEffect(() => {
        const fetchFleet = async () => {
            if (currentUser) {
                try {
                    const storedFleetData = localStorage.getItem(`fleetData_${currentUser.uid}`);
                    if (storedFleetData) {
                        const fleetData = JSON.parse(storedFleetData);
                        setFleet(fleetData);
                        setFilteredFleet(fleetData);
                    } else {
                        const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
                        const snapshot = await fleetCollectionRef.get();
                        if (!snapshot.empty) {
                            const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setFleet(fleetData);
                            setFilteredFleet(fleetData);
                            localStorage.setItem(`fleetData_${currentUser.uid}`, JSON.stringify(fleetData));
                        } else {
                            console.log('No fleet found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching fleet:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setFleetSide(userData.fleetSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setFleetSide(userData.fleetSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchFleet();
    }, [currentUser]);

    const deleteMachineFromFirestore = async (machineId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId).delete();
            console.log('Fleet item deleted successfully');
            // Call the onUpdate callback function to trigger UI update
            await onUpdate();
        } catch (error) {
            console.error('Error deleting Fleet item:', error);
        }
    };


    const fillFormWithFleetData = (machine) => {
        setFormData({
            name: machine.name,
            manufacturer: machine.manufacturer,
            model: machine.model,
            purchaseDate: machine.purchaseDate,
            year: machine.year,
            usage: machine.usage,
            usageUnit: machine.usageUnit,
            type: machine.type,
            purchasePrice: machine.purchasePrice,
            service: machine.service,
            engineType: machine.engineType,
            tag: machine.tag,
        });
        setEditingFleetId(machine.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            manufacturer: '',
            model: '',
            purchaseDate: '',
            year: 0,
            usage: 0,
            usageUnit: '',
            type: '',
            purchasePrice: 0,
            service: [],
            engineType: '',
            tag: false,
        });
        setEditingFleetId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');

        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            purchaseDate: formData.purchaseDate || currentDate
        };


        const parsedFormData = {
            ...formDataWithDate,
            year: parseFloat(formData.year),
            usage: parseFloat(formData.usage),
            purchasePrice: parseFloat(formData.purchasePrice),
        };

        try {
            if (editingFleetId) {
                await collectionRef.doc(editingFleetId).update(parsedFormData);
                console.log('Machine updated successfully!');
                await onUpdate()
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Machine added successfully!');
                await onUpdate()
            }

            const snapshot = await collectionRef.get();
            const updatedFleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFleet(updatedFleetData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating chem: ', error);
        }
    };

    const calculateTotalPrice = (machine) => {
        return (machine.purchasePrice);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'purchaseDate') {
            // If the changed field is 'date', directly set the value
            setFormData((prevData) => ({
                ...prevData,
                purchaseDate: value,
            }));
        } else {
            // For other fields, update as usual
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };



    const handleToggleChange = () => {
        setFormData(prevData => ({
            ...prevData,
            tag: !prevData.tag,
        }));
    };





    // Function to filter machines based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = fleet.filter(machine =>
            machine.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredFleet(filtered);
    };

    const filterFleetByType = (type) => {
        setSelectedType(type);
        const filtered = fleet.filter(fleet => type === '' || fleet.type === type);
        setFilteredFleet(filtered);
    };




    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');


    const calculateTotalCost = (machine) => {

        const totalServiceCost = machine.service.reduce((accumulator, current) => {
            return accumulator + (current.serviceCost);
        }, 0).toFixed(2);

        return (totalServiceCost);
    };


    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Manufacturer', 'Purchase Date', 'Year', 'Usage', 'Usage Unit', 'Type', 'Purchase Price', 'Service', 'Engine Type', 'Tag'];
        const worksheetData = [columnHeaders, ...fleet.map(machine => [machine.name, machine.manufacturer, machine.purchaseDate, machine.year, machine.usage, machine.usageUnit, machine.type, machine.purchasePrice, machine.service, machine.engineType, machine.tag])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Machines');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'MachineInventory.xlsx');
    };

    const addOrUpdateButtonText = editingFleetId ? 'Update Machine' : 'Add Machine';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(fleetSide); // Set isCollapsed to the value of chemSide from Firebase
    }, [fleetSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const calculateAverageUsage = (machines) => {
        const totalUsage = machines.reduce((accumulator, machine) => {
            const usage = machine.usage;
            return accumulator + usage;
        }, 0);

        const averageUsage = totalUsage / filteredFleet.length;
        return averageUsage.toFixed(2);
    };

    const calculateAverageCost = (machines) => {
        const totalCost = machines.reduce((accumulator, machine) => {
            const serviceCost = parseFloat(calculateTotalCost(machine));
            return accumulator + serviceCost;
        }, 0);

        const average = totalCost / filteredFleet.length
        return average.toFixed(2);
    };

    const calculateModeYear = (machines) => {
        const yearFrequency = machines.reduce((acc, machine) => {
            const year = machine.year;
            acc[year] = (acc[year] || 0) + 1;
            return acc;
        }, {});

        let modeYear = null;
        let maxFrequency = 0;

        for (const year in yearFrequency) {
            if (yearFrequency[year] > maxFrequency) {
                maxFrequency = yearFrequency[year];
                modeYear = year;
            }
        }

        return modeYear;
    };

    const calculateYearsSincePurchase = (machine) => {
        const currentYear = new Date().getFullYear();

        const purchaseYear = new Date(machine.purchaseDate).getFullYear();
        return currentYear - purchaseYear;
    };

    const calcYears = () => {
        return filteredFleet.map(machine => ({
            name: machine.name,
            years: calculateYearsSincePurchase(machine)
        }));
    };

    const averageUsage = calculateAverageUsage(filteredFleet);
    const averageTotalCost = calculateAverageCost(filteredFleet);
    const modeYear = calculateModeYear(filteredFleet);

    const years = calcYears(filteredFleet);


    // const calcTotalChemVol = (spray) => {
    //
    //     const areaUnit = spray.areaUnit;
    //     const area = spray.area;
    //     let areaNum;
    //
    //     if (areaUnit === "acres") {
    //         areaNum = area * 43560; // Convert acres to square feet
    //     } else {
    //         areaNum = area; // Assume the area is already in square feet
    //     }
    //
    //     return spray.ingredients.reduce((accumulator, current) => {
    //         return accumulator + (areaNum * ((current.rate / 1000) / 128));
    //     }, 0).toFixed(2);
    //
    // }

    // const calculateTotalCost = (spray) => {
    //
    //     const areaUnit = spray.areaUnit;
    //     const area = spray.area;
    //
    //     let areaNum;
    //
    //     if (areaUnit === "acres") {
    //         areaNum = area * 43560; // Convert acres to square feet
    //     } else {
    //         areaNum = area; // Assume the area is already in square feet
    //     }
    //
    //     const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
    //         return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
    //     }, 0).toFixed(2);
    //
    //     return (totalChemPrice / spray.area).toFixed(2);
    // };
    //
    const calcCost = () => {
        return filteredFleet.map(machine => ({
            name: machine.name,
            cost: calculateTotalCost(machine)
        }));
    };

    // const uniqueNutrients = calculateNutrients();
    const costs = calcCost();

    const fleetBarChartData = {
        labels: years.map(machine => machine.name.slice(0, 5)),
        datasets: [
            {
                label: 'Age (Years)',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: years.map(machine => machine.years),
            }
        ]
    };

    const fleetBarChartData2 = {
        labels: costs.map(machine => machine.name.slice(0,5)),
        datasets: [
            {
                label: 'Service Cost',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: costs.map(machine => machine.cost),
            }
        ]
    };


    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    return (
        <div className="bg-bgpage flex h-full w-full mt-16">


            <Dashboard/>


            <div className="w-full flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    <div className="flex flex-col justify-center items-center">

                        <div className="flex items-center justify-center w-full flex-row">

                            <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-md shadow-gray-900 w-full mr-2">

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Machines:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{filteredFleet.length}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Purchase Price:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{"$" + numberWithCommas(filteredFleet.reduce((total, machine) => total + parseFloat(calculateTotalPrice(machine)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-md text-white mr-2">Total Service Cost:</div>
                                        <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(filteredFleet.reduce((total, machine) => total + parseFloat(calculateTotalCost(machine)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <button className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2" onClick={toggleExpansion}>
                                    {expanded ? <FontAwesomeIcon icon={faCaretUp}/> :
                                        <FontAwesomeIcon icon={faCaretDown}/>}
                                </button>

                            </div>

                            <button onClick={downloadAsExcel}
                                    className="bg-darkgreen hover:bg-litegreen text-white flex font-bold p-2 rounded-md focus:outline-none focus:shadow-outline">
                                <FontAwesomeIcon icon={faDownload} className="text-white mr-2"/>
                                <FontAwesomeIcon icon={faFileExcel} className="text-white"/>
                            </button>

                            <button className="ml-2 text-3xl text-white" onClick={toggleCollapse}>
                                {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                    <FontAwesomeIcon icon={faSquareCaretRight}/>}
                            </button>

                        </div>


                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div className="mt-2 mr-2 ml-2 p-2 bg-bgcolor flex items-center justify-center rounded-md shadow-mg shadow-gray-900 w-full">


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Service Cost Comparison:</div>
                                            <Bar options={fleetOptions2} data={fleetBarChartData2} className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div className="w-full mr-2 rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Usage:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{numberWithCommas(averageUsage)}</div>
                                                </div>
                                            </div>


                                            <div className="w-full rounded-md bg-bglite p-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Model Year:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">{modeYear}</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div className="mr-auto ml-auto rounded-md bg-bglite pl-6 pr-6 pb-1 pt-1">
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Service Cost:</div>
                                                    <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen">${numberWithCommas(averageTotalCost)}</div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Equipment Age:</div>
                                            <Bar options={fleetOptions} data={fleetBarChartData} className="ml-auto mr-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}


                    </div>


                    <div className="mt-2 w-full flex flex-row items-center">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white mr-2"/>
                        <input
                            type="text"
                            placeholder="Search Fleet By Name"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className="bg-bglite appearance-none rounded-md w-full mr-2 py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                        />

                        <div className="w-1/4 flex flex-row items-center">
                            <select
                                value={selectedType}
                                onChange={(e) => filterFleetByType(e.target.value)}
                                className="bg-bglite appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">All Types</option>

                                <option value="Greens Mower">Greens Mower</option>
                                <option value="Tees Mower">Tees Mower</option>
                                <option value="Fairway Mower">Fairway Mower</option>
                                <option value="Rough Mower">Rough Mower</option>
                                <option value="Bunker Rake">Bunker Rake</option>
                                <option value="Work Cart">Work Cart</option>
                                <option value="Ball Picker">Ball Picker</option>
                                <option value="Utility Vehicle">Utility Vehicle</option>
                                <option value="Tow Blower">Tow Blower</option>
                                <option value="Tractor">Tractor</option>
                                <option value="Truck">Truck</option>
                                <option value="Misc.">Misc.</option>
                                {/* Add more options as needed */}
                            </select>

                            <div className="text-white ml-2 text-xl">
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>

                        </div>

                    </div>


                    <div className={` ${expanded ? 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-23.4rem)] scrollbar-none' : 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-11.25rem)] scrollbar-none'}`}>


                        {fleet.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div className="bg-bgcolor p-4 rounded-md shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a Machine Through the App</p>

                                    <p className="text-xs text-white mt-2">1] Fill out the panel on the right hand side of the screen</p>
                                    <p className="text-xs text-white mt-1">2] Scroll down on the panel until you see the Add Machine button</p>
                                    <p className="text-xs text-white mt-1">3] Press the Add Machine button to add it to your database</p>
                                    <p className="text-xs text-white mt-1">4] If your spray does not automatically show up on the screen refresh your page and it should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Adding a Machine through the ID Tag</p>

                                    <p className="text-xs text-white mt-2">1] Open up the ChemBase scanner app on your mobile phone</p>
                                    <p className="text-xs text-white mt-1">2] Make sure you have NFC enabled on your device and that your device is NFC compatible</p>
                                    <p className="text-xs text-white mt-1">3] Tap the ChemBase ID Tag to the back of your mobile phone</p>
                                    <p className="text-xs text-white mt-1">4] Fill out the form that pops up on your device and press add Machine</p>
                                    <p className="text-xs text-white mt-1">5] You should be able to see your machine now pop up on the Web and or Mobile ChemBase applications</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredFleet.map((machine, index) => (
                                <MachineCard key={index} machine={machine}
                                             onDelete={() => deleteMachineFromFirestore(machine.id, onUpdate())}
                                             onEdit={fillFormWithFleetData}/>
                            ))
                        )}


                    </div>


                </div>


                <div className={` ${isCollapsed ? 'hidden' : 'bg-bgcolor flex flex-col rounded-md shadow-lg shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/4'}`}>

                    <div className="text-white justify-center items-center text-center text-lg animate-fadeIn">Add Machine</div>

                    <button type="button" onClick={clearForm} className="absolute font-bold py-1 px-1 rounded-md mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                        <XCircleIcon className="w-6 hover:text-red-700 flex-none text-white" aria-hidden="true"/>
                    </button>

                    <form onSubmit={handleSubmit}
                          className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                        <div className="text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                        <label className="block text-white text-sm mb-2 mr-5" htmlFor="name">
                                Name:
                            </label>

                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="manufacturer">
                                Manufacturer:
                            </label>

                            <input
                                type="text"
                                id="manufacturer"
                                name="manufacturer"
                                value={formData.manufacturer}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="model">
                                Model:
                            </label>

                            <input
                                type="text"
                                id="model"
                                name="model"
                                value={formData.model}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm w-full" htmlFor="purchaseDate">
                                Purchase Date:
                            </label>

                            <input
                                type="text"
                                id="purchaseDate"
                                name="purchaseDate"
                                value={formData.purchaseDate || currentDate}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">CLASSIFIERS
                        </div>


                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="type">
                                Machine Type:
                            </label>

                            <select
                                id="type"
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Greens Mower">Greens Mower</option>
                                <option value="Tees Mower">Tees Mower</option>
                                <option value="Fairway Mower">Fairway Mower</option>
                                <option value="Rough Mower">Rough Mower</option>
                                <option value="Bunker Rake">Bunker Rake</option>
                                <option value="Work Cart">Work Cart</option>
                                <option value="Ball Picker">Ball Picker</option>
                                <option value="Utility Vehicle">Utility Vehicle</option>
                                <option value="Tow Blower">Tow Blower</option>
                                <option value="Tractor">Tractor</option>
                                <option value="Truck">Truck</option>
                                <option value="Misc.">Misc.</option>
                            </select>
                        </div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm mr-5" htmlFor="engineType">
                                Engine Type:
                            </label>

                            <select
                                id="engineType"
                                name="engineType"
                                value={formData.engineType}
                                onChange={handleChange}
                                className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Gasoline">Gasoline</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Electric">Electric</option>
                                <option value="Hybrid">Hybrid</option>

                            </select>
                        </div>


                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="year">
                                Year:
                            </label>

                            <input
                                type="number"
                                id="year"
                                name="year"
                                value={formData.year}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to identify this chemical in your database
                        </div>


                        <div
                            className="text-white mt-4 justify-center items-center text-center text-xs mb-2">MEASUREMENTS
                        </div>


                        <div className="flex mb-2">
                            <div className="w-full mr-5">
                                <label className="block text-white text-sm mb-2" htmlFor="usage">
                                    Usage:
                                </label>

                                <input
                                    type="number"
                                    id="usage"
                                    name="usage"
                                    value={formData.usage}
                                    onChange={handleChange}
                                    className="bg-bgcard w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <div className="w-full">
                                <label className="block text-white text-sm mb-2" htmlFor="usageUnit">
                                    Usage Unit:
                                </label>

                                <select
                                    id="usageUnit"
                                    name="usageUnit"
                                    value={formData.usageUnit}
                                    onChange={handleChange}
                                    className="bg-bglite appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >

                                    <option value="">Select...</option>

                                    <option value="Hours">Hours</option>
                                    <option value="Miles">Miles</option>

                                </select>
                            </div>
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to calculate your volume
                        </div>


                        <div className="text-white mt-4 justify-center items-center text-center text-xs">PRICE</div>


                        <div className="flex items-center mt-2 mb-2 justify-start">
                            <label className="block text-white text-sm w-full" htmlFor="purchasePrice">
                                Purchase Price:
                            </label>

                            <input
                                type="number"
                                id="purchasePrice"
                                name="purchasePrice"
                                value={formData.purchasePrice}
                                onChange={handleChange}
                                step="0.01"
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            />
                        </div>


                        <div
                            className="text-darkgreen mt-2 mb-4 font-bold justify-center items-center text-center text-xxs">This
                            is the data that the system uses to calculate your cost analysis
                        </div>


                        <div className="flex items-center justify-center mt-4 mb-4 relative">
                            <label htmlFor="tag" className="text-white text-sm cursor-pointer mr-5">Id Tag:</label>
                            <input
                                type="checkbox"
                                id="tag"
                                name="tag"
                                className="sr-only"
                                checked={formData.tag}
                                onChange={handleToggleChange}
                            />
                            <label htmlFor="tag" className="cursor-pointer">
                                <div className={`w-14 h-8 bg-gray-600 rounded-full relative ${formData.tag ? 'bg-green-500' : 'bg-red-500'}`}>
                                    <div
                                        className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${formData.tag ? 'bg-white translate-x-full' : 'bg-white'}`}></div>
                                </div>
                            </label>
                        </div>

                        {/* Add more input fields as needed */}
                        <div className="flex w-full justify-center mt-4">
                            {/* Add or Update Chem button */}
                            <button type="submit"
                                    className="w-full bg-darkgreen hover:bg-litegreen text-white text-sm font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                {addOrUpdateButtonText}
                            </button>
                        </div>


                    </form>

                </div>


            </div>


        </div>
    );
};


const MachineCard = ({machine, onDelete, onEdit}) => {
    const { currentUser } = useAuth();

    const [expanded, setExpanded] = useState(false); // State to track expansion

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Operational Usage',
                data: [],
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            const labels = [];
            const serviceUsage = [];
            machine.service.forEach(item => {
                labels.push(item.serviceDate);
                serviceUsage.push(item.serviceUsage);
            });
            setChartData({
                ...chartData,
                labels: labels,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: serviceUsage,
                    },
                ],
            });
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    const chartOptions = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Date',
                    color: 'white',
                    font: {
                        size: 16,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Operational Usage',
                    color: 'white',
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const [serviceData, setServiceData] = useState({
        // Initialize form fields
        serviceType: '',
        serviceCost: 0,
        serviceUsage: machine.usage,
        serviceDate: new Date().toLocaleDateString('en-US').split('/').join('-'), // Set default value to current date
        serviceProvider: '',
    });

    const toggleServiceForm = () => {
        setShowServiceForm(!showServiceForm);
    };

    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        setServiceData({
            ...serviceData,
            [name]: value,
        });
    };

    const handleServiceSubmit = async (e) => {
        e.preventDefault();

        // Parse serviceUsage and serviceCost to float
        const parsedServiceUsage = parseFloat(serviceData.serviceUsage);
        const parsedServiceCost = parseFloat(serviceData.serviceCost);

        // Create a reference to the user's fleet document
        const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machine.id);

        try {
            // Fetch the current services array
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service } = doc.data();
                // Append the new service to the array
                const updatedServices = [...service, { ...serviceData, serviceUsage: parsedServiceUsage, serviceCost: parsedServiceCost }];
                // Update the document with the modified array
                await fleetRef.update({ service: updatedServices });

                // Update machine.usage in the parent document with the new serviceUsage value
                await fleetRef.update({ 'usage': parsedServiceUsage });
            } else {
                // If the document doesn't exist, create it with the new service
                await fleetRef.set({
                    service: [{ ...serviceData, serviceUsage: parsedServiceUsage, serviceCost: parsedServiceCost }],
                });
            }

            // Reset form fields
            setServiceData({
                serviceType: '',
                serviceCost: 0,
                serviceUsage: 0,
                serviceDate: new Date().toLocaleDateString('en-US').split('/').join('-'), // Set service date to current date
                serviceProvider: '',
            });
            // Close the service form
            setShowServiceForm(false);
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };


    const totalServiceCost = machine.service.reduce((accumulator, current) => {
        return accumulator + (current.serviceCost);
    }, 0).toFixed(2);

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(machine.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };


    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <div className="bg-bgcolor p-2 rounded-md items-center justify-center shadow-md shadow-gray-900 mb-2 w-full animate-cascade">

            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-lg">
                        <p className="text-white">Are you sure you want to delete this Machine?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">

                <div className="mr-2">
                    <button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                    </button>
                </div>

                <div className="bg-darkgreen h-full rounded-md flex flex-col items-center justify-center p-1 w-full shadow-md shadow-gray-900 mr-5">
                    <div className="flex items-center">
                        <div className="text-xl text-white text-center line-clamp-1">{machine.name}</div>
                        {machine.tag && <img src={imageSrc} alt="" className="ml-2 w-8"/>}
                    </div>

                    <div className="text-xs text-white text-center line-clamp-1">{machine.type}</div>
                    <div className="text-xs text-white text-center line-clamp-1">{machine.purchaseDate}</div>
                </div>



                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full shadow-md shadow-gray-900 mr-5">

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Manufacturer:</div>
                        <div className="text-xs text-white text-center mb-1 line-clamp-1">Engine Type:</div>
                        <div className="text-xs text-white text-center line-clamp-1">Model Year:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className="rounded-md border-2 p-1.5 border-solid border-darkgreen">
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{machine.manufacturer}</div>
                            <div className="text-xs text-white text-center mb-1 line-clamp-1">{machine.engineType}</div>
                            <div className="text-xs text-white text-center line-clamp-1">{machine.year}</div>
                        </div>

                    </div>

                </div>


                <div className="bg-bgcolor h-full rounded-md flex p-2 w-full items-center justify-center shadow-md shadow-gray-900">

                    <div className="justify-center items-center">

                        <div className="w-full">
                            <div className="text-sm text-white text-center line-clamp-1 mb-1">Operational Usage:</div>
                            <div className="text-sm text-white text-center line-clamp-1 rounded-md border-2 p-1 border-solid border-darkgreen shadow-lg shadow-gray-900">{numberWithCommas(machine.usage) + " " + machine.usageUnit}</div>
                        </div>

                    </div>

                </div>


                <div className="h-full rounded-md flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Purchase Price:</div>
                        <div className="text-md text-white text-center mb-2 line-clamp-1">${numberWithCommas(machine.purchasePrice.toFixed(2))}</div>

                        <div className={`rounded-md border-2 p-1.5 border-solid shadow-md shadow-gray-900 ${totalServiceCost > machine.purchasePrice ? 'border-red-500 animate-pulse' : 'border-darkgreen'}`}>
                            <div className="text-lg text-darkgreen text-center line-clamp-1">Service Cost:</div>
                            <div className="text-md text-white text-center line-clamp-1">${numberWithCommas(totalServiceCost)}</div>
                        </div>

                    </div>



                </div>

                <div className="flex flex-col items-center">


                    <button onClick={() => onEdit(machine)} className="text-md text-white hover:text-litegreen px-2 mb-4">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>

                    <button className="text-xxl text-white font-bold mt-auto mb-auto hover:text-litegreen"
                            onClick={toggleExpansion}>
                        {expanded ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
                    </button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <div>

                    <div className=" w-full items-center justify-center">
                        <div className="text-md text-white font-bold text-center mt-5">OPERATIONAL STATISTICS</div>
                        <div className="text-xs text-darkgreen text-center">This is a chart of operational usage on the machine over time</div>
                    </div>

                    <div className="flex justify-center">
                        <div className="w-full max-w-screen-md h-96">
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </div>


                    <div className="flex">

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className=" w-full items-center justify-center">
                            <div className="text-md text-white font-bold text-center mt-5">SERVICES</div>
                            <div className="text-xs text-darkgreen text-center">This is all the data logged for each service!</div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">
                            <button className="text-white text-xs p-2 rounded-lg bg-darkgreen focus:outline-none focus:shadow-outline hover:bg-litegreen"
                                onClick={toggleServiceForm}>
                                Log a Service
                            </button>
                        </div>


                    </div>


                    <div className="items-center justify-center overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                        {machine.service.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center">
                                <div className="bg-bgcolor p-4 rounded-md shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-5">
                                    <p className="text-xl text-darkgreen">This Machine Has No Service</p>
                                    <p className="text-lg text-litegreen mt-1">Logging a Service</p>

                                    <p className="text-xs text-white mt-2">1] Press the Log a Service button to the left of the service title</p>
                                    <p className="text-xs text-white mt-1">2] This will open up the popup in the middle of the screen</p>
                                    <p className="text-xs text-white mt-1">3] Fill out the fields in the popup and then hit Submit at the bottom</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            machine.service.map((service, index) => (
                                <ServiceCard key={index} service={service} unit={machine.usageUnit} id={index} machineId={machine.id}/>
                            ))
                        )}

                    </div>


                    <div className="bg-bgcolor flex items-center justify-center rounded-md border-2 p-1 border-solid border-darkgreen shadow-lg shadow-gray-900 mt-5 mr-24 ml-24">

                        <div className="mr-10">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Services:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">{machine.service.length}</div>
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center justify-center">
                                <div className="text-sm text-white mr-2 line-clamp-1">Total Cost:</div>
                                <div className="text-sm text-white rounded-md border-2 p-1 border-solid border-darkgreen line-clamp-1">${totalServiceCost}</div>
                            </div>
                        </div>

                    </div>


                </div>

            )}


            {showServiceForm && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-lg">

                        <button type="cancel" className="rounded-lg text-2xl text-white" onClick={toggleServiceForm}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>

                        <div className="text-sm text-litegreen font-bold text-center mb-2">Log Service For: {machine.name}</div>

                        <form onSubmit={handleServiceSubmit}>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceDate">
                                    Date:
                                </label>

                                <input
                                    type="text"
                                    name="serviceDate"
                                    value={serviceData.serviceDate}
                                    onChange={handleServiceChange}
                                    placeholder="Service Date"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                    required
                                />
                            </div>

                            <div className="flex items-center justify-start mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceType">
                                    Type:
                                </label>

                                <select
                                    id="serviceType"
                                    name="serviceType"
                                    value={serviceData.serviceType}
                                    onChange={handleServiceChange}
                                    className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                >
                                    <option value="">Select...</option>

                                    <option value="Oil change">Oil change</option>
                                    <option value="Filter replacement (air, oil, fuel)">Filter replacement (air, oil, fuel)</option>
                                    <option value="Blade sharpening">Blade sharpening</option>
                                    <option value="Belt replacement">Belt replacement</option>
                                    <option value="Tire replacement">Tire replacement</option>
                                    <option value="Battery check/replacement">Battery check/replacement</option>
                                    <option value="Electrical system inspection/repair">Electrical system inspection/repair</option>
                                    <option value="Brake inspection/adjustment">Brake inspection/adjustment</option>
                                    <option value="Carburetor cleaning/adjustment">Carburetor cleaning/adjustment</option>
                                    <option value="Hydraulic fluid change">Hydraulic fluid change</option>
                                    <option value="Fuel system cleaning">Fuel system cleaning</option>
                                    <option value="Engine tune-up">Engine tune-up</option>
                                    <option value="Lubrication of moving parts">Lubrication of moving parts</option>
                                    <option value="Ignition system check/repair">Ignition system check/repair</option>
                                    <option value="Cooling system flush">Cooling system flush</option>
                                    <option value="Clutch adjustment/replacement">Clutch adjustment/replacement</option>
                                    <option value="Steering system inspection/alignment">Steering system inspection/alignment</option>
                                    <option value="Transmission service">Transmission service</option>
                                    <option value="Mower deck leveling">Mower deck leveling</option>
                                    <option value="General inspection and maintenance">General inspection and maintenance</option>


                                </select>
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceProvider">
                                    Provider:
                                </label>

                                <input
                                    type="text"
                                    name="serviceProvider"
                                    value={serviceData.serviceProvider}
                                    onChange={handleServiceChange}
                                    placeholder="Service Provider"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                    required
                                />
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceUsage">
                                    Usage:
                                </label>

                                <input
                                    type="number"
                                    name="serviceUsage"
                                    value={serviceData.serviceUsage}
                                    onChange={handleServiceChange}
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                    required
                                />

                                <label className="block text-white text-sm ml-5 mr-5" htmlFor="serviceUsage">
                                    {machine.usageUnit}
                                </label>
                            </div>

                            <div className="flex items-center justify-center mt-2 mb-2">
                                <label className="block text-white text-sm mr-5" htmlFor="serviceCost">
                                    Cost:
                                </label>

                                <input
                                    type="number"
                                    name="serviceCost"
                                    value={serviceData.serviceCost}
                                    onChange={handleServiceChange}
                                    placeholder="Service Cost"
                                    className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                />
                            </div>

                            <button type="submit" className="w-full rounded-lg bg-darkgreen text-white p-1">Submit</button>

                        </form>

                    </div>
                </div>
            )}



        </div>
    );
};



const ServiceCard = ({ service, unit, id, machineId }) => {

    const { currentUser } = useAuth();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = async () => {
        try {
            const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId);
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service: currentServices } = doc.data();
                const updatedServices = currentServices.filter((_, currentIndex) => currentIndex !== id);
                await fleetRef.update({ service: updatedServices });
                toggleDeleteConfirmation()
            }
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editedService, setEditedService] = useState(service);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        try {
            const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId);
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service: currentServices } = doc.data();
                // Update the specific service item in the array
                currentServices[id] = editedService;
                // Update the entire service array in Firestore
                await fleetRef.update({ service: currentServices });

                // If the edited item is the last one in the array
                if (id === currentServices.length - 1) {
                    // Update 'usage' field with the edited service usage
                    await fleetRef.update({ 'usage': editedService.serviceUsage });
                }

                toggleEditMode(); // Exit edit mode after saving
            }
        } catch (error) {
            console.error('Error updating service:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedService({
            ...editedService,
            [name]: value
        });
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="bg-bgcolor flex items-center justify-center rounded-md p-2 m-2 shadow-md shadow-gray-900 mt-2 ">

            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-bgcolor p-4 rounded-lg">
                        <p className="text-white">Are you sure you want to delete this Service?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditing ? (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">

                    <div className="bg-bgcolor p-4 rounded-lg flex flex-col">

                        <div className="text-sm text-litegreen font-bold text-center mb-2">Edit Service
                            For: {service.serviceDate}</div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceDate">
                                Date:
                            </label>

                            <input
                                type="text"
                                name="serviceDate"
                                value={editedService.serviceDate}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceProvider">
                                Provider:
                            </label>

                            <input
                                type="text"
                                name="serviceProvider"
                                value={editedService.serviceProvider}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceType">
                                Type:
                            </label>

                            <select
                                id="serviceType"
                                name="serviceType"
                                value={editedService.serviceType}
                                onChange={handleChange}
                                className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Oil change">Oil change</option>
                                <option value="Filter replacement (air, oil, fuel)">Filter replacement (air, oil, fuel)</option>
                                <option value="Blade sharpening">Blade sharpening</option>
                                <option value="Belt replacement">Belt replacement</option>
                                <option value="Tire replacement">Tire replacement</option>
                                <option value="Battery check/replacement">Battery check/replacement</option>
                                <option value="Electrical system inspection/repair">Electrical system inspection/repair</option>
                                <option value="Brake inspection/adjustment">Brake inspection/adjustment</option>
                                <option value="Carburetor cleaning/adjustment">Carburetor cleaning/adjustment</option>
                                <option value="Hydraulic fluid change">Hydraulic fluid change</option>
                                <option value="Fuel system cleaning">Fuel system cleaning</option>
                                <option value="Engine tune-up">Engine tune-up</option>
                                <option value="Lubrication of moving parts">Lubrication of moving parts</option>
                                <option value="Ignition system check/repair">Ignition system check/repair</option>
                                <option value="Cooling system flush">Cooling system flush</option>
                                <option value="Clutch adjustment/replacement">Clutch adjustment/replacement</option>
                                <option value="Steering system inspection/alignment">Steering system inspection/alignment</option>
                                <option value="Transmission service">Transmission service</option>
                                <option value="Mower deck leveling">Mower deck leveling</option>
                                <option value="General inspection and maintenance">General inspection and maintenance</option>

                            </select>
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceUsage">
                                Usage:
                            </label>

                            <input
                                type="number"
                                name="serviceUsage"
                                value={editedService.serviceUsage}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceCost">
                                Cost:
                            </label>

                            <input
                                type="number"
                                name="serviceCost"
                                value={editedService.serviceCost}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-md shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex">
                            <button type="submit" className="w-1/2 rounded-lg bg-darkgreen text-white p-1 mr-2" onClick={handleSave}>Update</button>
                            <button type="submit" className="w-1/2 rounded-lg bg-red-700 text-white p-1 ml-2" onClick={toggleEditMode}>Cancel</button>
                        </div>


                    </div>
                </div>
            ) : null}

            <div className="mr-2 items-center justify-center">
                <button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                    <FontAwesomeIcon icon={faTrash} className="text-red-700 hover:text-red-500"/>
                </button>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 rounded-md border-2 p-1 border-solid border-darkgreen mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Date:</div>
                <div className="text-sm text-white line-clamp-1">{service.serviceDate}</div>

                <div className="flex items-center justify-center w-full">
                <div className="text-sm text-darkgreen line-clamp-1 mr-1">Provider:</div>
                    <div className="text-sm text-white line-clamp-1">{service.serviceProvider}</div>
                </div>

            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">Service Type:</div>
                <div className="text-sm text-white line-clamp-1">{service.serviceType}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-5 w-1/3">
                <div className="text-sm text-darkgreen line-clamp-1">Usage:</div>
                <div className="text-sm text-white line-clamp-1">{numberWithCommas(service.serviceUsage) + " " + unit}</div>
            </div>

            <div className="flex flex-col items-center justify-center shadow-md shadow-gray-900 p-1 rounded-md mr-5 w-1/4">
                <div className="text-sm text-darkgreen line-clamp-1">Cost:</div>
                <div className="text-sm text-white line-clamp-1">${numberWithCommas(service.serviceCost)}</div>
            </div>

            <div className="mr-2 items-center justify-center">
                <button className="text-xl text-white font-bold hover:text-litegreen" onClick={toggleEditMode}>
                    <FontAwesomeIcon icon={faEdit}/>
                </button>
            </div>

        </div>
    );
};


export default Fleet;
