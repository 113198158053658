import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app'; // Update import statement
import { getStorage } from 'firebase/storage';
import 'firebase/compat/firestore'; // Update import statement

const firebaseConfig = {
    apiKey: "AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E",
    authDomain: "chembase-27126.firebaseapp.com",
    projectId: "chembase-27126",
    storageBucket: "chembase-27126.appspot.com",
    messagingSenderId: "188110069054",
    appId: "1:188110069054:web:1b239ad8ea1b62328f206f"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

const storage = getStorage(app);


firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();


export { app, auth, db, storage };
