import React from "react";
import Footer from "../LandingFooter";
import Navbar from "../LandingNavBar";
import dash from "../../chemDash.png";
import dash2 from "../../chemDash2.png";
import dash3 from "../../chemDash3.png";
import dash4 from "../../chemDash4.png";



import { Typewriter } from "react-simple-typewriter";


const LandingChems = () => {
    return (
        <>
            <Navbar transparent />

            <main className="bg-bgcolor w-full pt-16">

                <section id='hero'>

                    <div
                        className="top-0 mx-auto mt-5 flex h-screen w-full max-w-screen-xl flex-col items-center justify-center text-center text-white">

                        <p className="p-2 font-bold uppercase text-litegreen">
                            Elevate your Golf Course Management
                        </p>
                        <h1 className="text-5xl font-bold sm:text-6xl md:text-7xl">
                            Optimize with Precision.
                        </h1>
                        <div className="mt-4 flex text-xl font-bold sm:text-3xl md:text-4xl">
                            <p className="mr-2 md:mr-3">Efficiently track</p>
                            <Typewriter
                                words={["Fertilizers", "Fungicides", "Pesticides", "Herbicides", "And More"]}
                                loop={0}
                                cursor={1}
                                typeSpeed={120}
                            />
                        </div>
                        <p className=" mt-4 w-[90vw] text-xl font-bold text-gray-400 md:text-2xl">
                            Streamline your inventory management to enhance sustainability and course health.
                        </p>

                    </div>


                </section>

                <section id='features' className="pb-16">

                    <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
                        <div className='flex flex-col space-y-12 md:w-1/2'>
                            <h2 className='max-w-md text-4xl font-bold text-center text-white md:text-left'>
                                What Sets Us Apart?
                            </h2>
                            <p className='max-w-sm text-center text-white md:text-left'>
                                Our software, Manage, delivers all the essential features your golf course management
                                team requires, without unnecessary complexity. It's custom-built for modern golf course
                                management needs.
                            </p>
                        </div>

                        <div className='flex flex-col space-y-8 md:w-1/2'>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            01
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Comprehensive Chemical Inventory Tracking
                                    </h3>
                                    <p className='text-white'>
                                        Gain insights into your golf course's chemical inventory management like never
                                        before. Track usage, monitor stock levels, and ensure compliance effortlessly.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            02
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Streamlined Reporting and Analysis
                                    </h3>
                                    <p className='text-white'>
                                        Utilize advanced reporting features to analyze chemical usage patterns, optimize
                                        procurement decisions, and ensure environmentally responsible practices.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
                                <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
                                    <div className='flex items-center space-x-2'>
                                        <div className='px-4 py-2 text-white rounded-full md:py-1 bg-darkgreen'>
                                            03
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='hidden mb-4 text-lg text-white font-bold md:block'>
                                        Centralized Management Hub
                                    </h3>
                                    <p className='text-white'>
                                        Consolidate all aspects of your chemical inventory management into a single,
                                        user-friendly platform. Simplify workflows, enhance collaboration, and drive
                                        operational efficiency.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash}
                                 alt="/"/>
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Enhanced Chemical Inventory Management
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Streamline Chemical Inventory Analytics
                                </h1>
                                <p className="mt-2 text-white">
                                    Experience seamless management of your golf course's chemical inventory with our
                                    powerful analytics dashboard. Gain valuable insights, track usage trends, and
                                    optimize inventory levels effortlessly.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Enhanced Nutrient Data Analytics
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Optimize Chemical Nutrient Management
                                </h1>
                                <p className="mt-2 text-white">
                                    Elevate your golf course's chemical nutrient management with our advanced analytics
                                    dashboard. Dive deep into nutrient data for each chemical in your inventory,
                                    uncovering insights to maximize effectiveness and minimize waste.
                                </p>

                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash2}
                                 alt="/"/>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash3}
                                 alt="/"/>
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Innovative Chemical Card Design
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Simplify Chemical Inventory Management
                                </h1>
                                <p className="mt-2 text-white">
                                    Discover the power of our innovative chemical card design, offering a quick and
                                    intuitive glance at the most
                                    important information for each chemical in your inventory. Streamline your chemical
                                    inventory management process,
                                    effortlessly track usage trends, and optimize inventory levels with ease.
                                </p>


                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-32">
                    <div className="w-full bg-bgcolor px-4 py-6">
                        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
                            <div className="flex flex-col items-start justify-center">
                                <p className="font-bold uppercase text-litegreen">
                                    Innovative Nutrient Card Design
                                </p>
                                <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                                    Optimize Nutrient Management Effortlessly
                                </h1>
                                <p className="mt-2 text-white">
                                    Explore the efficiency of our innovative nutrient card design, providing an easy way
                                    to calculate nutrient totals
                                    for each application. Simplify your nutrient management process, streamline
                                    calculations, and ensure precise
                                    nutrient application with our intuitive nutrient card feature.
                                </p>


                                <div className="self-center">
                                    {/*<Button text="Get started" type="primary"/>*/}
                                </div>
                            </div>
                            <img className="mx-auto my-4 w-[500px] rounded-xl shadow-2xl shadow-litegreen" src={dash4}
                                 alt="/"/>
                        </div>
                    </div>
                </section>

                <Footer/>

            </main>

        </>
    );
}

export default LandingChems;
