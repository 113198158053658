import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth';
import { db } from '../../../firebase/firebase';


const Register = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const { userLoggedIn } = useAuth();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            setIsRegistering(true);
            try {
                // Create user with email and password
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);

                // Access the user object from the userCredential
                const user = userCredential.user;


                // Add user data to Firestore collection
                await db.collection('Users').doc(user.uid).set({
                    fname: fname,
                    lname: lname,
                    company: company,
                    title: title,
                    email: email
                });

                // Registration successful, user is now logged in
                setIsRegistering(false);
            } catch (error) {
                console.error('Error registering user:', error.message);
                setIsRegistering(false);
            }
        }
    };

    return (
        <>
            {userLoggedIn && <Navigate to={'/Home'} replace={true} />}

            <main className="w-full h-screen flex self-center place-content-center place-items-center bg-bgcolor">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-2xl  rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-white text-xl font-semibold sm:text-2xl">Sign Up</h3>
                        </div>
                    </div>
                    <form onSubmit={onSubmit} className="space-y-4">
                        <div className="flex">
                            <div className="flex-grow mr-1">
                                <input
                                    type="fname"
                                    autoComplete='fname'
                                    required
                                    placeholder="First Name:"
                                    value={fname} onChange={(e) => {
                                    setFname(e.target.value)
                                }}
                                    className="w-full px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                            <div className="flex-grow ml-1">
                                <input
                                    type="lname"
                                    autoComplete='lname'
                                    required
                                    placeholder="Last Name:"
                                    value={lname} onChange={(e) => {
                                    setLname(e.target.value)
                                }}
                                    className="w-full px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        </div>

                        <div>
                            <input
                                type="company"
                                autoComplete='company'
                                required
                                placeholder="Company:"
                                value={company} onChange={(e) => {
                                setCompany(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-gray-400 bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <input
                                type="title"
                                autoComplete='title'
                                required
                                placeholder="Title:"
                                value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                placeholder="Email:"
                                value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='new-password'
                                required
                                placeholder="Password:"
                                value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='off'
                                required
                                placeholder="Password Confirm:"
                                value={confirmPassword} onChange={(e) => {
                                setconfirmPassword(e.target.value)
                            }}
                                className="w-full mt-1 px-3 py-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isRegistering}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-darkgreen hover:bg-litegreen hover:shadow-xl transition duration-300'}`}
                        >
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </button>
                        <div className="text-sm text-center text-white">
                            Already have an account? {'   '}
                            <Link to={'/login'}
                                  className="text-center text-sm hover:underline font-bold">Continue</Link>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
}

export default Register;
